<template>
  <div id="`psnpayrolllist`">
    <app-gridview
      v-bind:parentData="{
        tabHeaders: this.dataHeader,
        tabItems: this.dataItems,
        pageTitle: this.pageTitle,
        search: this.search,
      }"
      v-on:EditItem="editItemHandler($event)"
      v-on:DeleteItem="deleteItemHandler($event)"
      v-on:NewPressed="newRecordHandler"
    ></app-gridview>
  </div>
</template>


<script>
import GridView from "../../../components/common/GridView";
import moment from "moment";

export default {
  components: {
    "app-gridview": GridView,
  },

  data() {
    return {
      pageTitle: this.$t('cp22.cp22record'),
      dataHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "80px" },
        { text: this.$t('columns.staffcode'), value: "staffcode", width: "100px" },
        { text: this.$t('columns.staffname'), value: "psnname", width: "100px" },
        { text: this.$t('columns.company'), value: "compnycode", width: "100px" },
        { text: this.$t('cp22.deathnoticedate'), value: "deathnoticedate", width: "200px" },
        { text: this.$t('cp22.cesstype'), value: "cesstypedesc", width: "200px" },
        { text: this.$t('cp22.retiretype'), value: "retiretypedesc", width: "200px" },
      ],

      dataItems: [],
      loading: false,
      errMsg: "",
      search: this.$route.params.search,
    };
  },

  methods: {
    //Handler for getting the records
    getCP22ListHandler() {
      const vm = this;

      this.loading = !this.loading;

      this.$store.dispatch('get', {url: `/cp22/all`})
        .then(function (res) {
          vm.dataItems = res.data.rec;

          for (let i = 0; i < vm.dataItems.length; i++) {
            if (vm.dataItems[i].deathnoticedate) {
              vm.dataItems[i].deathnoticedate = moment(
                vm.dataItems[i].deathnoticedate
              ).format("YYYY-MM-DD");
            }
          }

          vm.loading = !vm.loading;
        })
        .catch(function (err) {
          vm.errMsg = err;
        });
    },

    editItemHandler(item) {
      this.$router.push({ name: "cp22edit", params: { id: item.id, search: item.search } });
    },

    newRecordHandler() {
      this.$router.push({ name: "cp22new" });
    },
  },

  mounted() {
    this.getCP22ListHandler();
  },
};
</script>

                