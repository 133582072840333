<template>
    <div id="ClaimApprovalInfo">

        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <v-row class="pt-5">
            <v-col>
                <v-card color="#F4F4F4">
                    <v-row class="ml-2 mr-1 mb-12">
                        <v-card-text class="mb-n6">
                        <div class="text--primary">{{claimApprovalRecord.staffno}} - {{claimApprovalRecord.staffname}}</div>
                        </v-card-text>
                        <v-card-text class="mb-n4">
                            <div class="text--primary">{{claimApprovalRecord.clmtypdesc}}</div>
                        </v-card-text>
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('claimrecordlisting.receiptdate')}} - {{$t('claimrecordlisting.receiptno')}}</p>
                        </v-card-text>
                        <v-card-text class="mb-n6">
                            <div class="text--primary mt-n3">{{claimApprovalRecord.receiptdate}} - {{claimApprovalRecord.receiptno}}</div>
                        </v-card-text>
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('claimrecordlisting.chargecode')}}</p>
                        </v-card-text>
                        <v-card-text class="mb-n6">
                            <div class="text--primary mt-n3" v-if="claimApprovalRecord.chargecodedesc">{{claimApprovalRecord.chargecodedesc}}</div>
                            <div class="text--primary mt-n3" v-else>-</div>
                        </v-card-text>
                        <v-card-text v-if="claimApprovalRecord.clmtypecatid == 1 || claimApprovalRecord.clmtypecatid == 2">
                            <p class="blue--text mb-n5" v-if="claimApprovalRecord.clmtypecatid == 1">{{$t('panelclinic')}} / {{$t('claimrecordlisting.nanpanelclinic')}}</p>
                            <p class="blue--text mb-n5" v-if="claimApprovalRecord.clmtypecatid == 2">{{$t('setupclaimlimit.vehichletype')}} / {{$t('claimrecordlisting.fromlocation')}} / {{$t('claimrecordlisting.tolocation')}} / {{$t('claimrecordlisting.distanceinkm')}} </p>
                        </v-card-text>

                        <v-card-text class="mb-n6" v-if="claimApprovalRecord.clmtypecatid == 1">
                            <div class="text--primary mt-n3" v-if="claimApprovalRecord.panelclinicdesc">{{$t('panelclinic')}} - {{claimApprovalRecord.panelclinicdesc}}</div>
                            <div class="text--primary mt-n3" v-else-if="claimApprovalRecord.clinicname">{{$t('claimrecordlisting.nanpanelclinic')}} - {{claimApprovalRecord.clinicname}}</div>
                            <div class="text--primary mt-n3" v-else>-</div>
                        </v-card-text>

                        <v-card-text class="mb-n6" v-else-if="claimApprovalRecord.clmtypecatid == 2">
                            <div class="text--primary mt-n3" 
                                v-if="(claimApprovalRecord.clmtypecatid == 2) && 
                                (claimApprovalRecord.vehtype || claimApprovalRecord.fromloc 
                                || claimApprovalRecord.toloc || claimApprovalRecord.distance )"
                                >
                                {{claimApprovalRecord.vehtype ? `${claimApprovalRecord.vehtype} / ` : '- / '}}
                                {{claimApprovalRecord.fromloc ? `${claimApprovalRecord.fromloc} / ` : '- / '}}
                                {{claimApprovalRecord.toloc ? `${claimApprovalRecord.toloc} / ` : '- / '}}
                                {{claimApprovalRecord.distance ? `${claimApprovalRecord.distance}` : '-'}}
                            </div>
                            <div class="text--primary mt-n3" v-else>-</div>
                        </v-card-text>

                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('columns.remark')}}</p>
                        </v-card-text>
                        <v-card-text class="mb-n6">
                            <div class="text--primary mt-n3" v-if="claimApprovalRecord.clmremark">{{claimApprovalRecord.clmremark}}</div>
                            <div class="text--primary mt-n3" v-else>-</div>
                        </v-card-text>
                        <v-card-text>
                            <p class="blue--text mb-n5">{{$t('recdisciplinaryrec.supportingdoc')}}</p>
                        </v-card-text>
                        <v-card-text class="mb-n4">
                            <div class="text--primary mt-n3" v-if="claimApprovalRecord.filetype" @click="loadAttachment(claimApprovalRecord.filetype, claimApprovalRecord.receiptattach)">
                                {{claimApprovalRecord.filetype}}
                                <v-icon> mdi-eye </v-icon>
                            </div>
                            <div class="text--primary mt-n3" v-else>-</div>
                        </v-card-text>
                    </v-row>
                    <v-text-field v-model="claimApprovalRecord.aprvremark" :label="$t('selfserviceleaveapproval.approrrej')" class="ml-8 mr-8 mt-n5" outlined dense background-color="white"></v-text-field>
                    <v-row justify="center" class="pb-3">
                        <v-card-actions>
                            <v-btn color="success" @click="approve(claimApprovalRecord)">
                                {{$t('taskapprovemobile.approve')}}
                            </v-btn>
                            <v-btn color="error" @click="reject(claimApprovalRecord)">
                                {{$t('taskapprovemobile.reject')}}
                            </v-btn>
                            <v-btn color="primary" @click="finalApprove(claimApprovalRecord)">
                                {{$t('taskapprovemobile.finalapprove')}}
                            </v-btn>
                            <app-alert-event
                                v-bind:subDialog="this.subDialog"
                                v-bind:subDialogId="this.subDialogId"
                                v-bind:subDialogTitle="this.subDialogTitle"
                                v-bind:subDialogText="this.subDialogText"
                                v-bind:subDetailTask="this.subDetailTask"
                                v-on:saveAction="this.saveAction"
                                v-on:cancelAction="this.cancelAction"
                            />
                            <app-staff-list
                                v-bind:subDialogStaffList="this.subDialogStaffList"
                                v-bind:subDialogStaffListParam="this.subDialogStaffListParam"
                                v-on:backAction="this.backAction"
                                v-on:choosenPerson="this.choosenPerson"
                            />
                        </v-card-actions>
                    </v-row>
                </v-card>
            </v-col>

            <v-col>
                <v-row>
                    <v-col>
                        <v-row>
                            <v-card color="#6DB961" width="95%">
                                <v-card-text>
                                    <h4 class="ml-4 mr-1 white--text">{{$t('selfserviceclaimapproval.aprvamount')}}</h4>
                                </v-card-text>
                            </v-card>
                        </v-row>
                        <v-row class="pt-2">
                            <v-card width="95%">
                                <v-row class="ml-2 mr-1 mb-n6 pt-4">
                                    <v-card-text>
                                        <p class="blue--text mb-n5">{{$t('claimrecordlisting.approvedclaimamountbeforetax')}}</p>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text--primary mb-n10 mt-n3 subtext">{{claimApprovalRecord.aprvamtb4tax}}</div>
                                    </v-card-text>
                                </v-row>
                                <v-row class="ml-2 mr-1 mb-n6">
                                    <v-card-text>
                                        <p class="blue--text mb-n5">{{$t('claimrecordlisting.approvedtaxamount')}}</p>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text--primary mb-n10 mt-n3 subtext">{{claimApprovalRecord.aprvtaxamt}}</div>
                                    </v-card-text>
                                </v-row>
                                <v-row class="ml-2 mr-1 pb-12">
                                    <v-card-text>
                                        <p class="blue--text mb-n5">{{$t('claimrecordlisting.approvedtotalamount')}}</p>
                                    </v-card-text>
                                    <v-text-field 
                                        v-model="claimApprovalRecord.aprvamt" 
                                        type="number" 
                                        :label="this.$t('columns.totalamount')" 
                                        class="ml-4 mr-8 mt-2 mb-n1" 
                                        outlined 
                                        dense 
                                        @change="inputApproveTotalAmount"
                                        background-color="white"></v-text-field>
                                </v-row>
                            </v-card>
                        </v-row>
                    </v-col>
                    <v-col>
                        <v-row>
                            <v-card color="#1278D7" width="95%">
                                <v-card-text>
                                    <h4 class="ml-4 mr-1 white--text">{{$t('selfserviceclaimapproval.recamount')}}</h4>
                                </v-card-text>
                            </v-card>
                        </v-row>
                        <v-row class="pt-2">
                            <v-card width="95%">
                                <v-row class="ml-2 mr-1 mb-n6 pt-4">
                                    <v-card-text>
                                        <p class="blue--text mb-n5">{{$t('claimrecordlisting.receiptcurrency')}}</p>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text--primary mb-n10 mt-n3 subtext">{{claimApprovalRecord.crcydesc}}</div>
                                    </v-card-text>
                                </v-row>
                                <v-row class="ml-2 mr-1 mb-n6">
                                    <v-card-text>
                                        <p class="blue--text mb-n5">{{$t('claimrecordlisting.claimamountbeforetax')}}</p>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text--primary mb-n10 mt-n3 subtext">{{claimApprovalRecord.receiptclmamtb4tax}}</div>
                                    </v-card-text>
                                </v-row>
                                <v-row class="ml-2 mr-1 mb-n6">
                                    <v-card-text>
                                        <p class="blue--text mb-n5">{{$t('claimrecordlisting.taxamount')}}</p>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text--primary mb-n10 mt-n3 subtext">{{claimApprovalRecord.receiptclmtaxamt}}</div>
                                    </v-card-text>
                                </v-row>
                                <v-row class="ml-2 mr-1 pb-6">
                                    <v-card-text>
                                        <p class="blue--text mb-n5">{{$t('claimrecordlisting.totalclaimamount')}}</p>
                                    </v-card-text>
                                    <v-card-text>
                                        <div class="text--primary mt-n3 subtext">{{claimApprovalRecord.receiptclmtotalamt}}</div>
                                    </v-card-text>
                                </v-row>
                            </v-card>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="pt-4">
                    <v-card color="#6DB961" width="97.5%">
                        <v-card-text>
                            <h4 class="ml-4 mr-1 white--text">{{$t('selfserviceleaveapproval.subapprinfo')}}</h4>
                        </v-card-text>
                    </v-card>
                </v-row>
                <v-row class="pt-2 pb-10">
                    <v-card width="97.5%">
                        <v-row class="ml-2 mr-1 mb-n6 pt-4">
                            <v-card-text>
                                <p class="blue--text mb-n5">{{$t('homemobile.submitted')}}</p>
                            </v-card-text>
                            <v-card-text>
                                <div class="text--primary mb-n10 mt-n3 subtext">{{$t('selfserviceclaimapproval.by')}} {{claimApprovalRecord.staffno}} {{claimApprovalRecord.staffname}} {{$t('homemobile.timerelatedon')}} {{claimApprovalRecord.entrytime}}</div>
                            </v-card-text>
                        </v-row>
                        <v-row class="ml-2 mr-1 pb-10">
                            <v-card-text>
                                <p class="blue--text mb-n5">{{$t('selfserviceleaveapproval.approval')}}</p>
                            </v-card-text>
                            <v-card-text v-for="(item, index) in staffApproveList" :key="index">
                                <div class="text--primary mb-10 mt-n3 subtext">{{$t('selfserviceclaimapproval.by')}} {{item.staffcode}} {{item.psnname}} -- <span class="red--text">{{item.aprstatus}}</span> -- {{$t('homemobile.timerelatedon')}} {{item.aprdate}}</div>
                            </v-card-text>
                        </v-row>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>

        <app-alert-error
            v-bind:errorDialog="errorDialog"
            v-bind:errorMessage="errorMessage"
            v-on:OkPressed="dismissHandler"
        />

    </div>
</template>

<script>

import AlertEvent from '../common/AlertEvent.vue'
import Loading from "vue-loading-overlay";
import StaffListEvent from '../common/StaffList.vue'
import ErrorMessageSelService from '../../sscomponents/common/ErrorMessage.vue'
import moment from "moment";

export default {
    
    components: {
        "app-alert-event": AlertEvent,
        "app-staff-list": StaffListEvent,
        "app-alert-error": ErrorMessageSelService,
        Loading
    },

    props: {
        claimApprovalRecord: Object,
        staffApproveList: Array
    },

    data(){
        return {
            subDialog: false,
            subDialogTitle: '',
            subDialogText: '',
            subDialogId: null,
            subDetailTask: null,
            loading: false,

            subDialogStaffList: false,
            subDialogStaffListParam: null,

            errorDialog: false,
            errorMessage: ''
        }
    },

    methods: {

        // Error Message Popup Dismiss
        dismissHandler(){
            this.errorDialog = false
            this.errorMessage = ''
        },

        finalApprove(param){
            this.subDialogId = 1 //Final Approve ID
            this.subDialog = true
            this.subDialogTitle = this.$t('selfservicemytask.confirmfinapprove')
            this.subDialogText = this.$t('selfservicemytask.approvefintask')
            this.subDetailTask = param
        },

        reject(param){
            if(param.aprvremark) {
                this.subDialogId = 2 //Reject ID
                this.subDialog = true
                this.subDialogTitle = this.$t('selfservicemytask.confirmrej')
                this.subDialogText = this.$t('selfservicemytask.rejecttask')
                this.subDetailTask = param
            } else {
                this.errorDialog = true
                this.errorMessage = this.$t('selfservicemytask.remcolrequired')
                // console.log(this.$t('selfservicemytask.remcolrequired'))
            }
        },

        approve(param){
            this.subDialogId = 3 //Approve ID
            this.subDialog = true
            this.subDialogTitle = this.$t('selfservicemytask.confirmapprove')
            this.subDialogText = this.$t('selfservicemytask.approvetask')
            this.subDetailTask = param
        },

        saveAction($event, param) {
            // Perform Action to final approve
            if($event == 1){
                this.taskFinalApproval(param)
            }

            // Perform Action to reject
            if($event == 2){
                this.taskApproval('REJECT', param)
            }

            // Perform Action to approve
            if($event == 3){
                this.taskCheckApproval(param)
            }

            this.resetSubDialog();
        },

        cancelAction() {
            this.resetSubDialog();
        },

        resetSubDialog(){
            this.subDialogId = null
            this.subDialog = false
            this.subDialogTitle = ''
            this.subDialogText = ''
            this.subDialogTask = null
            this.subDialogStaffList = false
            this.subDialogStaffListParam = null 
        },

        taskFinalApproval(value){

            let data = []
            data.push(value)

            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('put', {url: "/finalapprovalworkflow/claim", payload:  data})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        taskApproval(text, value){

            value.apraction = text
            let data = []
            data.push(value)

            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('put', {url: "/approvalworkflow/claim/" + this.$store.getters.userId + '/' + this.$store.getters.staffId, payload: data})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        taskCheckApproval(value){

            const vm = this;
            vm.loading = true;

            this.$store.dispatch('get', {url: "/staffreportingposition/" + value.reqstaffid})
            .then((res) => {
                const result = res.data;

                if (value.level == value.lvlno){
                    vm.taskApproval('APPROVE', value)
                }

                if (value.level != value.lvlno){
                    let res = result
                    if (res.rec.length == 0 || (value.hrlast == 1 && (value.level - value.lvlno > 1)) 
                    || res.rec[0].staffId != vm.$store.getters.staffId || value[0].exception == 'Y'){
                        vm.staffList(value)
                    } else {
                        vm.checkSelfReportingPosition(value)
                    }
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        checkSelfReportingPosition(data) {

            const vm = this;
            vm.loading = true;
            
            this.$store.dispatch('get', {url: "/staffreportingposition/" + this.$store.getters.staffId})
            .then((res) => {
                const result = res.data;

                if(result.rec.length > 0){
                    vm.taskApproval('APPROVE', data)
                } else {
                    vm.staffList(data)
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        staffList(param){
            this.subDialogStaffList = true
            this.subDialogStaffListParam = param 
        },

        // Staff List Action
        backAction(){
            this.subDialogStaffList = false
            this.subDialogStaffListParam = null
        },

        // Fucntion receive from choosen staff for another level approval
        choosenPerson(id, data){
            this.subDialogStaffList = false
            data.refstaffid = id
            this.taskWorkflowExceptionApproval(data)
        },

        taskWorkflowExceptionApproval(claimapproval){

            const vm = this;
            vm.loading = true;
            claimapproval.apraction = 'APPROVE'

            this.$store.dispatch('put', {url: "/approvalworkflowexception/claim/" + this.$store.getters.userId + '/' + this.$store.getters.staffId, payload: [claimapproval]})
            .then((res) => {
                const result = res.data;

                if(result.status == 200){
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.subDialogStaffList = false
                vm.subDialogStaffListParam = null
                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        loadAttachment(filetype, attachment) {

            if(filetype == 'PDF'){
                const blob = this.b64toBlob(attachment, 'application/pdf', 512)
                const url = URL.createObjectURL(blob);
                window.open(url);
            }

            if(filetype == 'Image'){
                var windowHandle = window.open("");
                windowHandle.document.write("<img src='" + attachment + "' />");
            }

        },

        b64toBlob(b64Data, contentType, sliceSize = 512) {
            contentType = contentType || ''
            sliceSize = sliceSize || 512
            b64Data = b64Data.replace(/^[^,]+,/, '')
            b64Data = b64Data.replace(/\s/g, '')

            var byteCharacters = atob(b64Data)
            var byteArrays = []

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize)

            var byteNumbers = new Array(slice.length)
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            var byteArray = new Uint8Array(byteNumbers)

            byteArrays.push(byteArray)
            }

            var blob = new Blob(byteArrays, {
            type: contentType
            })
            // return byteCharacters;
            return blob
        },

        inputApproveTotalAmount(){
            if(this.claimApprovalRecord.crcyid == this.claimApprovalRecord.clmtypecrcyid){
                this.loadCheckClaimBalance();
            } else if (this.claimApprovalRecord.crcyid != this.claimApprovalRecord.clmtypecrcyid) {
                this.getExchangeRate();
            }
        },

        loadCheckClaimBalance() {
            let data = {
                receiptdate: moment(this.claimApprovalRecord.receiptdate, 'DD-MM-YYYY').toDate(),
                staffid: this.$store.getters.staffId,
                clmtypeid: this.claimApprovalRecord.clmtypeid,
                userid: this.$store.getters.userId,
            };
            
            const vm = this;
            this.$store.dispatch('post', { url: "/claim/claimbalancenew", payload: data})
                .then((res) => {
                    //req success --> push res into array
                    vm.claimbalancedetail = res.data.rec;

                    vm.claimApprovalRecord.oktoclaim = res.data.rec[0].oktoclaim;
                    vm.claimApprovalRecord.staffid = res.data.rec[0].staffid;
                    vm.claimApprovalRecord.clmtypeid = res.data.rec[0].clmtypeid;

                    // if the receipt currency and claim currency is similar
                    if((parseInt(this.claimApprovalRecord.aprvamt) > this.claimApprovalRecord.oktoclaim) && 
                        (parseInt(this.claimApprovalRecord.aprvamt) > this.claimApprovalRecord.receiptaprvamt) &&
                        (this.claimApprovalRecord.crcyid == this.claimApprovalRecord.clmtypecrcyid)){
                        this.claimApprovalRecord.aprvamt = this.claimApprovalRecord.oktoclaim

                        let convertamount =  parseFloat(this.claimApprovalRecord.aprvamt) *
                                            parseFloat(1);

                        this.claimApprovalRecord.receiptaprvamt = parseFloat(convertamount.toFixed(2));

                        //calculate the converted approve before tax amount if oktoclaim is used as converted approved total amount
                        let newaprvamtb4tax = (parseFloat(this.claimApprovalRecord.aprvamt) / parseFloat(this.claimApprovalRecord.clmtotalamt)) * parseFloat(this.claimApprovalRecord.clmamtb4tax)
                        this.claimApprovalRecord.aprvamtb4tax = parseFloat(newaprvamtb4tax.toFixed(2));
                        this.claimApprovalRecord.receiptaprvamtb4tax = this.claimApprovalRecord.aprvamtb4tax 
                    }
                    // if the receipt currency and claim currency is different
                    else if((parseInt(this.claimApprovalRecord.aprvamt) > this.claimApprovalRecord.oktoclaim) && 
                    (parseInt(this.claimApprovalRecord.aprvamt) > this.claimApprovalRecord.receiptaprvamt) &&
                    (this.claimApprovalRecord.crcyid != this.claimApprovalRecord.clmtypecrcyid)){
                        this.claimApprovalRecord.aprvamt = this.claimApprovalRecord.oktoclaim

                        let convertamount =  parseFloat(this.claimApprovalRecord.aprvamt) /
                                            parseFloat(this.claimApprovalRecord.rate);

                        this.claimApprovalRecord.receiptaprvamt = parseFloat(convertamount.toFixed(2));

                        //calculate the converted approve before tax amount if oktoclaim is used as converted approved total amount

                        let newaprvamtb4tax = (parseFloat(this.claimApprovalRecord.aprvamt) / parseFloat(this.claimApprovalRecord.clmtotalamt)) * parseFloat(this.claimApprovalRecord.clmamtb4tax)
                        this.claimApprovalRecord.aprvamtb4tax = parseFloat(newaprvamtb4tax.toFixed(2));
                        this.claimApprovalRecord.receiptaprvamtb4tax = this.claimApprovalRecord.aprvamtb4tax 
                        
                    //if the claim amount is within the oktoclaim amount
                    } else {
                        if (parseInt(this.claimApprovalRecord.aprvamt) > this.claimApprovalRecord.receiptclmtotalamt) {
                            this.claimApprovalRecord.aprvamt = this.claimApprovalRecord.receiptclmtotalamt
                            this.claimApprovalRecord.receiptaprvamt = this.claimApprovalRecord.receiptclmtotalamt

                            let newaprvamtb4tax = (parseFloat(this.claimApprovalRecord.aprvamt) / parseFloat(this.claimApprovalRecord.clmtotalamt)) * parseFloat(this.claimApprovalRecord.clmamtb4tax)

                            this.claimApprovalRecord.aprvamtb4tax = parseFloat(newaprvamtb4tax.toFixed(2));
                            this.claimApprovalRecord.receiptaprvamtb4tax = this.claimApprovalRecord.aprvamtb4tax 

                        } else {
                            this.claimApprovalRecord.receiptaprvamt = this.claimApprovalRecord.aprvamt
                            
                            let newaprvamtb4tax = (parseFloat(this.claimApprovalRecord.aprvamt) / parseFloat(this.claimApprovalRecord.clmtotalamt)) * parseFloat(this.claimApprovalRecord.clmamtb4tax)

                            this.claimApprovalRecord.aprvamtb4tax = parseFloat(newaprvamtb4tax.toFixed(2));
                            this.claimApprovalRecord.receiptaprvamtb4tax = this.claimApprovalRecord.aprvamtb4tax 
                            
                        }
                    }
            }).catch((err) => {
                //req failure --> emit error
                vm.isErr = true;
                vm.errMsg = err;
                vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
            });
        },

        getExchangeRate(){
            const vm = this;

            this.$store.dispatch('get', {
                url: "/exchratecurrency/" + this.claimApprovalRecord.crcyid  + "/" + this.claimApprovalRecord.clmtypecrcyid  
            + "/" + this.claimApprovalRecord.exchrateid + "/" + this.claimApprovalRecord.receiptdate 
            + "/" + this.claimApprovalRecord.receiptdate
            }).then(res => {

                vm.exchangeItems = res.data.rec;

                if (res.data.rec.length > 0 ) {
                        vm.claimApprovalRecord.rate = res.data.rec[0].rate;
                        vm.claimApprovalRecord.sqlAll = res.data.rec[0].sqlAll;
                        vm.claimApprovalRecord.sqlAll2 = res.data.rec[0].sqlAll2;

                if(this.claimApprovalRecord.sqlAll == '1') {

                    this.loadCheckClaimBalance();

                        if(this.claimApprovalRecord.receiptclmamtb4tax != "" ){

                        let convertedrate = this.claimApprovalRecord.rate 

                        let currentAmount =  parseFloat(this.claimApprovalRecord.receiptclmamtb4tax) *
                                            parseFloat(convertedrate);

                        this.claimApprovalRecord.clmamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.claimApprovalRecord.aprvamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.claimApprovalRecord.rate = convertedrate;
                        }

                    if(this.claimApprovalRecord.receiptclmtaxamt != "" || this.claimApprovalRecord.receiptclmtaxamt != null){

                        let convertedrate = this.claimApprovalRecord.rate 

                        let currentAmount2 =  parseFloat(this.claimApprovalRecord.receiptclmtaxamt) *
                                            parseFloat(convertedrate);

                        this.claimApprovalRecord.clmtaxamt = parseFloat(currentAmount2.toFixed(2));
                        this.claimApprovalRecord.aprvtaxamt = parseFloat(currentAmount2.toFixed(2));
                        this.claimApprovalRecord.rate = convertedrate;
                        }

                    if(this.claimApprovalRecord.receiptclmtotalamt != ""  ){
                        let convertedrate = this.claimApprovalRecord.rate 

                        let currentAmount3 =  parseFloat(this.claimApprovalRecord.receiptclmtotalamt) *
                                            parseFloat(convertedrate);

                        this.claimApprovalRecord.clmtotalamt = parseFloat(currentAmount3.toFixed(2));
                        this.claimApprovalRecord.aprvamt = parseFloat(currentAmount3.toFixed(2));
                        this.claimApprovalRecord.rate = convertedrate;

                        }
                }

                else if (this.claimApprovalRecord.sqlAll2 == '2') {

                    this.loadCheckClaimBalance();
                
                        if(this.claimApprovalRecord.receiptclmamtb4tax != "" ){

                        let convertedrate = parseFloat(1 / this.claimApprovalRecord.rate);

                        let currentAmount =  parseFloat(this.claimApprovalRecord.receiptclmamtb4tax) *
                                            parseFloat(convertedrate);

                        this.claimApprovalRecord.clmamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.claimApprovalRecord.aprvamtb4tax = parseFloat(currentAmount.toFixed(2));
                        this.claimApprovalRecord.rate = convertedrate;
                        }

                        if(this.claimApprovalRecord.receiptclmtaxamt != "" ){

                            let convertedrate = parseFloat(1 / this.claimApprovalRecord.rate);

                            let currentAmount2 =  parseFloat(this.claimApprovalRecord.receiptclmtaxamt) *
                                                parseFloat(convertedrate);

                            this.claimApprovalRecord.clmtaxamt = parseFloat(currentAmount2.toFixed(2));
                            this.claimApprovalRecord.aprvtaxamt = parseFloat(currentAmount2.toFixed(2));
                            this.claimApprovalRecord.rate = convertedrate;
                            }

                        if(this.claimApprovalRecord.receiptclmtotalamt != "" ){

                            let convertedrate = parseFloat(1 / this.claimApprovalRecord.rate);

                            let currentAmount3 =  parseFloat(this.claimApprovalRecord.receiptclmtotalamt) *
                                                parseFloat(convertedrate);

                            this.claimApprovalRecord.clmtotalamt = parseFloat(currentAmount3.toFixed(2));
                            this.claimApprovalRecord.aprvamt = parseFloat(currentAmount3.toFixed(2));
                            this.claimApprovalRecord.rate = convertedrate;
                            }
                        }  
                } else {

                        if (res.data.rec.length == 0) {
                        // alert if no exchange items are found
                            this.$dialog.alert("Unable to find exchange rate. Please setup exchange rate at Exchange Rate Set before save record").then((dialog) => {
                            console.log(dialog); 
                        });
                    } 
                }
            })
        }
    },
}

</script>