<template>
  <div>
    <div v-if="!loading">
      <app-form
        v-bind:parentObject="{
          data: this.formData[0],
          searchnew: this.search,
          pageTitle: this.pageTitle,
          pageTitleValue: this.pageTitleValue,
          errMsg: this.errMsg,
          isEdit: this.editMode,
        }"
        v-on:input="formData[0] = $event"
        v-on:new="createHandler"
        v-on:save="saveHandler"
        v-on:delete="isConfirm = !isConfirm"
        v-on:error="errorHandler"
      />

      <div v-if="isErr">
        <app-error
          v-bind:parentError="{
            data: this.errMsg,
            isErr: this.isErr,
          }"
          v-on:closeError="isErr = $event"
        />
      </div>

      <div v-if="isConfirm">
        <app-confirm
          v-bind:parentObject="{
            pageTitleValue: this.pageTitleValue,
            isConfirm: this.isConfirm,
          }"
          v-on:confirm="deleteHandler($event)"
          v-on:cancelConfirm="isConfirm = !isConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import CP22Form from "../../../components/payroll/cp22/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";
import ConfirmDialog from "../../../components/common/ConfirmDialog";

export default {
  components: {
    "app-form": CP22Form,
    "app-error": ErrorDialog,
    "app-confirm": ConfirmDialog,
  },

  data() {
    return {
      id: this.$route.params.id,
      search: this.$route.params.search,
      pageTitle: this.$t('cp22.createcp22'),
      pageTitleValue: "",
      formData: [],
      errMsg: "",
      confirmMsg: "",
      isErr: false,
      loading: true,
      editMode: true,
      isConfirm: false,
      userid: this.$store.getters.userId,

    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canedit")) {
        return false;
      }
      if (this.formData[0].vssmss == false) {
        this.formData[0].vssmss = 0;
      } else {
        this.formData[0].vssmss = 1;
      }

      if (this.formData[0].repinctry == false) {
        this.formData[0].repinctry = 0;
      } else {
        this.formData[0].repinctry = 1;
      }
      
      vm.formData[0].userid = this.$store.getters.userId;

      var payload = this.formData[0];

      this.$store.dispatch('put', {url: "/cp22/" + vm.id, payload: payload})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = !vm.isErr;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "cp22list", params: { search: this.search} });
          }
        })
        .catch((err) => {
          vm.isErr = !vm.isErr;
          vm.errMsg = err;
        });
    },

    errorHandler($event) {
      this.isErr = $event.isErr;
      this.errMsg = $event.errMsg;
    },

    cancelHandler() {
      this.$router.push({ name: "cp22list" });
    },

    deleteHandler($event) {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "candelete")) {
        return false;
      }
      this.isConfirm = !this.isConfirm;

      if ($event) {
        this.$store.dispatch('delete', {url:
              "/cp22/" +
              this.id})
          .then((res) => {
            if (res.data.error) {
              vm.isErr = !vm.isErr;
              vm.errMsg = res.data.error;
            } else {
              this.$router.push({ name: "cp22list" });
            }
          })
          .catch((err) => {
            vm.isErr = !vm.isErr;
            vm.errMsg = err;
          });
      }
    },

    createHandler() {
      this.$router.push({ name: "cp22new" });
    },

    loadPsnpayrollHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: "/cp22/" + vm.id})
        .then((res) => {
          if (res.status == 200) {
            vm.formData = res.data.rec;
            vm.pageTitleValue = vm.formData[0].psnid;

            if (vm.formData[0].deathnoticedate) {
              vm.formData[0].deathnoticedate = moment(
                vm.formData[0].deathnoticedate
              ).format("YYYY-MM-DD");
            }

            if (vm.formData[0].vssmss === "N") {
              vm.formData[0].vssmss = false;
            } else {
              vm.formData[0].vssmss = true;
            }

            if (vm.formData[0].repinctry === "N") {
              vm.formData[0].repinctry = false;
            } else {
              vm.formData[0].repinctry = true;
            }

            if (vm.formData[0].isdynamic == "N") {
              vm.formData[0].isdynamic = false;
            } else {
              vm.formData[0].isdynamic = true;
            }

             vm.formData.lockstaff = res.data.rec[0].lockstaff;
            vm.formData[0].userid = this.$store.getters.userId;
           // vm.formData.userid = this.$store.getters.userId;
           
           vm.loading = !vm.loading;
          } else {
            vm.isErr = !vm.isErr;
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },
  },

  mounted() {
    this.loadPsnpayrollHandler();
  },
};
</script>