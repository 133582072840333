<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <h2 class="pb-5">
            {{ this.parentObject.pageTitle
            }}<span class="page_title_value">{{
              this.parentObject.pageTitleValue
            }}</span>
          </h2>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5"
        >
          <v-row>
            <v-col cols="12" sm="10" class="pb-n5">
              <!-- based on staff banka account id-->
              <v-autocomplete
                outlined
                :label="this.$t('columns.name')"
                type="text"
                ref="staffid"
                :rules="[rules.required]"
                v-model="formData.staffid"
                @input="updateValue"
                dense
                :items="psn0List"
                @change="changePersonExtraInfoHandler(formData.staffid)"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="5" sm="5" class="pb-n5 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('columns.company')"
                    type="text"
                    ref="compnycode"
                    v-model="formData.compnycode"
                    dense
                    readonly
                    append-icon=""
                    background-color="#ffeab2"
                ></v-text-field>
            </v-col>

            <v-col cols="5" sm="54" class="pb-n5 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('attendancereportbypeiordbycompanyreport.companyname')"
                    type="text"
                    ref="compnycode"
                    v-model="formData.compnyname"
                    dense
                    readonly
                    append-icon=""
                    background-color="#ffeab2"
                ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-select
                outlined
                :label="this.$t('cp22.cesstype')"
                ref="cesstypeid"
                :rules="[rules.required]"
                v-model="formData.cesstypeid"
                @input="updateValue"
                :items="cesstypeList"
                @change="changeCessTypeHandler(formData.cesstypeid)"
                dense
              ></v-select>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                      outlined
                      :label="this.$t('columns.code')"
                      type="text"
                      ref="cesstype"
                      v-model="formData.cesstype"
                      dense
                      readonly
                      append-icon=""
                      background-color="#ffeab2"
                  ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('cp22.deathnoticedate')"
                type="date"
                ref="deathnoticedate"
                :rules="[rules.required]"
                v-model="formData.deathnoticedate"
                @input="updateValue"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-select
                outlined
                :label="this.$t('cp22.retiretype')"
                ref="retiretypeid"
                :rules="[rules.required]"
                v-model="formData.retiretypeid"
                @input="updateValue"
                :items="retiretypeList"
                @change="changeRetireTypeHandler(formData.retiretypeid)"
                dense
              ></v-select>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                      outlined
                      :label="this.$t('columns.code')"
                      type="text"
                      ref="retiretype"
                      v-model="formData.retiretype"
                      dense
                      readonly
                      append-icon=""
                      background-color="#ffeab2"
                  ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-checkbox
                outlined
                ref="vssmss"
                v-model="formData.vssmss"
                :label="this.$t('cp22.vssmss')"
                @change="updateValue"
                dense
                ></v-checkbox>
              </v-col>
          </v-row>

          <v-row class="blue--text ">
            <h3>{{$t('cp22.deathrep')}}</h3>
          </v-row>
          <v-row>
            <v-col cols="2" sm="2" class="pb-n5 mt-n4">
              <v-checkbox
                outlined
                ref="repinctry"
                v-model="formData.repinctry"
                :label="this.$t('cp22.repinctry')"
                @change="updateValue"
                dense
                ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" sm="10" class="pb-n5 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('cp22.repname')"
                    type="text"
                    ref="repname"
                    v-model="formData.repname"
                    dense
                    @input="updateValue"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" sm="10" class="pb-n5 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('cp22.repaddr1')"
                    type="text"
                    ref="repaddr1"
                    v-model="formData.repaddr1"
                    dense
                    @input="updateValue"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" sm="10" class="pb-n5 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('cp22.repaddr2')"
                    type="text"
                    ref="repaddr2"
                    v-model="formData.repaddr2"
                    dense
                    @input="updateValue"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" sm="10" class="pb-n5 mt-n8">
                <v-text-field
                    outlined
                    :label="this.$t('cp22.repaddr3')"
                    type="text"
                    ref="repaddr3"
                    v-model="formData.repaddr3"
                    dense
                    @input="updateValue"
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('cp22.repposcode')"
                type="text"
                ref="repposcode"
                v-model="formData.repposcode"
                @input="updateValue"
                dense
                ></v-text-field>
            </v-col>
            <v-col cols="4" sm="6" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('cp22.repcity')"
                type="text"
                ref="repcity"
                v-model="formData.repcity"
                @input="updateValue"
                dense
                ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('cp22.repctry')"
                type="text"
                ref="cntryid"
                :items="countryList"
                v-model="formData.repctryid"
                @input="updateValue"
                @change="changeCountryHandler(formData.repctryid)"
                dense
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
              <v-text-field
                dense
                :label="this.$t('columns.code')"
                outlined
                readonly
                background-color="#ffeab2"
                v-model="formData.repctrycode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('cp22.idno')"
                type="text"
                ref="idno"
                v-model="formData.idno"
                @input="updateValue"
                dense
                ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-select
                outlined
                :label="this.$t('cp22.idtype')"
                ref="idtypeid"
                :rules="[rules.required]"
                v-model="formData.idtypeid"
                @input="updateValue"
                :items="idtypeList"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('cp22.phonenum')"
                type="text"
                ref="phoneno"
                v-model="formData.phoneno"
                @input="updateValue"
                dense
                ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setupreport_exportmapping.relationship')"
                type="text"
                ref="relationshipfordeath"
                v-model="formData.relationshipfordeath"
                @input="updateValue"
                dense
                ></v-text-field>
            </v-col>
          </v-row>

        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>
// import moment from 'moment';

//import GenericForm from '../../generic/GridForm';

export default {
  props: {
    parentObject: Object,
  },

  components: {
    //'app-genericform': GenericForm,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        percentage: (value) => !(value < 0 || value > 100) || "Value is 1-100",
      },

      formData: {
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyid,
        compnycode: this.parentObject.data.compnycode,
        compnyname: this.parentObject.data.compnyname,
        cesstypeid: this.parentObject.data.cesstypeid,
        cesstype: this.parentObject.data.cesstype,
        cesstypedesc: this.parentObject.data.cesstypedesc,
        deathnoticedate: this.parentObject.data.deathnoticedate,
        retiretypeid: this.parentObject.data.retiretypeid,
        retiretype: this.parentObject.data.retiretype,
        retiretypedesc: this.parentObject.data.retiretypedesc,
        vssmss: this.parentObject.data.vssmss,
        repinctry: this.parentObject.data.repinctry,
        repname: this.parentObject.data.repname,
        repaddr1: this.parentObject.data.repaddr1,
        repaddr2: this.parentObject.data.repaddr2,
        repaddr3: this.parentObject.data.repaddr3,
        repposcode: this.parentObject.data.repposcode,
        repcity: this.parentObject.data.repcity,
        repctryid: this.parentObject.data.repctryid,
        repctrycode: this.parentObject.data.repctrycode,
        repctrydesc: this.parentObject.data.repctrydesc,
        idtypeid: this.parentObject.data.idtypeid,
        idtypecode: this.parentObject.data.idtypecode,
        idno: this.parentObject.data.idno,
        phoneno: this.parentObject.data.phoneno, 
        relationshipfordeath: this.parentObject.data.relationshipfordeath,

        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid,
      },
      itemData: {
        itemcode: null,
        itemdesc: null,
        itemstamp: 0,
      },

      psns: [],
      psn0List: [],

      countryCode: '',
      countryList: [],
      countryItems: [],

      idtypeList: [],
      idtypeItems: [],

      cesstypeList: [],
      cesstypeItems: [],

      retiretypeList: [],
      retiretypeItems: [],

      errMsg: "",
      isErr: false,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    // psnpayrollExtra will have columns from staff, staffbank account, person, psndetails and staff account number
    // the reason i use staffid is because staff has column that can be connected to person table, staffbankaccount table and company table
    loadPersonExtraInfoHandler() {
      const vm = this;

      this.$store.dispatch('get', {url: `/staff/all/${this.$store.getters.userId}`})
        .then((res) => {
          vm.psns = res.data.rec;

          for (let i = 0; i < vm.psns.length; i++) {
            vm.psn0List.push({
              text: vm.psns[i].psndetails,
              value: vm.psns[i].id,
            });
          }
        });
    },

    changePersonExtraInfoHandler(id) {
      let item = this.psns.find((item) => item.id == id);

      this.psndetails = item.psndetails;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyid = item.compnyid;
      this.formData.compnycode = item.compnycode;
      this.formData.compnyname = item.compnyname;
    },

    loadIdType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/idtype/all"}).then((res) => {
        vm.idtypeItems = res.data.rec;

        for (let i = 0; i < vm.idtypeItems.length; i++) {
          vm.idtypeList.push({
            text: vm.idtypeItems[i].idtypecode,
            value: vm.idtypeItems[i].id,
          });
        }
      });
    },

    changeIdTypeHandler(id) {
      let item = this.idtypeItems.find((item) => item.id == id);

      this.formData.idtypecode = item.idtypecode;
    },

    loadRetireType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/cp22retiretype/all"}).then((res) => {
        vm.retiretypeItems = res.data.rec;

        for (let i = 0; i < vm.retiretypeItems.length; i++) {
          vm.retiretypeList.push({
            text: vm.retiretypeItems[i].retiretypedesc,
            value: vm.retiretypeItems[i].id,
          });
        }
      });
    },

    changeRetireTypeHandler(id) {
      let item = this.retiretypeItems.find((item) => item.id == id);

      this.formData.retiretype = item.retiretype;
    },

    loadCessType() {
      const vm = this;

      this.$store.dispatch('get', {url: "/cp22cesstype/all"}).then((res) => {
        vm.cesstypeItems = res.data.rec;

        for (let i = 0; i < vm.cesstypeItems.length; i++) {
          vm.cesstypeList.push({
            text: vm.cesstypeItems[i].cesstypedesc,
            value: vm.cesstypeItems[i].id,
          });
        }
      });
    },

    changeCessTypeHandler(id) {
      let item = this.cesstypeItems.find((item) => item.id == id);

      this.formData.cesstype = item.cesstype;
    },

    loadCountryListHandler() {
            const vm = this;

            this.$store.dispatch('get', {url: '/country/all'})
                .then(res => {
                    vm.countries = res.data.rec;

                    for (let i = 0; i < res.data.rec.length; i++) {
                        vm.countryList.push(
                            {
                                text: res.data.rec[i].cntryname,
                                value: res.data.rec[i].id
                            }
                        )

                        vm.countryItems.push(
                            {
                                id: res.data.rec[i].id,
                                code: res.data.rec[i].cntrycode,
                                desc: res.data.rec[i].cntryname,
                                stamp: res.data.rec[i].stamp
                            }
                        )
                    }

                    vm.countryLoading = false;

                    if (vm.formData.cntryid) {
                        vm.changeCountryHandler(vm.formData.cntryid);
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
        },

    changeCountryHandler(id) {
            let item = this.countries.find(item => item.id === id);

            this.countryCode = item.cntrycode;
        },

    updateValue() {
      this.$emit("input", {
        staffid: this.formData.staffid,
        staffcode: this.formData.staffcode,
        compnyid: this.formData.compnyid,
        compnycode: this.formData.compnycode,
        compnyname: this.formData.compnyname,
        cesstypeid: this.formData.cesstypeid,
        cesstype: this.formData.cesstype,
        cesstypedesc: this.formData.cesstypedesc,
        deathnoticedate: this.formData.deathnoticedate,
        retiretypeid: this.formData.retiretypeid,
        retiretype: this.formData.retiretype,
        retiretypedesc: this.formData.retiretypedesc,
        vssmss: this.formData.vssmss,
        repinctry: this.formData.repinctry,
        repname: this.formData.repname,
        repaddr1: this.formData.repaddr1,
        repaddr2: this.formData.repaddr2,
        repaddr3: this.formData.repaddr3,
        repposcode: this.formData.repposcode,
        repcity: this.formData.repcity,
        repctryid: this.formData.repctryid,
        repctrycode: this.formData.repctrycode,
        repctrydesc: this.formData.repctrydesc,
        idtypeid: this.formData.idtypeid,
        idtypecode: this.formData.idtypecode,
        idno: this.formData.idno,
        phoneno: this.formData.phoneno, 
        relationshipfordeath: this.formData.relationshipfordeath,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
      });
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      this.$emit("save");
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "cp22list" , params: { search: this.search}});
    },
  },

  mounted() {
    this.loadPersonExtraInfoHandler();
    this.loadCountryListHandler();
    this.loadIdType();
    this.loadRetireType();
    this.loadCessType();
    
  },
};
</script>

<style>
.removeSpin input[type="number"] {
  -moz-appearance: textfield;
  -moz-appearance: autocomplete;
}
.removeSpin input::-webkit-outer-spin-button,
.removeSpin input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>    