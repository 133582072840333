<template>

    <div id="ClaimDetails">

        <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="150"
        :width="150"
        :color="'#FFFF00'"
        :background-color="'#000000'"
        :opacity="0.5"
        ></loading>

        <div v-for="(item, index) in claimrecordItems" :key="index">
        <v-row class="pt-5">
            <v-card color="#F4F4F4" class="ml-3 mr-3" width="100%">
                <v-row>
                    <v-col cols="7" sm="7" md="7">
                        <v-row class="mb-n6 mt-2">
                            <v-card-text>
                                <h4 class="ml-4 mr-1">{{item.clmtypdesc}}<span class="ml-4">{{item.clmrecordd[0].staffno}} - {{item.clmrecordd[0].staffname}}</span></h4>
                                <!-- <h4 class="ml-4 mr-1">{{item.clmtypdesc}}<span class="ml-4">{{item.clmrecordd[0]}}</span></h4> -->
                            </v-card-text>
                        </v-row>
                        <v-row class="mt-n6">
                            <v-card-text>
                                <p class="ml-4 mr-1 blue--text">{{$t('selfserviceleaveapproval.subapprinfo')}}</p>
                            </v-card-text>
                        </v-row>
                    </v-col>
                    <v-col cols="5" sm="5" md="5">
                        <v-card-actions class="mr-4 ml-1 justify-end mt-5 mb-2">
                            <v-btn color="success" @click="approve('APPROVE')">
                                {{$t('taskapprovemobile.approve')}}
                            </v-btn>
                            <v-btn color="error" @click="reject('REJECT')">
                                {{$t('taskapprovemobile.reject')}}
                            </v-btn>
                            <v-btn color="primary" @click="finalApprove('FINAL APPROVE')">
                                {{$t('taskapprovemobile.finalapprove')}}
                            </v-btn>
                            <app-alert-event
                                v-bind:subDialog="subDialog"
                                v-bind:subDialogId="subDialogId"
                                v-bind:subDialogTitle="subDialogTitle"
                                v-bind:subDialogText="subDialogText"
                                v-bind:subDetailTask="subDetailTask"
                                v-on:saveAction="saveAction"
                                v-on:cancelAction="cancelAction"
                            />
                            <app-staff-list
                                v-bind:subDialogStaffList="subDialogStaffList"
                                v-bind:subDialogStaffListParam="subDialogStaffListParam"
                                v-on:backAction="backAction"
                                v-on:choosenPerson="choosenPerson"
                            />
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>    
        
        <v-list class="mt-1" v-for="(itemd, index) in item.clmrecordd" :key="index" dense :class="{ 'dark-bg': index % 2 !== 0 }">
            <!-- <v-list-item v-for="(itemd, index) in item.clmrecordd" :key="index" dense :class="{ 'dark-bg': index % 2 !== 0 }"> -->
            <v-list-item>
                <v-list-item-content @click="$emit('claim-display-details', itemd)">{{itemd.clmtypdesc}} {{$t('taskmobile.on')}} {{itemd.displaydate}} {{$t('taskmobile.withclaimamount')}} {{itemd.clmtypecrcycode}} {{itemd.aprvamt}}</v-list-item-content>
                <v-list-item-action>
                    <v-checkbox v-model="itemd.active"></v-checkbox>
                </v-list-item-action>
            </v-list-item>
        </v-list>

        </div>

        <app-alert-error
            v-bind:errorDialog="errorDialog"
            v-bind:errorMessage="errorMessage"
            v-on:OkPressed="dismissHandler"
        />

    </div>

</template>

<script>

import AlertEvent from '../../sscomponents/common/AlertEvent.vue'
import Loading from "vue-loading-overlay";
import StaffListEvent from '../common/StaffList.vue'
import ErrorMessageSelService from '../../sscomponents/common/ErrorMessage.vue'

export default {

    props: {
        claimrecordItems: Array
    },

    components: {
        "app-alert-event": AlertEvent,
        "app-staff-list": StaffListEvent,
        "app-alert-error": ErrorMessageSelService,
        Loading
    },

    data(){
        return {
            subDialog: false,
            subDialogTitle: '',
            subDialogText: '',
            subDialogId: null,
            subDetailTask: null,

            loading: false,

            subDialogStaffList: false,
            subDialogStaffListParam: null,

            errorDialog: false,
            errorMessage: ''
        }
    },

    methods: {

        // Error Message Popup Dismiss
        dismissHandler(){
            this.errorDialog = false
            this.errorMessage = ''
        },

        // To check only active item selected
        async checkActiveItem(action){

            let select = []

            for (let i = 0; i < this.claimrecordItems.length; i++) {
                let claimrecorddholder = this.claimrecordItems[i].clmrecordd.filter(val => {
                    val.apraction = action
                    return val.active
                })
                select.push(...claimrecorddholder)
            }

            return select

        },

        async finalApprove(action){

            let activeItem = await this.checkActiveItem(action);

            if(activeItem.length > 0){
                this.subDialogId = 1 //Final Approve ID
                this.subDialog = true
                this.subDialogTitle = this.$t('selfservicemytask.confirmfinapprove')
                this.subDialogText = this.$t('selfservicemytask.approvefintask')
                this.subDetailTask = activeItem
            } else {
                this.errorDialog = true
                this.errorMessage = this.$t('norectoprocess')
                // console.error(this.$t('norectoprocess'))
            }

        },

        async reject(action){

            let activeItem = await this.checkActiveItem(action);

            if(activeItem.length > 0){
                this.subDialogId = 2 //Reject ID
                this.subDialog = true
                this.subDialogTitle = this.$t('selfservicemytask.confirmrej')
                this.subDialogText = this.$t('selfservicemytask.rejecttask')
                this.subDetailTask = activeItem
            } else {
                this.errorDialog = true
                this.errorMessage = this.$t('norectoprocess')
                // console.error(this.$t('norectoprocess'))
            }
            
        },

        async approve(action){

            // Filter active item only
            let activeItem = await this.checkActiveItem(action);
            
            if(activeItem.length > 0){
                this.subDialogId = 3 //Approve ID
                this.subDialog = true
                this.subDialogTitle = this.$t('selfservicemytask.confirmapprove')
                this.subDialogText = this.$t('selfservicemytask.approvetask')
                this.subDetailTask = activeItem
            } else {
                this.errorDialog = true
                this.errorMessage = this.$t('norectoprocess')
                // console.error(this.$t('norectoprocess'))
            }

        },

        saveAction($event, param) {
            console.log('Save')
            console.log($event);
            console.log(param);

            // Perform Action to final approve
            if($event == 1){
                this.taskFinalApproval(param)
            }

            // Perform Action to reject
            if($event == 2){
                this.taskApproval('REJECT', param)
            }

            // Perform Action to approve
            if($event == 3){
                this.taskCheckApproval(param)
            }

            this.resetSubDialog();
        },

        cancelAction() {
            console.log('Cancel')
            this.resetSubDialog();
        },

        resetSubDialog(){
            this.subDialogId = null
            this.subDialog = false
            this.subDialogTitle = ''
            this.subDialogText = ''
            this.subDialogTask = null
            this.subDialogStaffList = false
            this.subDialogStaffListParam = null 
        },

        taskFinalApproval(param){

            const vm = this;
            vm.loading = true

            this.$store.dispatch('put', {url: "/finalapprovalworkflow/claim", payload: param})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.loading = false

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        taskApproval(text, value){

            value.apraction = text

            const vm = this;
            vm.loading = true;

            this.$store.dispatch('put', {url: "/approvalworkflow/claim/" + this.$store.getters.userId + '/' + this.$store.getters.staffId, payload: value})
            .then((res) => {
                const result = res.data;
                if (result.status === 200) {
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        taskCheckApproval(value){

            const vm = this;
            vm.loading = true;

            this.$store.dispatch('get', {url: "/staffreportingposition/" + value[0].reqstaffid})
            .then((res) => {
            const result = res.data;

            if (value[0].level == value[0].lvlno){
                vm.taskApproval('APPROVE', value)
                console.log('test')
            }

            if (value[0].level != value[0].lvlno){
                let res = result
                if (res.rec.length == 0 || (value[0].hrlast == 1 && (value[0].level - value[0].lvlno > 1)) 
                || res.rec[0].staffId != vm.$store.getters.staffId || value[0].exception == 'Y'){
                    vm.staffList(value)
                    console.log('test')
                } else {
                    vm.checkSelfReportingPosition(value)
                }
            }

            vm.loading = false;

        }).catch((err) => {
            vm.loading = false;
            console.error(err)
        });

        },

        checkSelfReportingPosition(data) {

            const vm = this;
            vm.loading = true;

            this.$store.dispatch('get', {url: "/staffreportingposition/" + this.$store.getters.staffId})
            .then((res) => {
                const result = res.data;

                if(result.rec.length > 0){
                    vm.taskApproval('APPROVE', data)
                } else {
                    vm.staffList(data)
                }

                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        },

        staffList(param){
            this.subDialogStaffList = true
            this.subDialogStaffListParam = param 
        },

        // Staff List Action
        backAction(){
            this.subDialogStaffList = false
            this.subDialogStaffListParam = null
        },

        // Function receive from choosen staff for another level approval
        choosenPerson(id, data){

            this.subDialogStaffList = false

            for (let i = 0; i < data.length; i++) {
                data[i].refstaffid = id
                data[i].apraction = 'APPROVE'
            }

            this.taskWorkflowExceptionApproval(data)

        },

        taskWorkflowExceptionApproval(claimapproval){

            const vm = this;
            vm.loading = true;

            this.$store.dispatch('put', {url: "/approvalworkflowexception/claim/" + this.$store.getters.userId + '/' + this.$store.getters.staffId, payload: claimapproval})
            .then((res) => {
                const result = res.data;

                if(result.status == 200){
                    vm.$router.push({name: 'selfservicemytask'});
                } else {
                    console.error(result.error);
                }

                vm.subDialogStaffList = false
                vm.subDialogStaffListParam = null
                vm.loading = false;

            }).catch((err) => {
                vm.loading = false;
                console.error(err)
            });

        }

    }
}

</script>

<style scoped>
p {
    font-size: 9px;
}
.dark-bg {
    background-color: #F4F4F4;
}
</style>