import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/login/Login.vue';
import Dashboard from './components/dashboard/Dashboard.vue';

import PasswordPolicyList from "./pages/settings/passwordpolicy/PasswordPolicyGrid.vue";
import PasswordPolicyNew from "./pages/settings/passwordpolicy/PasswordPolicyNew.vue";
import PasswordPolicyEdit from "./pages/settings/passwordpolicy/PasswordPolicyEdit.vue";

import SecuserList from "./pages/settings/secuser/SecuserGrid.vue";
import SecuserNew from "./pages/settings/secuser/SecuserNew.vue";
import SecuserEdit from "./pages/settings/secuser/SecuserEdit.vue";

import CompanyList from './pages/settings/company/CompanyGrid.vue';
import CompanyNew from './pages/settings/company/CompanyNew.vue';
import CompanyEdit from "./pages/settings/company/CompanyEdit.vue";

import AddressList from './pages/settings/addressmas/AddressGrid.vue';
import AddressNew from './pages/settings/addressmas/AddressNew.vue';
import AddressEdit from "./pages/settings/addressmas/AddressEdit.vue";

import BranchList from './pages/settings/branch/BranchGrid.vue';
import BranchNew from './pages/settings/branch/BranchNew.vue';
import BranchEdit from "./pages/settings/branch/BranchEdit.vue";

import DivisionList from './pages/settings/division/DivisionGrid.vue';
import DivisionNew from './pages/settings/division/DivisionNew.vue';
import DivisionEdit from "./pages/settings/division/DivisionEdit.vue";

import DepartmentList from './pages/settings/department/DepartmentGrid.vue';
import DepartmentNew from './pages/settings/department/DepartmentNew.vue';
import DepartmentEdit from "./pages/settings/department/DepartmentEdit.vue";

import SectionList from './pages/settings/section/SectionGrid.vue';
import SectionNew from './pages/settings/section/SectionNew.vue';
import SectionEdit from "./pages/settings/section/SectionEdit.vue";

import UnitList from './pages/settings/unit/UnitGrid.vue';
import UnitNew from './pages/settings/unit/UnitNew.vue';
import UnitEdit from "./pages/settings/unit/UnitEdit.vue";

import ServiceRecordList from './pages/settings/servicerecord/ServiceRecordGrid.vue';
import ServiceRecordNew from './pages/settings/servicerecord/ServiceRecordNew.vue';
import ServiceRecordEdit from './pages/settings/servicerecord/ServiceRecordEdit.vue';

import BasicFreqList from './pages/settings/basicfreq/BasicFreqGrid.vue';
import BasicFreqNew from './pages/settings/basicfreq/BasicFreqNew.vue';
import BasicFreqEdit from './pages/settings/basicfreq/BasicFreqEdit.vue';

import EmpTypeList from './pages/settings/emptype/EmpTypeGrid.vue';
import EmpTypeNew from './pages/settings/emptype/EmpTypeNew.vue';
import EmpTypeEdit from './pages/settings/emptype/EmpTypeEdit.vue';

import GradeList from './pages/settings/grade/GradeGrid.vue';
import GradeNew from './pages/settings/grade/GradeNew.vue';
import GradeEdit from './pages/settings/grade/GradeEdit.vue';

import JobList from './pages/settings/job/JobGrid.vue';
import JobNew from './pages/settings/job/JobNew.vue';
import JobEdit from './pages/settings/job/JobEdit.vue';

import PositionList from './pages/settings/position/PositionGrid.vue';
import PositionNew from './pages/settings/position/PositionNew.vue';
import PositionEdit from './pages/settings/position/PositionEdit.vue';

import LetterList from './pages/settings/letter/LetterGrid.vue';
import LetterNew from './pages/settings/letter/LetterNew.vue';
import LetterEdit from './pages/settings/letter/LetterEdit.vue';

// import LetterPrintFilter from './pages/settings/letterprint/LetterPrintFilter.vue';
// import LetterPrintProcess from './pages/settings/letterprint/LetterPrintProcess.vue';

import PersonList from './pages/personnel/person/PersonGrid.vue';
import PersonNew from './pages/personnel/person/PersonNew.vue';
import PersonEdit from './pages/personnel/person/PersonEdit.vue';

import StaffList from './pages/personnel/staff/StaffGrid.vue';
import StaffNew from './pages/personnel/staff/StaffNew.vue';
import StaffEdit from './pages/personnel/staff/StaffEdit.vue';

import StaffListHS from './pages/personnel/staffhidesalary/StaffGrid.vue';
import StaffNewHS from './pages/personnel/staffhidesalary/StaffNew.vue';
import StaffEditHS from './pages/personnel/staffhidesalary/StaffEdit.vue';

import AffectItemList from './pages/payroll/affectitem/AffectItemGrid.vue';
import AffectItemNew from './pages/payroll/affectitem/AffectItemNew.vue';
import AffectItemEdit from './pages/payroll/affectitem/AffectItemEdit.vue';

import AffectTypeList from './pages/payroll/affecttype/AffectTypeGrid.vue';
import AffectTypeNew from './pages/payroll/affecttype/AffectTypeNew.vue';
import AffectTypeEdit from './pages/payroll/affecttype/AffectTypeEdit.vue';

import PayItemTypeList from "./pages/payroll/payitemtype/PayItemTypeGrid.vue";
import PayItemTypeNew from "./pages/payroll/payitemtype/PayItemTypeNew.vue";
import PayItemTypeEdit from "./pages/payroll/payitemtype/PayItemTypeEdit.vue";

import PayItemList from "./pages/payroll/payitem/PayItemGrid.vue";
import PayItemNew from "./pages/payroll/payitem/PayItemNew.vue";
import PayItemEdit from "./pages/payroll/payitem/PayItemEdit.vue";

import PayRateList from "./pages/payroll/payrate/PayRateGrid.vue";
import PayRateNew from "./pages/payroll/payrate/PayRateNew.vue";
import PayRateEdit from "./pages/payroll/payrate/PayRateEdit.vue";

import PayProcessList from "./pages/payroll/processlist/PayProcessListGrid.vue";
import PayProcessNew from "./pages/payroll/processlist/PayProcessListNew.vue";
import PayProcessEdit from "./pages/payroll/processlist/PayProcessListEdit.vue";

import PayTypeList from "./pages/payroll/payrolltype/PayTypeGrid.vue";
import PayTypeNew from "./pages/payroll/payrolltype/PayTypeNew.vue";
import PayTypeEdit from "./pages/payroll/payrolltype/PayTypeEdit.vue";

import PayoutControlList from "./pages/payroll/payoutcontrol/PayoutControlGrid.vue";
import PayoutControlNew from "./pages/payroll/payoutcontrol/PayoutControlNew.vue";
import PayoutControlEdit from "./pages/payroll/payoutcontrol/PayoutControlEdit.vue";

import PayrollRunProcess from "./pages/payroll/payrollrunprocess/PayrollRunProcess.vue";
import PayrollProcessLog from "./pages/payroll/payrollrunprocess/PayrollProcessLog.vue";

import mmPayrollRunProcess from "./pages/mm/payrollrunprocess/PayrollRunProcess.vue";
import mmPayrollProcessLog from "./pages/mm/payrollrunprocess/PayrollProcessLog.vue";

//import leave payroll posting
import LeavePayrollPosting from "./pages/payroll/leavepayrollposting/LeavePayrollPosting.vue";
import LeavePayrollLog from "./pages/payroll/leavepayrollposting/LeavePayrollLog.vue";

//import claim payroll posting
import ClaimPayrollPosting from "./pages/payroll/claimpayrollposting/ClaimPayrollPosting.vue";
import ClaimPayrollLog from "./pages/payroll/claimpayrollposting/ClaimPayrollLog.vue";

// import UserList from './components/settings/user/UserGrid.vue';
// import UserNew from './components/settings/user/UserNew.vue';

import BankAccountList from './pages/payroll/bankaccount/BankAccountGrid.vue';
import BankAccountNew from './pages/payroll/bankaccount/BankAccountNew.vue';
import BankAccountEdit from './pages/payroll/bankaccount/BankAccountEdit.vue';

import StaffBankAccountList from './pages/payroll/staffbankaccount/StaffBankAccountGrid.vue';
import StaffBankAccountNew from './pages/payroll/staffbankaccount/StaffBankAccountNew.vue';
import StaffBankAccountEdit from './pages/payroll/staffbankaccount/StaffBankAccountEdit.vue';

import GroupList from './pages/settings/group/GroupGrid.vue';
import GroupNew from './pages/settings/group/GroupNew.vue';
import GroupEdit from './pages/settings/group/GroupEdit.vue';

import ValvalueList from './pages/settings/valvalue/ValvalueGrid.vue';
import ValvalueNew from './pages/settings/valvalue/ValvalueNew.vue';
import ValvalueEdit from './pages/settings/valvalue/ValvalueEdit.vue';

import ExemptionList from './pages/payroll/exemption/ExemptionGrid.vue';
import ExemptionNew from './pages/payroll/exemption/ExemptionNew.vue';
import ExemptionEdit from './pages/payroll/exemption/ExemptionEdit.vue';

import FamilyList from './pages/personnel/family/FamilyGrid.vue';
import FamilyNew from './pages/personnel/family/FamilyNew.vue';
import FamilyEdit from './pages/personnel/family/FamilyEdit.vue';

import tp3List from './pages/payroll/tp3/tp3Grid.vue';
import tp3New from './pages/payroll/tp3/tp3New.vue';
import tp3Edit from './pages/payroll/tp3/tp3Edit.vue';

import SocsoList from './pages/payroll/socso/SocsoGrid.vue';
import SocsoNew from './pages/payroll/socso/SocsoNew.vue';
import SocsoEdit from './pages/payroll/socso/SocsoEdit.vue';

import BaitulmalList from './pages/payroll/baitulmal/BaitulmalGrid.vue';
import BaitulmalNew from './pages/payroll/baitulmal/BaitulmalNew.vue';
import BaitulmalEdit from './pages/payroll/baitulmal/BaitulmalEdit.vue';

import eisList from './pages/payroll/eis/eisGrid.vue';
import eisNew from './pages/payroll/eis/eisNew.vue';
import eisEdit from './pages/payroll/eis/eisEdit.vue';

import EpfveList from './pages/payroll/epfve/epfveGrid.vue';
import EpfveNew from './pages/payroll/epfve/epfveNew.vue';
import EpfveEdit from './pages/payroll/epfve/epfveEdit.vue';

import taxsetList from './pages/payroll/taxset/TaxsetGrid.vue';
import taxsetNew from './pages/payroll/taxset/TaxsetNew.vue';
import taxsetEdit from './pages/payroll/taxset/TaxsetEdit.vue';

import psnpayrollList from './pages/payroll/psnpayroll/psnpayrollGrid.vue';
import psnpayrollNew from './pages/payroll/psnpayroll/psnpayrollNew.vue';
import psnpayrollEdit from './pages/payroll/psnpayroll/psnpayrollEdit.vue';

import cp22List from './pages/payroll/cp22/cp22Grid.vue';
import cp22New from './pages/payroll/cp22/cp22New.vue';
import cp22Edit from './pages/payroll/cp22/cp22Edit.vue';

import cp22ListingParameter from './pages/payroll/cp22/ParameterNew.vue';

import eadtlList from './pages/payroll/eadtl/eadtlGrid.vue';
import eadtlNew from './pages/payroll/eadtl/eadtlNew.vue';
import eadtlEdit from './pages/payroll/eadtl/eadtlEdit.vue';

import cntryssList from './pages/payroll/cntryss/cntryssGrid.vue';
import cntryssNew from './pages/payroll/cntryss/cntryssNew.vue';
import cntryssEdit from './pages/payroll/cntryss/cntryssEdit.vue';

import staffulldNew from './pages/personnel/staffulldetails/staffulldetailsNew.vue';

import parameterBank from './pages/payroll/bankpayment/ParameterNew.vue';
import detailsBank from './pages/payroll/bankpayment/DetailsNew.vue';

import parameterNonbank from './pages/payroll/nonbankpayment/ParameterNew.vue';
import detailsNonbank from './pages/payroll/nonbankpayment/DetailsNew.vue';

import parameterPayrollSummaryCompany from './pages/payroll/payrollsummarycompany/ParameterNew.vue';
import detailsPayrollSummaryCompany from './pages/payroll/payrollsummarycompany/DetailsNew.vue';

import parameterCashdenomination from './pages/payroll/cashdenomination/ParameterNew.vue';
import detailsCashdenomination from './pages/payroll/cashdenomination/DetailsNew.vue';

import socsosubList from './pages/payroll/socsosub/socsosubGrid.vue';
import socsosubNew from './pages/payroll/socsosub/socsosubNew.vue';
import socsosubEdit from './pages/payroll/socsosub/socsosubEdit.vue';

import eissubList from './pages/payroll/eissub/eissubGrid.vue';
import eissubNew from './pages/payroll/eissub/eissubNew.vue';
import eissubEdit from './pages/payroll/eissub/eissubEdit.vue';

import MonthlyTaxExport from './pages/payroll/monthlytaxexport/MonthlyTaxExport.vue';
import MonthlyTaxExportParam from './pages/payroll/monthlytaxexport/MonthlyTaxExportParam.vue';
import MonthlyTaxExportLog from './pages/payroll/monthlytaxexport/MonthlyTaxExportLog.vue';


//import payroll vendor pages
import VendorList from './pages/payroll/vendorsetup/VendorGrid.vue';
import VendorNew from './pages/payroll/vendorsetup/VendorNew.vue';
import VendorEdit from './pages/payroll/vendorsetup/VendorEdit.vue';

//import reference number setup pages
import RefNumList from './pages/payroll/referencenumbersetup/RefNumSetupGrid.vue';
import RefNumNew from './pages/payroll/referencenumbersetup/RefNumSetupNew.vue';
import RefNumEdit from './pages/payroll/referencenumbersetup/RefNumSetupEdit.vue';

//removed by SJ 2020-08-11. REplaced by new names. import taxable exemption group setup pages
// import TaxExempGroupSetupList from './pages/payroll/taxableexemptiongroupsetup/TaxExempGroupSetupGrid.vue';
// import TaxExemGroupSetupNew from './pages/payroll/taxableexemptiongroupsetup/TaxExemGroupSetupNew.vue';
// import TaxExempGroupSetupEdit from './pages/payroll/taxableexemptiongroupsetup/TaxExempGroupSetupEdit.vue';

//import taxable rebate group setup pages 
import TaxableRebateGroupSetupList from './pages/payroll/taxablerebategroupsetup/TaxableRebateGroupSetupGrid.vue';
import TaxableRebateGroupSetupNew from './pages/payroll/taxablerebategroupsetup/TaxableRebateGroupSetupNew.vue';
import TaxableRebateGroupSetupEdit from './pages/payroll/taxablerebategroupsetup/TaxableRebateGroupSetupEdit.vue';

//import taxable rebate setup pages
import TaxableRebateSetupList from './pages/payroll/taxablerebatesetup/TaxableRebateSetupGrid.vue';
import TaxableRebateSetupNew from './pages/payroll/taxablerebatesetup/TaxableRebateSetupNew.vue';
import TaxableRebateSetupEdit from './pages/payroll/taxablerebatesetup/TaxableRebateSetupEdit.vue';

//import contact details pages
import ContactDetailsList from './pages/personnel/contactdetails/ContactDetailsGrid.vue';
import ContactDetailsNew from './pages/personnel/contactdetails/ContactDetailsNew.vue';
import ContactDetailsEdit from './pages/personnel/contactdetails/ContactDetailsEdit.vue';

//import taxable rebate group setup pages
// import TaxableRebateSetupList from './pages/payroll/taxablerebatesetup/TaxableRebateSetupGrid.vue';
// import TaxableRebateSetupNew from './pages/payroll/taxablerebatesetup/TaxableRebateSetupNew.vue';
// import TaxableRebateSetupEdit from './pages/payroll/taxablerebatesetup/TaxableRebateSetupEdit.vue';

//import epf setup pages
import EPFSetupList from './pages/payroll/epfsetup/EPFSetupGrid.vue';
import EPFSetupNew from './pages/payroll/epfsetup/EPFSetupNew.vue';
import EPFSetupEdit from './pages/payroll/epfsetup/EPFSetupEdit.vue';

//import group assign pages
import PersonStatutoryInfoList from './pages/payroll/personstatutoryinfo/PersonStatutoryInfoGrid.vue';
import PersonStatutoryInfoNew from './pages/payroll/personstatutoryinfo/PersonStatutoryInfoNew.vue';
import PersonStatutoryInfoEdit from './pages/payroll/personstatutoryinfo/PersonStatutoryInfoEdit.vue';

//import person statutory number info pages
import GroupAssignmentList from './pages/personnel/groupassignment/GroupAssignmentGrid.vue';
import GroupAssignmentNew from './pages/personnel/groupassignment/GroupAssignmentNew.vue';
import GroupAssignmentEdit from './pages/personnel/groupassignment/GroupAssignmentEdit.vue';

//import tp1 pages 
import Tp1List from './pages/payroll/tp1/tp1Grid.vue';
import Tp1New from './pages/payroll/tp1/tp1New.vue';
import Tp1Edit from './pages/payroll/tp1/tp1Edit.vue';

//import headcount analysis by department page
import HeadcountAnalysis from './pages/personnel/headcountanalysis/HeadcountAnalysis.vue';
import ParameterHeadcount from './pages/personnel/headcountanalysis/Parameter.vue';

//import payslip pages 
import ParameterPayslip from './pages/payroll/payslip/Parameter.vue';
import Payslip from './pages/payroll/payslip/Payslip.vue';

//import payroll report
import PayrollSummaryListing from './pages/payroll/payrollreport/PayrollSummaryListing.vue';
import PayrollDetailListing from './pages/payroll/payrollreport/PayrollDetailListing.vue';
import PayrollSummaryAnalysis from './pages/payroll/payrollreport/PayrollSummaryAnalysis.vue';

//import 12 month payroll summary
import Staff12MonthPayrollSummary from './pages/payroll/staff12monthspayrollsummary/Staff12MonthPayrollSummary.vue'

//import payroll reconciliation pages 
import ParameterPayrollReconciliation from './pages/payroll/payrollreconciliation/Parameter.vue';
import PayrollReconciliation from './pages/payroll/payrollreconciliation/PayrollReconciliation.vue';
import ViewDetails from './pages/payroll/payrollreconciliation/ViewDetails.vue';

//import statutory report setup pages 
import StatutoryReportSetupList from './pages/payroll/statutoryreportsetup/StatutoryReportSetupGrid';
import StatutoryReportSetupNew from './pages/payroll/statutoryreportsetup/StatutoryReportSetupNew';
import StatutoryReportSetupEdit from './pages/payroll/statutoryreportsetup/StatutoryReportSetupEdit';

//import monthly epf submission record pages
import MonthlyEPFSubmissionRecordList from './pages/payroll/monthlyepfsubmissionrecord/MonthlyEPFSubmissionRecordGrid';
import MonthlyEPFSubmissionRecordNew from './pages/payroll/monthlyepfsubmissionrecord/MonthlyEPFSubmissionRecordNew';
import MonthlyEPFSubmissionRecordEdit from './pages/payroll/monthlyepfsubmissionrecord/MonthlyEPFSubmissionRecordEdit';

//import monthly tax submission pages
import MonthlyTaxSubmissionRecordList from './pages/payroll/monthytaxsubmissionrecord/MonthlyTaxSubmissionRecordGrid';
import MonthlyTaxSubmissionRecordNew from './pages/payroll/monthytaxsubmissionrecord/MonthlyTaxSubmissionRecordNew';
import MonthlyTaxSubmissionRecordEdit from './pages/payroll/monthytaxsubmissionrecord/MonthlyTaxSubmissionRecordEdit';


//import monthly ptptn submission record pages
import MonthlyPTPTNSubmissionRecordList from './pages/payroll/monthlyptptnsubmissionrecord/MonthlyPTPTNSubmissionRecordGrid';
import MonthlyPTPTNSubmissionRecordNew from './pages/payroll/monthlyptptnsubmissionrecord/MonthlyPTPTNSubmissionRecordNew';
import MonthlyPTPTNSubmissionRecordEdit from './pages/payroll/monthlyptptnsubmissionrecord/MonthlyPTPTNSubmissionRecordEdit';

//import cp38/39 pages
import ParameterTaxCP3839 from './pages/payroll/taxcp3839/Parameter.vue';
/* end of nawfal route imports */

/* faisal route imports */
//import payroll dashboard pages
import PayrollDashboardFilter from './pages/payroll/payrolldashboard/PayrollDashboardFilter.vue';
import PayrollDashboardSummary from './pages/payroll/payrolldashboard/PayrollDashboardSummary.vue';
import PayrollDashboardDetail from './pages/payroll/payrolldashboard/PayrollDashboardDetail.vue';
import PayrollDashboardDetail2 from './pages/payroll/payrolldashboard/PayrollDashboardDetail2.vue';

//import salary export record//
import SalaryExportRecordList from './pages/payroll/salaryexportrecord/SalaryExportRecordGrid.vue';
import SalaryExportRecordNew from './pages/payroll/salaryexportrecord/SalaryExportRecordNew.vue';
import SalaryExportRecordEdit from './pages/payroll/salaryexportrecord/SalaryExportRecordEdit.vue';

//import salary export//
import SalaryExport from './pages/payroll/salaryexport/SalaryExport.vue';
import SalaryExportParam from './pages/payroll/salaryexport/SalaryExportParam.vue';
import SalaryExportLog from './pages/payroll/salaryexport/SalaryExportLog.vue';
import SalaryExportParameter from './pages/payroll/salaryexport/SalaryExportParameter.vue';

//import Holiday Assignment pages//
import HolidayAssignmentList from './pages/leave/holidayassignment/HolidayAssignmentGrid.vue';
import HolidayAssignmentNew from './pages/leave/holidayassignment/HolidayAssignmentNew.vue';
import HolidayAssignmentEdit from './pages/leave/holidayassignment/HolidayAssignmentEdit.vue';

//import clinic pages//
import ClinicList from './pages/leave/clinic/ClinicGrid.vue';
import ClinicNew from './pages/leave/clinic/ClinicNew.vue';
import ClinicEdit from './pages/leave/clinic/ClinicEdit.vue';

//import rest day pattern setup pages//
import RestDayList from './pages/leave/restday/RestDayGrid.vue';
import RestDayNew from './pages/leave/restday/RestDayNew.vue';
import RestDayEdit from './pages/leave/restday/RestDayEdit.vue';

//import rest day schedule instruction pages//
import RDayScheduleInstructionList from './pages/leave/rdayscheduleinstruction/RDayScheduleInstructionGrid.vue';
import RDayScheduleInstructionNew from './pages/leave/rdayscheduleinstruction/RDayScheduleInstructionNew.vue';
import RDayScheduleInstructionEdit from './pages/leave/rdayscheduleinstruction/RDayScheduleInstructionEdit.vue';

//import rest day assignment by staff pages

//import RestDayStaff from './pages/leave/restdaystaff/RestDayStaff.vue';

//import leave setup pages//
import LeaveSetupList from './pages/leave/leavesetup/LeaveSetupGrid.vue';
import LeaveSetupNew from './pages/leave/leavesetup/LeaveSetupNew.vue';
import LeaveSetupEdit from './pages/leave/leavesetup/LeaveSetupEdit.vue';

//import leave entitlement policy pages
import LeaveEntitlementList from './pages/leave/leaveentitlementpolicy/LeaveEntitlementPolicyGrid.vue';
import LeaveEntitlementNew from './pages/leave/leaveentitlementpolicy/LeaveEntitlementPolicyNew.vue';
import LeaveEntitlementEdit from './pages/leave/leaveentitlementpolicy/LeaveEntitlementPolicyEdit.vue';

//import leave to payroll posting setup pages
import LeaveToPayrollPostingSetupList from './pages/leave/leavetopayrollpostingsetup/LeaveToPayrollPostingSetupGrid.vue';
import LeaveToPayrollPostingSetupNew from './pages/leave/leavetopayrollpostingsetup/LeaveToPayrollPostingSetupNew.vue';
import LeaveToPayrollPostingSetupEdit from './pages/leave/leavetopayrollpostingsetup/LeaveToPayrollPostingSetupEdit.vue';

//import leave carry forward policy pages
import LeaveCarryForwardPolicyForm from './pages/leave/carryforwardpolicy/LeaveCarryForwardPolicyForm.vue';

//import leave record pages
import LeaveRecordList from './pages/leave/leaverecord/LeaveRecordGrid.vue';
import LeaveRecordNew from './pages/leave/leaverecord/LeaveRecordNew.vue';
import LeaveRecordEdit from './pages/leave/leaverecord/LeaveRecordEdit.vue';

//import leave balance pages
import LeaveBalanceAdjList from './pages/leave/leavebalanceadj/LeaveBalanceAdjGrid.vue';
import LeaveBalanceAdjNew from './pages/leave/leavebalanceadj/LeaveBalanceAdjNew.vue';
import LeaveBalanceAdjEdit from './pages/leave/leavebalanceadj/LeaveBalanceAdjEdit.vue';

//import leave balance by staff
import LeaveBalanceByStaff from './pages/leave/leavebalance/LeaveBalanceByStaff.vue';

//import leave balance summary
import LeaveBalanceSummary from './pages/leave/leavebalancesummary/ParameterNew.vue';
import DetailsLeaveBalance from './pages/leave/leavebalancesummary/DetailsNew.vue';

//import payee input pages
import PayeeInputList from './pages/payroll/payeeinput/PayeeInputGrid.vue';
import PayeeInputNew from './pages/payroll/payeeinput/PayeeInputNew.vue';
import PayeeInputEdit from './pages/payroll/payeeinput/PayeeInputEdit.vue';

//import payroll preview pages
import PayrollPreviewFilter from './pages/payroll/payrollpreview/PayrollPreviewFilter.vue';
import PayrollPreviewSummary from './pages/payroll/payrollpreview/PayrollPreviewSummary.vue';
import PayrollPreviewDetail from './pages/payroll/payrollpreview/PayrollPreviewDetail.vue';

//import payroll finalization pages
import PayrollFinalizationList from './pages/payroll/payrollfinalization/PayrollFinalizationGrid.vue';
import PayrollFinalizationNew from './pages/payroll/payrollfinalization/PayrollFinalizationNew.vue';
import PayrollFinalizationEdit from './pages/payroll/payrollfinalization/PayrollFinalizationEdit.vue';

//import payroll preview pages
import PayrollExceptionFilter from './pages/payroll/payrollexception/PayrollExceptionFilter.vue';
import PayrollExceptionSummary from './pages/payroll/payrollexception/PayrollExceptionSummary.vue';
import PayrollExceptionDetail from './pages/payroll/payrollexception/PayrollExceptionDetail.vue';

//import person payroll direct assignment pages
import PersonPayrollDirectAssignmentList from './pages/payroll/personpayrolldirectassignment/PersonPayrollDirectAssignmentGrid.vue';
import PersonPayrollDirectAssignmentNew from './pages/payroll/personpayrolldirectassignment/PersonPayrollDirectAssignmentNew.vue';
import PersonPayrollDirectAssignmentEdit from './pages/payroll/personpayrolldirectassignment/PersonPayrollDirectAssignmentEdit.vue';

//import group payment pages
import GroupPaymentList from './pages/payroll/grouppayment/GroupPaymentGrid.vue';
import GroupPaymentNew from './pages/payroll/grouppayment/GroupPaymentNew.vue';
import GroupPaymentEdit from './pages/payroll/grouppayment/GroupPaymentEdit.vue';

//import epf export
import MonthlyEPFExport from './pages/payroll/monthlyepfexport/MonthlyEPFExport.vue';
import MonthlyEPFExportParam from './pages/payroll/monthlyepfexport/MonthlyEPFExportParam.vue';
import MonthlyEPFExportLog from './pages/payroll/monthlyepfexport/MonthlyEPFExportLog.vue';

//import EIS export page
import MonthlyEISExport from './pages/payroll/monthlyeisexport/MonthlyEISExport.vue';
import MonthlyEISExportParam from './pages/payroll/monthlyeisexport/MonthlyEISExportParam.vue';
import MonthlyEISExportLog from './pages/payroll/monthlyeisexport/MonthlyEISExportLog.vue';

//import Socso export  page
import MonthlySocsoExport from './pages/payroll/monthlysocsoexport/MonthlySocsoExport.vue';
import MonthlySocsoExportParam from './pages/payroll/monthlysocsoexport/MonthlySocsoExportParam.vue';
import MonthlySocsoExportLog from './pages/payroll/monthlysocsoexport/MonthlySocsoExportLog.vue';

// //import epf export
// import MonthlyPTPTNExport from './pages/payroll/monthlyptptnexport/MonthlyPTPTNExport.vue';
// import MonthlyPTPTNExportParam from './pages/payroll/monthlyptptnexport/MonthlyPTPTNExportParam.vue';
// import MonthlyPTPTNExportLog from './pages/payroll/monthlyptptnexport/MonthlyPTPTNExportLog.vue';

//import tax pcb ii pages
import ParameterTaxPcbII from './pages/payroll/taxpcbii/Parameter.vue';

//import ea form pages
import ParameterEaForm from './pages/payroll/eaform/Parameter.vue';


//import payroll command pages
import PayrollCommandFilter from './pages/payroll/payrollcommand/PayrollCommandFilter.vue';
import PayrollCommandProcess from './pages/payroll/payrollcommand/PayrollCommandProcess.vue';

//import staff doc pages 
import DocumentRecordList from "./pages/personnel/documentrecord/DocumentRecordGrid.vue";
import DocumentRecordNew from "./pages/personnel/documentrecord/DocumentRecordNew.vue";
import DocumentRecordEdit from "./pages/personnel/documentrecord/DocumentRecordEdit.vue";

//import exchrateset pages
import ExchangeRateSetList from "./pages/settings/exchangerateset/ExchangeRateSetGrid.vue";
import ExchangeRateSetNew from "./pages/settings/exchangerateset/ExchangeRateSetNew.vue";
import ExchangeRateSetEdit from "./pages/settings/exchangerateset/ExchangeRateSetEdit.vue";

//import Form A pages
import FormAExport from './pages/payroll/forma/FormAExport.vue';

//import eisocs Borang 8A pages
import EISSocso8AExport from './pages/payroll/eissocso8a/Form8AExport.vue';

//import Socso Borang 8A pages
import Socso8AExport from './pages/payroll/socso8a/Form8AExport.vue';

//import Pivot pages
import PayrollPivotFilter from "./pages/payroll/payrollpivot/PayrollPivotFilter.vue"
import PayrollPivotDetail from "./pages/payroll/payrollpivot/PayrollPivotDetail.vue"

//import wizard setup pages
import WizardList from "./pages/settings/wizard/WizardGrid.vue";
import WizardNew from "./pages/settings/wizard/WizardNew.vue";
import WizardEdit from "./pages/settings/wizard/WizardEdit.vue";
//import change password
import ChangePassword from './components/common/changepsw.vue';
import FirstTImeLogin from './components/common/FirstTimeLogin.vue';

//import reset password
import ResetPassword from './components/common/ResetPsw.vue';

//import forgot password
import ForgotPassword from './components/common/forgotpsw.vue';

//import data access
import DataAccessList from './pages/settings/dataaccess/DataAccessGrid.vue';
import DataAccessNew from './pages/settings/dataaccess/DataAccessNew.vue';
import DataAccessEdit from './pages/settings/dataaccess/DataAccessEdit.vue';

import AlertGrid from "./pages/settings/alert/AlertGrid.vue";
import AlertNew from "./pages/settings/alert/AlertNew.vue";
import AlertEdit from "./pages/settings/alert/AlertEdit.vue";

//import wizard listing
import WizardListing from './pages/settings/wizardlisting/WizardListing.vue';
import WizardStep from './pages/settings/wizardlisting/WizardStep.vue';

//import function access
import FunctionAccessList from './pages/settings/functionaccess/FunctionAccessGrid.vue';
import FunctionAccessNew from './pages/settings/functionaccess/FunctionAccessNew.vue';
import FunctionAccessEdit from './pages/settings/functionaccess/FunctionAccessEdit.vue';

import AlertListing from './pages/settings/alertlisting/AlertListing.vue';
import ImportFunction from './pages/settings/import/ImportData.vue';

import MappingFunction from './pages/settings/mapping/MappingGrid.vue';
import MappingNewFunction from './pages/settings/mapping/MappingNew.vue';
import MappingEditFunction from './pages/settings/mapping/MappingEdit.vue';
import MappingProcessFunction from './pages/settings/mapping/MappingProcess.vue';

import parameterServiceMovementListing from './pages/personnel/servicemovementlisting/ParameterNew.vue';
import detailsServiceMovementListing from './pages/personnel/servicemovementlisting/DetailsNew.vue';

import HolidayGrid from "./pages/leave/holiday/HolidayGrid.vue";
import HolidayNew from "./pages/leave/holiday/HolidayNew.vue";
import HolidayEdit from "./pages/leave/holiday/HolidayEdit.vue";

import AnnouncementGrid from "./pages/settings/announcement/AnnouncementGrid.vue";
import AnnouncementNew from "./pages/settings/announcement/AnnouncementNew.vue";
import AnnouncementEdit from "./pages/settings/announcement/AnnouncementEdit.vue";

import parameterPersonAndStaff from './pages/personnel/personandstaff/ParameterNew.vue';
import detailsPersonAndStaff from './pages/personnel/personandstaff/DetailsNew.vue';

import parameterMisc from './pages/payroll/miscpayrollList/ParameterNew.vue';
import detailsMisc from './pages/payroll/miscpayrollList/DetailsNew.vue';

import DisciplinaryList from './pages/personnel/disciplinaryrecordslisting/DisciplinaryGrid.vue';
import DisciplinaryNew from './pages/personnel/disciplinaryrecordslisting/DisciplinaryNew.vue';
import DisciplinaryEdit from './pages/personnel/disciplinaryrecordslisting/DisciplinaryEdit.vue';

import WorkflowList from './pages/personnel/workflowlisting/WorkflowListingGrid.vue';
import WorkflowNew from './pages/personnel/workflowlisting/WorkflowListingNew.vue';
import WorkflowEdit from './pages/personnel/workflowlisting/WorkflowListingEdit.vue';

//import shift setup pages//
import ShiftSetupList from './pages/attendance/shiftsetup/ShiftSetupGrid.vue';
import ShiftSetupNew from './pages/attendance/shiftsetup/ShiftSetupNew.vue';
import ShiftSetupEdit from './pages/attendance/shiftsetup/ShiftSetupEdit.vue';

//import period attendance incentive setup pages//
import PeriodShiftIncList from './pages/attendance/periodshiftinc/PeriodShiftIncGrid.vue';
import PeriodShiftIncNew from './pages/attendance/periodshiftinc/PeriodShiftIncNew.vue';
import PeriodShiftIncEdit from './pages/attendance/periodshiftinc/PeriodShiftIncEdit.vue';

//import work schedule group assignment//

import WorkScheduleGroupList from './pages/attendance/workschedulegroup/WorkScheduleGroupGrid.vue';
import WorkScheduleGroupNew from './pages/attendance/workschedulegroup/WorkScheduleGroupNew.vue';
import WorkScheduleGroupEdit from './pages/attendance/workschedulegroup/WorkScheduleGroupEdit.vue';

//import work schedule by staff for a period
import WorkScheduleStaffList from './pages/attendance/workschedulestaff/WorkScheduleStaffGrid.vue';
import WorkScheduleStaffNew from './pages/attendance/workschedulestaff/WorkScheduleStaffNew.vue';
import WorkScheduleStaffEdit from './pages/attendance/workschedulestaff/WorkScheduleStaffEdit.vue';

//import staff daily roster
import StaffDailyRosterList from './pages/attendance/staffdailyroster/StaffDailyRosterGrid.vue';

//import work hour pay item mapping
import WorkHoursPayItemMapping from './pages/attendance/workhourspayitemmapping/WorkHoursPayItemMappingGrid.vue';
import WorkHoursPayItemMappingNew from './pages/attendance/workhourspayitemmapping/WorkHoursPayItemMappingNew.vue';
import WorkHoursPayItemMappingEdit from './pages/attendance/workhourspayitemmapping/WorkHoursPayItemMappingEdit.vue';


//import pre approved ot
import PreApprovedOT from './pages/attendance/preapprovedot/PreApprovedOtGrid.vue';
import PreApprovedOTNew from './pages/attendance/preapprovedot/PreApprovedOtNew.vue';
import PreApprovedOTEdit from './pages/attendance/preapprovedot/PreApprovedOtEdit.vue';

//import process clock data
import ProcessClockData from './pages/attendance/processclockdata/ProcessClockData.vue';
import ProcessClockDataLog from "./pages/attendance/processclockdata/ProcessClockDataLog.vue";

//import processed attendance data
import ProcessedAttendanceData from './pages/attendance/processedattendacedata/ProcessedAttendanceData.vue';

//import attendance payroll posting
import AttendancePayrollPosting from './pages/payroll/attendancepayrollposting/AttendancePayrollPosting.vue';
import AttendancePostingLog from './pages/payroll/attendancepayrollposting/AttendancePayrollPostingLog.vue';


//import generate unpaid leave
import GenerateUnpaidLeave from './pages/attendance/generateunpaidleave/GenerateUnpaidLeave.vue';
import GenerateUnpaidLeaveLog from './pages/attendance/generateunpaidleave/GenerateUnpaidLeaveLog.vue';

//import generate unpaid leave
import GeneratePeriodicAttendanceIncentive from './pages/attendance/generateperiodicattnincentive/GeneratePeriodicAttendanceIncentive.vue';
import GeneratePeriodicAttendanceIncentiveLog from './pages/attendance/generateperiodicattnincentive/GeneratePeriodicAttendanceIncentiveLog.vue';

//import Audit Report
import parameterAuditListing from './pages/settings/auditlisting/ParameterNew.vue';
import detailsAuditListing from './pages/settings/auditlisting/DetailsNew.vue';

//import leave dashboard
import LeaveDashboardFilter from './pages/leave/leavedashboard/ParameterNew.vue';
import LeaveDashboardSummary from './pages/leave/leavedashboard/DetailsNew.vue';
//import LeaveDashboardDetail from './pages/leave/leavedashboard/LeaveDashboardDetail.vue';

//import asset 
import AssetGrid from './pages/personnel/asset/AssetGrid.vue';
import AssetNew from './pages/personnel/asset/AssetNew.vue';
import AssetEdit from './pages/personnel/asset/AssetEdit.vue';

//import asset assignment
import AssetAssignmentGrid from './pages/personnel/assetassignment/AssetAssignmentGrid.vue';
import AssetAssignmentNew from './pages/personnel/assetassignment/AssetAssignmentNew.vue';
import AssetAssignmentEdit from './pages/personnel/assetassignment/AssetAssignmentEdit.vue';

//import position assignment analysis
import PositionAssignmentAnalysis from './pages/personnel/positionassignmentanalysis/PositionAssignmentAnalysis.vue';
import PositionAssignmentAnalysisParameter from './pages/personnel/positionassignmentanalysis/Parameter.vue';

//import attendance report by period by company
import AttendancePeriod from './pages/attendance/attendanceperiod/ParameterNew.vue';
import DetailsAttendancePeriod from './pages/attendance/attendanceperiod/DetailsNew.vue';

import WhoIsOnLeaveDashboard from './pages/leave/whoisonleave/WhoIsOnLeaveDashboard.vue';
import WhoIsOnLeaveDetail from './pages/leave/whoisonleave/WhoIsOnLeaveDetail.vue';

//import late comer attendance report
import AttendanceLateComerListing from './pages/attendance/attendancelatecomer/ParameterNew.vue';
import DetailsAttendanceLateComer from './pages/attendance/attendancelatecomer/DetailsNew.vue';

//import absent attendance report
import AttendanceAbsentListing from './pages/attendance/attendanceabsent/ParameterNew.vue';
import DetailsAttendanceAbsent from './pages/attendance/attendanceabsent/DetailsNew.vue';

//claim module

//import claim type setup
import ClaimTypeSetupGrid from './pages/claim/claimtypesetup/ClaimTypeSetupGrid.vue';
import ClaimTypeSetupNew from './pages/claim/claimtypesetup/ClaimTypeSetupNew.vue';
import ClaimTypeSetupEdit from './pages/claim/claimtypesetup/ClaimTypeSetupEdit.vue';

//import claim limit setup
import ClaimLimitSetupGrid from './pages/claim/claimlimitsetup/ClaimLimitSetupGrid.vue';
import ClaimLimitSetupNew from './pages/claim/claimlimitsetup/ClaimLimitSetupNew.vue';
import ClaimLimitSetupEdit from './pages/claim/claimlimitsetup/ClaimLimitSetupEdit.vue';

//import claim record setup
import ClaimRecordGrid from './pages/claim/claimrecord/ClaimRecordGrid.vue';
import ClaimRecordNew from './pages/claim/claimrecord/ClaimRecordNew.vue';
import ClaimRecordEdit from './pages/claim/claimrecord/ClaimRecordEdit.vue';

//import claim limit adjustment setup
import ClaimLimitAdjustmentGrid from './pages/claim/claimlimitadjustment/ClaimLimitAdjustmentGrid.vue';
import ClaimLimitAdjustmentNew from './pages/claim/claimlimitadjustment/ClaimLimitAdjustmentNew.vue';
import ClaimLimitAdjustmentEdit from './pages/claim/claimlimitadjustment/ClaimLimitAdjustmentEdit.vue';

//import claim limit and utilization by staff
import ClaimLimitAndUtilizationByStaff from './pages/claim/claimlimitutilizationstaff/ClaimLimitUtilizationByStaff.vue';

//import claim utilization for period
import ClaimUtilizationPeriodDetails from './pages/claim/claimutilizationperiod/DetailsNew.vue';
import ClaimUtilizationPeriodParameter from './pages/claim/claimutilizationperiod/ParameterNew.vue';

//import workflow exception listing
import WorkflowExceptionList from './pages/settings/workflowexception/WorkflowExceptionGrid.vue';
import WorkflowExceptionNew from './pages/settings/workflowexception/WorkflowExceptionNew.vue';
import WorkflowExceptionEdit from './pages/settings/workflowexception/WorkflowExceptionEdit.vue';

//import claim transaction listing
import ClaimTransactionDetails from './pages/claim/claimtransaction/DetailsNew.vue';
import ClaimTransactionParameter from './pages/claim/claimtransaction/ParameterNew.vue';

//import HRDF listing
import HRDFListingDetails from './pages/payroll/hrdfList/DetailsNew.vue';
import HRDFListingParameter from './pages/payroll/hrdfList/ParameterNew.vue';

//import EIS SOCSO Lampiran 1 pages
import EISOCLampiran1 from './pages/payroll/eisoclampiran1/EISLampiran1Export.vue';

//import EIS Lampiran 1 pages
import EISLampiran1 from './pages/payroll/eislampiran1/EISLampiran1Export.vue';

//import claim utilization trend analysis
import ClaimUtilizationTrendAnalysisDetails from './pages/claim/claimutilizationtrendanalysis/DetailsNew.vue';
import ClaimUtilizationTrendAnalysisParameter from './pages/claim/claimutilizationtrendanalysis/ParameterNew.vue';

//import User Batch Creation
import BatchUserCreationParameter from './pages/settings/batchusercreation/BatchUserCreationParameter.vue';
import BatchUserCreationLog from './pages/settings/batchusercreation/BatchUserCreationLog.vue';
//import PTPTN Export pages
import PTPTNEexport from './pages/payroll/ptptnexport/PTPTNExport.vue';

//import Target Training Hours pages//
import TargetTrainingHoursList from './pages/training/targettraininghours/TargetTrainingHoursGrid.vue';
import TargetTrainingHoursNew from './pages/training/targettraininghours/TargetTrainingHoursNew.vue';
import TargetTrainingHoursEdit from './pages/training/targettraininghours/TargetTrainingHoursEdit.vue';

//import Training Record pages//
import TrainingRecordList from './pages/training/trainingrecord/TrainingRecordGrid.vue';
import TrainingRecordNew from './pages/training/trainingrecord/TrainingRecordNew.vue';
import TrainingRecordEdit from './pages/training/trainingrecord/TrainingRecordEdit.vue';

//import training hours analysis 
import TrainingHoursAnalysisDetails from './pages/training/traininghoursanalysis/DetailsNew.vue';
import TrainingHoursAnalysisParameter from './pages/training/traininghoursanalysis/ParameterNew.vue';

//import training expenses analysis 
import TrainingExpensesAnalysisDetails from './pages/training/trainingexpensesanalysis/DetailsNew.vue';
import TrainingExpensesAnalysisParameter from './pages/training/trainingexpensesanalysis/ParameterNew.vue';

//import Group Insurance Policy pages//
import GroupInsurancePolicyList from './pages/insurance/groupinsurancepolicy/GroupInsurancePolicyGrid.vue';
import GroupInsurancePolicyNew from './pages/insurance/groupinsurancepolicy/GroupInsurancePolicyNew.vue';
import GroupInsurancePolicyEdit from './pages/insurance/groupinsurancepolicy/GroupInsurancePolicyEdit.vue';

//import Report Export Mapping pages//
import ReportExportMappingList from './pages/insurance/reportexportmapping/ReportExportMappingGrid.vue';
import ReportExportMappingNew from './pages/insurance/reportexportmapping/ReportExportMappingNew.vue';
import ReportExportMappingEdit from './pages/insurance/reportexportmapping/ReportExportMappingEdit.vue';

//import Group Insurance Assignment pages//
import GrpInsAssignmentList from './pages/insurance/grpinsassignment/GrpInsAssignmentGrid.vue';
import GrpInsAssignmentNew from './pages/insurance/grpinsassignment/GrpInsAssignmentNew.vue';
import GrpInsAssignmentEdit from './pages/insurance/grpinsassignment/GrpInsAssignmentEdit.vue';

//import Export Staff Insurance Report pages//
import ExportStaffInsurance from './pages/insurance/exportstaffinsurance/ExportStaffInsuranceParameter.vue';
import ExportStaffInsuranceDetail from './pages/insurance/exportstaffinsurance/ExportStaffInsurance.vue';
import ExportStaffInsuranceLog from './pages/insurance/exportstaffinsurance/ExportStaffInsuranceLog.vue';

//import Rating Scale Set pages//
import RatingScaleSetList from './pages/performance/ratingscaleset/RatingScaleSetGrid.vue';
import RatingScaleSetNew from './pages/performance/ratingscaleset/RatingScaleSetNew.vue';
import RatingScaleSetEdit from './pages/performance/ratingscaleset/RatingScaleSetEdit.vue';

//import Ranking Set pages//
import RankingSetList from './pages/performance/rankingset/RankingSetGrid.vue';
import RankingSetNew from './pages/performance/rankingset/RankingSetNew.vue';
import RankingSetEdit from './pages/performance/rankingset/RankingSetEdit.vue';

//import Appraisal Exercise pages//
import AppraisalExerciseList from './pages/performance/appraisalexercise/AppraisalExerciseGrid.vue';
import AppraisalExerciseNew from './pages/performance/appraisalexercise/AppraisalExerciseNew.vue';
import AppraisalExerciseEdit from './pages/performance/appraisalexercise/AppraisalExerciseEdit.vue';

//import Appraisal Record pages//
import AppraisalRecordList from './pages/performance/appraisalrecord/AppraisalRecordGrid.vue';
import AppraisalRecordNew from './pages/performance/appraisalrecord/AppraisalRecordNew.vue';
import AppraisalRecordEdit from './pages/performance/appraisalrecord/AppraisalRecordEdit.vue';

//import performance review analysis 
import PerformanceReviewDetails from './pages/performance/performancereview/DetailsNew.vue';
import PerformanceReviewParameter from './pages/performance/performancereview/ParameterNew.vue';

//import performance reward summary 
import PerformanceRewardDetails from './pages/performance/performancereward/DetailsNew.vue';
import PerformanceRewardParameter from './pages/performance/performancereward/ParameterNew.vue';

//Kpi setting Set pages//
import KpiSettingList from './pages/performancemanagement/targetsetting/KpiSettingGrid.vue';
import KpiSettingNew from './pages/performancemanagement/targetsetting/KpiSettingNew.vue';
import KpiSettingEdit from './pages/performancemanagement/targetsetting/KpiSettingEdit.vue';
import KpiSettingEditCommonKpi from './pages/performancemanagement/targetsetting/KpiSettingEditCommonKpi.vue';
import KpiSettingGenerate from './pages/performancemanagement/targetsetting/KpiSettingGenerate.vue';
import KpiSettingGenerateStaff from './pages/performancemanagement/targetsetting/KpiSettingGenerateStaff.vue';
import KpiSettingStaff from './pages/performancemanagement/targetsetting/KpiSettingStaff.vue';
import KpiSettingStaffExercise from './pages/performancemanagement/targetsetting/KpiSettingStaffExercise.vue';

//import miscellaneous export  page
import MonthlyMiscellaneousExport from './pages/payroll/monthlymiscellaneousexport/MonthlyMiscellaneousExport.vue';
import MonthlyMiscellaneousExportParam from './pages/payroll/monthlymiscellaneousexport/MonthlyMiscellaneousExportParam.vue';
import MonthlyMiscellaneousExportLog from './pages/payroll/monthlymiscellaneousexport/MonthlyMiscellaneousExportLog.vue';

//import Workflow Maintenance pages
import WorkflowMaintenanceList from './pages/settings/workflowmaintenance/WorkflowMaintenanceGrid.vue';
import WorkflowMaintenanceNew from './pages/settings/workflowmaintenance/WorkflowMaintenanceNew.vue';
import WorkflowMaintenanceEdit from './pages/settings/workflowmaintenance/WorkflowMaintenanceEdit.vue';

//import Workflow Maintenance Batch pages
import WorkflowMaintenanceBatch from './pages/settings/workflowmaintenancebatch/WorkflowMaintenanceBatch.vue';

//import IR Case Maintenance
import IRCaseMaintenanceList from './pages/industrialrelations/ircasemaintenance/IRCaseMaintenanceGrid.vue';
import IRCaseMaintenanceNew from './pages/industrialrelations/ircasemaintenance/IRCaseMaintenanceNew.vue';
import IRCaseMaintenanceEdit from './pages/industrialrelations/ircasemaintenance/IRCaseMaintenanceEdit.vue';

// //import Case Subject Maintenance
import CaseSubjectMaintenanceList from './pages/industrialrelations/casesubjectmaintenance/CaseSubMaintenanceGrid.vue';
import CaseSubjectMaintenanceNew from './pages/industrialrelations/casesubjectmaintenance/CaseSubMaintenanceNew.vue';
import CaseSubjectMaintenanceEdit from './pages/industrialrelations/casesubjectmaintenance/CaseSubMaintenanceEdit.vue';

//import Domestic Inquiry Maintenance
import IRDomesticInquiryList from './pages/industrialrelations/domesticinquiry/DomesticInquiryGrid.vue';
import IRDomesticInquiryNew from './pages/industrialrelations/domesticinquiry/DomesticInquiryNew.vue';
import IRDomesticInquiryEdit from './pages/industrialrelations/domesticinquiry/DomesticInquiryEdit.vue';

// Staff Enquiry
import StaffEnquiryParameter from './pages/personnel/staffenquiry/ParameterNew.vue';
import StaffEnquiryDetails from './pages/personnel/staffenquiry/DetailsNew.vue';

// Claim Enquiry
import ClaimEnquiryParameter from './pages/claim/claimenquiry/ParameterNew.vue';
import ClaimEnquiryDetails from './pages/claim/claimenquiry/DetailsNew.vue';

// Leave Enquiry
import LeaveEnquiryParameter from './pages/leave/leaveenquiry/ParameterNew.vue';
import LeaveEnquiryDetails from './pages/leave/leaveenquiry/DetailsNew.vue';

// Payroll Enquiry
import PayrollEnquiryFilter from './pages/payroll/payrollenquiry/PayrollEnquiryFilter.vue';
import PayrollEnquiryDetail from './pages/payroll/payrollenquiry/PayrollEnquiryDetail.vue';

// Payroll Enquiry By Service Movement
import PayrollEnquiryBySmFilter from './pages/payroll/payrollenquirybysm/PayrollEnquiryBySmFilter.vue';
import PayrollEnquiryBySmDetail from './pages/payroll/payrollenquirybysm/PayrollEnquiryBySmDetail.vue';

// Attendance Enquiry
import AttendanceEnquiryFilter from './pages/attendance/attendanceenquiry/AttendanceEnquiryFilter.vue';
import AttendanceEnquiryDetail from './pages/attendance/attendanceenquiry/AttendanceEnquiryDetail.vue';

// Attendance Summary by Staff
import AttendanceSummaryByStaffFilter from './pages/attendance/attendancesummarybystaff/AttendanceSummaryByStaffFilter.vue';
import AttendanceSummaryByStaffDetail from './pages/attendance/attendancesummarybystaff/AttendanceSummaryByStaffDetail.vue';

// Process Log Listing
import ProcessLogListing from './pages/settings/processloglisting/ProcessLogListing.vue';
import ProcessLogListingDetail from './pages/settings/processloglisting/ProcessLogListingDetail.vue';

//SOSCO&EIS Submission Record
import eissocsubList from './pages/payroll/eissocsubmissionrecord/eissocsubGrid.vue';
import eissocsubNew from './pages/payroll/eissocsubmissionrecord/eissocsubNew.vue';
import eissocsubEdit from './pages/payroll/eissocsubmissionrecord/eissocsubEdit.vue';

//import Socso export  page
import MonthlyEISSocsoExport from './pages/payroll/monthlyeissocsoexport/MonthlyEISSocsoExport.vue';
import MonthlyEISSocsoExportParam from './pages/payroll/monthlyeissocsoexport/MonthlyEISSocsoExportParam.vue';
import MonthlyEISSocsoExportLog from './pages/payroll/monthlyeissocsoexport/MonthlyEISSocsoExportLog.vue';

// Staff Payroll Direct Assignment Enquiry
import StaffPayrollDirectAssignmentEnquiryparameter from './pages/payroll/staffpayrolldirectassignmentenquiry/Parameter.vue';
import StaffPayrollDirectAssignmentEnquirydetail from './pages/payroll/staffpayrolldirectassignmentenquiry/Detail.vue';

// // Organization Chart 
import OrgChartFilter from './pages/personnel/orgchart/OrgChartFilter.vue';
import OrgChartDetails from './pages/personnel/orgchart/OrgChartDetails.vue';

// System Logo
import SystemLogo from './pages/settings/systemlogo/SystemLogo.vue';

// Qualification 
import QualificationList from './pages/personnel/qualification/QualificationGrid.vue';
import QualificationNew from './pages/personnel/qualification/QualificationNew.vue';
import QualificationEdit from './pages/personnel/qualification/QualificationEdit.vue';

// Self-Service Home
import SelfServiceHome from './sspages/home/SelfServiceHome.vue'

// Self-Service My Task
import SelfServiceMyTask from './sspages/mytask/MyTask.vue'

// Self-Service Leave Approval
import SelfServiceLeaveApproval from './sspages/leaveapproval/LeaveApproval.vue'

// Self-Service Claim Approval
import SelfServiceClaimApproval from './sspages/claimapproval/ClaimApproval.vue'

// Self-Service Claim Approval Details
import SelfServiceClaimApprovalDetails from './sspages/claimapprovaldetails/ClaimApprovalDetails.vue'

// E-doc
import EdocList from './pages/settings/edocument/EdocGrid.vue';
import EdocNew from './pages/settings/edocument/EdocNew.vue';
import EdocEdit from './pages/settings/edocument/EdocEdit.vue';

//import Payroll Audit Export pages
import PayrollAuditExport from './pages/payroll/payrollaudit/PayrollAuditExport.vue';

// myanmar ssb setup
import mmssbsetupList from './pages/payroll/mmssbsetup/SsbSetupGrid.vue';
import mmssbsetupNew from './pages/payroll/mmssbsetup/SsbSetupNew.vue';
import mmssbsetupEdit from './pages/payroll/mmssbsetup/SsbSetupEdit.vue'; 

// myanmar tax rate setup
import mmtaxratesetupList from './pages/payroll/mmtaxratesetup/TaxratesetupGrid.vue';
import mmtaxratesetupNew from './pages/payroll/mmtaxratesetup/TaxratesetupNew.vue';
import mmtaxratesetupEdit from './pages/payroll/mmtaxratesetup/TaxratesetupEdit.vue'; 

// myanmar person statutory
import mmpsnstatutoryList from './pages/payroll/mmpsnstatutory/PsnStatutoryGrid.vue';
import mmpsnstatutoryNew from './pages/payroll/mmpsnstatutory/PsnStatutoryNew.vue';
import mmpsnstatutoryEdit from './pages/payroll/mmpsnstatutory/PsnStatutoryEdit.vue'; 

// myanmar person tax bf
import mmpsntaxbfList from './pages/payroll/mmpsntaxbf/PsnTaxBfGrid.vue';
import mmpsntaxbfNew from './pages/payroll/mmpsntaxbf/PsnTaxBfNew.vue';
import mmpsntaxbfEdit from './pages/payroll/mmpsntaxbf/PsnTaxBfEdit.vue'; 

// myanmar tax period setup
import MMTaxPeriodSetupList from './pages/payroll/mmtaxperiodsetup/TaxPeriodGrid.vue';
import MMTaxPeriodSetupNew from './pages/payroll/mmtaxperiodsetup/TaxPeriodNew.vue';
import MMTaxPeriodSetupEdit from './pages/payroll/mmtaxperiodsetup/TaxPeriodEdit.vue'; 

// myanmar tax rebate limit setup
import MMTaxableRebateLimitList from './pages/payroll/mmtaxablerebatelimit/TaxableRebateLimitGrid.vue';
import MMTaxableRebateLimitNew from './pages/payroll/mmtaxablerebatelimit/TaxableRebateLimitNew.vue';
import MMTaxableRebateLimitEdit from './pages/payroll/mmtaxablerebatelimit/TaxableRebateLimitEdit.vue'; 

// myanmar person taxable rebate registrationsetup
import MMPersonTaxableRebateRegistrationList from './pages/payroll/mmpersontaxablerebatereg/PersonTaxableRebateRegGrid.vue';
import MMPersonTaxableRebateRegistrationNew from './pages/payroll/mmpersontaxablerebatereg/PersonTaxableRebateRegNew.vue';
import MMPersonTaxableRebateRegistrationEdit from './pages/payroll/mmpersontaxablerebatereg/PersonTaxableRebateRegEdit.vue'; 

// myanmar tax submission record
import MMMonthlyTaxSubmissionRecordList from './pages/payroll/mmmonthlytaxsubmissionrec/MonthlyTaxSubmissionRecordGrid';
import MMMonthlyTaxSubmissionRecordNew from './pages/payroll/mmmonthlytaxsubmissionrec/MonthlyTaxSubmissionRecordNew';
import MMMonthlyTaxSubmissionRecordEdit from './pages/payroll/mmmonthlytaxsubmissionrec/MonthlyTaxSubmissionRecordEdit';

// myanmar tax export
import MMMonthlyTaxExport from './pages/payroll/mmmonthlytaxexport/MonthlyTaxExport.vue';
import MMMonthlyTaxExportParam from './pages/payroll/mmmonthlytaxexport/MonthlyTaxExportParam.vue';
import MMMonthlyTaxExportLog from './pages/payroll/mmmonthlytaxexport/MonthlyTaxExportLog.vue';

// myanmar ssb submission record
import MMMonthlySSBSubmissionRecordList from './pages/payroll/mmmonthlyssbsubmissionrec/MonthlySSBSubmissionRecordGrid';
import MMMonthlySSBSubmissionRecordNew from './pages/payroll/mmmonthlyssbsubmissionrec/MonthlySSBSubmissionRecordNew';
import MMMonthlySSBSubmissionRecordEdit from './pages/payroll/mmmonthlyssbsubmissionrec/MonthlySSBSubmissionRecordEdit';

// myanmar ssb export
import MMMonthlySSBExport from './pages/payroll/mmmonthlyssbexport/MonthlySSBExport.vue';
import MMMonthlySSBExportParam from './pages/payroll/mmmonthlyssbexport/MonthlySSBExportParam.vue';
import MMMonthlySSBExportLog from './pages/payroll/mmmonthlyssbexport/MonthlySSBExportLog.vue';

//myanmar payslip pages 
import MMParameterPayslip from './pages/payroll/mmpayslip/Parameter.vue';
import MMPayslip from './pages/payroll/mmpayslip/Payslip.vue';

//import lateness dedecution policy setup
import LatenessDeductionPolicyGrid from './pages/latenessdeduction/policy/policyGrid.vue';
import LatenessDeductionPolicyNew from './pages/latenessdeduction/policy/policyNew.vue';
import LatenessDeductionPolicyEdit from './pages/latenessdeduction/policy/policyEdit.vue';
//import lateness dedecution generate
import LatenessDeductionGenerate from './pages/latenessdeduction/generate/Generate.vue';
import LatenessDeductionGenerateLog from './pages/latenessdeduction/generate/GenerateLog.vue';
//import lateness dedecution listing
import LatenessDeductionListing from './pages/latenessdeduction/listing/Listing.vue';
import LatenessDeductionDetailsListing from './pages/latenessdeduction/listing/ListingDetails.vue';
import LatenessDeductionDetailsEdit from './pages/latenessdeduction/listing/Edit.vue';

import LatenessDeductionPosting from './pages/latenessdeduction/posting/Posting.vue';
import LatenessDeductionPostingLog from './pages/latenessdeduction/posting/PostingLog.vue';

import GLCodeSetup from './pages/payroll/glcodesetup/GLCodeSetup.vue';
import GLCodeMappingPolicyList from './pages/payroll/glcodemappingpolicy/GLCodeMappingPolicyGrid.vue';
import GLCodeMappingPolicyNew from './pages/payroll/glcodemappingpolicy/GLCodeMappingPolicyNew.vue';
import GLCodeMappingPolicyEdit from './pages/payroll/glcodemappingpolicy/GLCodeMappingPolicyEdit.vue';

import GLCodeMappingListingList from './pages/payroll/glcodemappinglisting/GLCodeMappingListingGrid.vue';
import GLCodeMappingListingNew from './pages/payroll/glcodemappinglisting/GLCodeMappingListingNew.vue';
import GLCodeMappingListingEdit from './pages/payroll/glcodemappinglisting/GLCodeMappingListingEdit.vue';

import GLExport from './pages/payroll/glexport/GLExport.vue';
import GLExportParam from './pages/payroll/glexport/GLExportParam.vue';
import GLExportLog from './pages/payroll/glexport/GLExportLog.vue';
import GLExportParameter from './pages/payroll/glexport/GLExportParameter.vue';

// intgsetup
import intgsetupList from './pages/settings/intgsetup/intgsetupGrid.vue';
import intgsetupNew from './pages/settings/intgsetup/intgsetupNew.vue';
import intgsetupEdit from './pages/settings/intgsetup/intgsetupEdit.vue';

Vue.use(Router)

export default [
    {
        path: "/:lang/home/:db",
        component: Login,
        meta: {
            guest: true,
            HideHeader: true
        }
    },
    // {
    //     path: "/:lang/home",
    //     name: "login",
    //     component: Login,
    //     meta: {
    //         guest: true,
    //         HideHeader: true
    //     }
    // },

    {
        path: "/:lang/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: "/:lang/login",
        component: Login,
        meta: {
            guest: true,
            HideHeader: true
        }
    },
    {
        path: "/:lang/firsttimelogin/:id",
        name: "firsttimelogin",
        component: FirstTImeLogin,
        meta: {
            guest: true,
            HideHeader: true
        }
    },
    {
        path: "/:lang/changepassword/:id",
        name: "changepassword",
        component: ChangePassword,
        meta: {
            guest: true,
        }
    },
    {
        path: "/:lang/forgotpassword/",
        name: "forgotpassword",
        component: ForgotPassword,
        meta: {
            guest: true,
            HideHeader: true
        }
    },
    {
        path: "/:lang/resetpassword/:token",
        name: "resetpassword",
        component: ResetPassword,
        meta: {
            guest: true,
            HideHeader: true
        }
    },
    {
        path: "/:lang/resetpassword/:token/:db",
        name: "resetpassword",
        component: ResetPassword,
        meta: {
            guest: true,
            HideHeader: true
        }
    },
    {
        path: "/:lang/passwordpolicy/list",
        name: "passwordpolicylist",
        component: PasswordPolicyList,
        meta: {
            requiresAuth: true,
            funcname: "Password Policy",
        }
    },

    {
        path: "/:lang/passwordpolicy/new",
        name: "passwordpolicynew",
        component: PasswordPolicyNew,
        meta: {
            requiresAuth: true,
            funcname: "Password Policy",
        }
    },

    {
        path: "/:lang/passwordpolicy/edit/:id",
        name: "passwordpolicyedit",
        component: PasswordPolicyEdit,
        meta: {
            requiresAuth: true,
            funcname: "Password Policy",
        }

    },

    {
        path: "/:lang/secuser/list",
        name: "secuserlist",
        component: SecuserList,
        meta: {
            requiresAuth: true,
            funcname: "User",
        }
    },

    {
        path: "/:lang/secuser/new",
        name: "secusernew",
        component: SecuserNew,
        meta: {
            requiresAuth: true,
            funcname: "User",
        }
    },

    {
        path: "/:lang/secuser/edit/:id",
        name: "secuseredit",
        component: SecuserEdit,
        meta: {
            requiresAuth: true,
            funcname: "User",
        }

    },

    {
        path: "/:lang/company/list",
        name: "companylist",
        component: CompanyList,
        meta: {
            requiresAuth: true,
            funcname: "Company"
        }
    },

    {
        path: "/:lang/company/new",
        name: "companynew",
        component: CompanyNew,
        meta: {
            requiresAuth: true,
            funcname: "Company"
        }
    },

    {
        path: "/:lang/company/edit/:id",
        name: "companyedit",
        component: CompanyEdit,
        meta: {
            requiresAuth: true,
            funcname: "Company"
        }
    },

    {
        path: "/:lang/address/list",
        name: "addresslist",
        component: AddressList,
        meta: {
            requiresAuth: true,
            funcname: "Address Master"
        }
    },

    {
        path: "/:lang/address/new",
        name: "addressnew",
        component: AddressNew,
        meta: {
            requiresAuth: true,
            funcname: "Address Master"

        }
    },

    {
        path: "/:lang/address/edit/:id",
        name: "addressedit",
        component: AddressEdit,
        meta: {
            requiresAuth: true,
            funcname: "Address Master"
        }
    },

    {
        path: "/:lang/branch/list",
        name: "branchlist",
        component: BranchList,
        meta: {
            requiresAuth: true,
            funcname: "Branch"
        }
    },

    {
        path: "/:lang/branch/new",
        name: "branchnew",
        component: BranchNew,
        meta: {
            requiresAuth: true,
            funcname: "Branch"
        }
    },

    {
        path: "/:lang/branch/edit/:id",
        name: "branchedit",
        component: BranchEdit,
        meta: {
            requiresAuth: true,
            funcname: "Branch"
        }
    },

    {
        path: "/:lang/division/list",
        name: "divisionlist",
        component: DivisionList,
        meta: {
            requiresAuth: true,
            funcname: "Division"
        }
    },

    {
        path: "/:lang/division/new",
        name: "divisionnew",
        component: DivisionNew,
        meta: {
            requiresAuth: true,
            funcname: "Division"
        }
    },

    {
        path: "/:lang/division/edit/:id",
        name: "divisionedit",
        component: DivisionEdit,
        meta: {
            requiresAuth: true,
            funcname: "Division"
        }
    },

    {
        path: "/:lang/department/list",
        name: "departmentlist",
        component: DepartmentList,
        meta: {
            requiresAuth: true,
            funcname: "Department"
        }
    },

    {
        path: "/:lang/department/new",
        name: "departmentnew",
        component: DepartmentNew,
        meta: {
            requiresAuth: true,
            funcname: "Department"
        }
    },

    {
        path: "/:lang/department/edit/:id",
        name: "departmentedit",
        component: DepartmentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Department"
        }
    },

    {
        path: "/:lang/section/list",
        name: "sectionlist",
        component: SectionList,
        meta: {
            requiresAuth: true,
            funcname: "Section"
        }
    },

    {
        path: "/:lang/section/new",
        name: "sectionnew",
        component: SectionNew,
        meta: {
            requiresAuth: true,
            funcname: "Section"
        }
    },

    {
        path: "/:lang/section/edit/:id",
        name: "sectionedit",
        component: SectionEdit,
        meta: {
            requiresAuth: true,
            funcname: "Section"
        }
    },

    {
        path: "/:lang/unit/list",
        name: "unitlist",
        component: UnitList,
        meta: {
            requiresAuth: true,
            funcname: "Unit"
        }
    },

    {
        path: "/:lang/unit/new",
        name: "unitnew",
        component: UnitNew,
        meta: {
            requiresAuth: true,
            funcname: "Unit"
        }
    },

    {
        path: "/:lang/unit/edit/:id",
        name: "unitedit",
        component: UnitEdit,
        meta: {
            requiresAuth: true,
            funcname: "Unit"
        }
    },

    {
        path: "/:lang/servicerecord/list",
        name: "servicerecordlist",
        component: ServiceRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Service Record Type"
        }
    },

    {
        path: "/:lang/servicerecord/new",
        name: "servicerecordnew",
        component: ServiceRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Service Record Type"
        }
    },

    {
        path: "/:lang/servicerecord/edit/:id",
        name: "servicerecordedit",
        component: ServiceRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Service Record Type"
        }
    },

    {
        path: "/:lang/basicfreq/list",
        name: "basicfreqlist",
        component: BasicFreqList,
        meta: {
            requiresAuth: true,
            funcname: "Salary Frequency"
        }
    },

    {
        path: "/:lang/basicfreq/new",
        name: "basicfreqnew",
        component: BasicFreqNew,
        meta: {
            requiresAuth: true,
            funcname: "Salary Frequency"
        }
    },

    {
        path: "/:lang/basicfreq/edit/:id",
        name: "basicfreqedit",
        component: BasicFreqEdit,
        meta: {
            requiresAuth: true,
            funcname: "Salary Frequency"
        }
    },

    {
        path: "/:lang/emptype/list",
        name: "emptypelist",
        component: EmpTypeList,
        meta: {
            requiresAuth: true,
            funcname: "Employment Type"
        }
    },

    {
        path: "/:lang/emptype/new",
        name: "emptypenew",
        component: EmpTypeNew,
        meta: {
            requiresAuth: true,
            funcname: "Employment Type"
        }
    },

    {
        path: "/:lang/emptype/edit/:id",
        name: "emptypeedit",
        component: EmpTypeEdit,
        meta: {
            requiresAuth: true,
            funcname: "Employment Type"
        }
    },

    {
        path: "/:lang/grade/list",
        name: "gradelist",
        component: GradeList,
        meta: {
            requiresAuth: true,
            funcname: "Grade"
        }
    },

    {
        path: "/:lang/grade/new",
        name: "gradenew",
        component: GradeNew,
        meta: {
            requiresAuth: true,
            funcname: "Grade"
        }
    },

    {
        path: "/:lang/grade/edit/:id",
        name: "gradeedit",
        component: GradeEdit,
        meta: {
            requiresAuth: true,
            funcname: "Grade"
        }
    },

    {
        path: "/:lang/job/list",
        name: "joblist",
        component: JobList,
        meta: {
            requiresAuth: true,
            funcname: "Job"
        }
    },

    {
        path: "/:lang/job/new",
        name: "jobnew",
        component: JobNew,
        meta: {
            requiresAuth: true,
            funcname: "Job"
        }
    },

    {
        path: "/:lang/job/edit/:id",
        name: "jobedit",
        component: JobEdit,
        meta: {
            requiresAuth: true,
            funcname: "Job"
        }
    },

    {
        path: "/:lang/position/list",
        name: "positionlist",
        component: PositionList,
        meta: {
            requiresAuth: true,
            funcname: "Position"
        }
    },

    {
        path: "/:lang/position/new",
        name: "positionnew",
        component: PositionNew,
        meta: {
            requiresAuth: true,
            funcname: "Position"
        }
    },

    {
        path: "/:lang/position/edit/:id",
        name: "positionedit",
        component: PositionEdit,
        meta: {
            requiresAuth: true,
            funcname: "Position"
        }
    },

    {
        path: "/:lang/letter/list",
        name: "letterlist",
        component: LetterList,
        meta: {
            requiresAuth: true,
            funcname: "Letter"
        }
    },

    {
        path: "/:lang/letter/new",
        name: "letternew",
        component: LetterNew,
        meta: {
            requiresAuth: true,
            funcname: "Letter"
        }
    },

    {
        path: "/:lang/letter/edit/:id",
        name: "letteredit",
        component: LetterEdit,
        meta: {
            requiresAuth: true,
            funcname: "Letter"
        }
    },

    // {
    //     path: "/:lang/letterprint/filter",
    //     name: "letterprintfilter",
    //     component: LetterPrintFilter,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "Letter"
    //     }
    // },

    // {
    //     path: "/:lang/letterprint/process",
    //     name: "letterprintprocess",
    //     component: LetterPrintProcess,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "Letter"
    //     }
    // },

    // {
    //     path: "/:lang/settings/user/list",
    //     name: "userlist",
    //     component: UserList,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "",
    //     }
    // },

    // {
    //     path: "/:lang/settings/user/new",
    //     name: "usernew",
    //     component: UserNew,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "",
    //     }
    // },

    {
        path: "/:lang/person/list",
        name: "personlist",
        component: PersonList,
        meta: {
            requiresAuth: true,
            funcname: "Personal Info"
        }
    },

    {
        path: "/:lang/person/new",
        name: "personnew",
        component: PersonNew,
        meta: {
            requiresAuth: true,
            funcname: "Personal Info"
        }
    },

    {
        path: "/:lang/person/edit/:id",
        name: "personedit",
        component: PersonEdit,
        meta: {
            requiresAuth: true,
            funcname: "Personal Info"
        }
    },

    {
        path: "/:lang/staff/list",
        name: "stafflist",
        component: StaffList,
        meta: {
            requiresAuth: true,
            funcname: "Staff Full Details"
        }
    },

    {
        path: "/:lang/staff/new",
        name: "staffnew",
        component: StaffNew,
        meta: {
            requiresAuth: true,
            funcname: "Staff Full Details"
        }
    },

    {
        path: "/:lang/staff/edit/:id",
        name: "staffedit",
        component: StaffEdit,
        meta: {
            requiresAuth: true,
            funcname: "Staff Full Details"
        }
    },

    {
        path: "/:lang/staffs/list",
        name: "stafflisths",
        component: StaffListHS,
        meta: {
            requiresAuth: true,
            funcname: "Staff General Details"
        }
    },

    {
        path: "/:lang/staffs/new",
        name: "staffnewhs",
        component: StaffNewHS,
        meta: {
            requiresAuth: true,
            funcname: "Staff General Details"
        }
    },

    {
        path: "/:lang/staffs/edit/:id",
        name: "staffediths",
        component: StaffEditHS,
        meta: {
            requiresAuth: true,
            funcname: "Staff General Details"
        }
    },

    {
        path: "/:lang/affectitem/list",
        name: "affectitemlist",
        component: AffectItemList,
        meta: {
            requiresAuth: true,
            funcname: "Affect Item"
        }
    },

    {
        path: "/:lang/affectitem/new",
        name: "affectitemnew",
        component: AffectItemNew,
        meta: {
            requiresAuth: true,
            funcname: "Affect Item"
        }
    },

    {
        path: "/:lang/affectitem/edit/:id",
        name: "affectitemedit",
        component: AffectItemEdit,
        meta: {
            requiresAuth: true,
            funcname: "Affect Item"
        }
    },

    {
        path: "/:lang/affecttype/list",
        name: "affecttypelist",
        component: AffectTypeList,
        meta: {
            requiresAuth: true,
            funcname: "Affect Type"
        }
    },

    {
        path: "/:lang/affecttype/new",
        name: "affecttypenew",
        component: AffectTypeNew,
        meta: {
            requiresAuth: true,
            funcname: "Affect Type"
        }
    },

    {
        path: "/:lang/affecttype/edit/:id",
        name: "affecttypeedit",
        component: AffectTypeEdit,
        meta: {
            requiresAuth: true,
            funcname: "Affect Type"
        }
    },

    {
        path: "/:lang/payitemtype/list",
        name: "payitemtypelist",
        component: PayItemTypeList,
        meta: {
            requiresAuth: true,
            funcname: "Pay Item Type"
        }
    },

    {
        path: "/:lang/payitemtype/new",
        name: "payitemtypenew",
        component: PayItemTypeNew,
        meta: {
            requiresAuth: true,
            funcname: "Pay Item Type"
        }
    },

    {
        path: "/:lang/payitemtype/edit/:id",
        name: "payitemtypeedit",
        component: PayItemTypeEdit,
        meta: {
            requiresAuth: true,
            funcname: "Pay Item Type"
        }
    },

    {
        path: "/:lang/payitem/list",
        name: "payitemlist",
        component: PayItemList,
        meta: {
            requiresAuth: true,
            funcname: "Pay Item"
        }
    },

    {
        path: "/:lang/payitem/new",
        name: "payitemnew",
        component: PayItemNew,
        meta: {
            requiresAuth: true,
            funcname: "Pay Item"
        }
    },

    {
        path: "/:lang/payitem/edit/:id",
        name: "payitemedit",
        component: PayItemEdit,
        meta: {
            requiresAuth: true,
            funcname: "Pay Item"
        }
    },

    {
        path: "/:lang/payrate/list",
        name: "payratelist",
        component: PayRateList,
        meta: {
            requiresAuth: true,
            funcname: "Pay Rate"
        }
    },

    {
        path: "/:lang/payrate/new",
        name: "payratenew",
        component: PayRateNew,
        meta: {
            requiresAuth: true,
            funcname: "Pay Rate"
        }
    },

    {
        path: "/:lang/payrate/edit/:id",
        name: "payrateedit",
        component: PayRateEdit,
        meta: {
            requiresAuth: true,
            funcname: "Pay Rate"
        }
    },

    {
        path: "/:lang/payprocess/list",
        name: "payprocesslist",
        component: PayProcessList,
        meta: {
            requiresAuth: true,
            funcname: "Process List Master"
        }
    },

    {
        path: "/:lang/payprocess/new",
        name: "payprocessnew",
        component: PayProcessNew,
        meta: {
            requiresAuth: true,
            funcname: "Process List Master"
        }
    },

    {
        path: "/:lang/payprocess/edit/:id",
        name: "payprocessedit",
        component: PayProcessEdit,
        meta: {
            requiresAuth: true,
            funcname: "Process List Master"
        }
    },

    {
        path: "/:lang/paytype/list",
        name: "paytypelist",
        component: PayTypeList,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Type"
        }
    },

    {
        path: "/:lang/paytype/new",
        name: "paytypenew",
        component: PayTypeNew,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Type"
        }
    },

    {
        path: "/:lang/paytype/edit/:id",
        name: "paytypeedit",
        component: PayTypeEdit,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Type"
        }
    },

    {
        path: "/:lang/payoutctr/list/",
        name: "payoutcontrollist",
        component: PayoutControlList,
        meta: {
            requiresAuth: true,
            funcname: "Payout Control"
        }
    },

    {
        path: "/:lang/payoutctr/new/",
        name: "payoutcontrolnew",
        component: PayoutControlNew,
        meta: {
            requiresAuth: true,
            funcname: "Payout Control"
        }
    },

    {
        path: "/:lang/payoutctr/edit/:id",
        name: "payoutcontroledit",
        component: PayoutControlEdit,
        meta: {
            requiresAuth: true,
            funcname: "Payout Control"
        }
    },

    {
        path: "/:lang/payrollrunprocess",
        name: "payrollrunprocess",
        component: PayrollRunProcess,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Run Process"
        }
    },
    {
        path: "/:lang/payrollprocesslog",
        name: "payrollprocesslog",
        component: PayrollProcessLog,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Run Process"
        }
    },

    {
        path: "/:lang/mm/payrollrunprocess",
        name: "mmpayrollrunprocess",
        component: mmPayrollRunProcess,
        meta: {
            requiresAuth: true,
            funcname: "MM Payroll Run Process"
        }
    },
    {
        path: "/:lang/mm/mmpayrollprocesslog",
        name: "mmpayrollprocesslog",
        component: mmPayrollProcessLog,
        meta: {
            requiresAuth: true,
            funcname: "MM Payroll Run Process"
        }
    },

    {
        path: "/:lang/leavepayrollposting",
        name: "leavepayrollposting",
        component: LeavePayrollPosting,
        meta: {
            requiresAuth: true,
            funcname: "Leave Payroll Posting"
        }
    },
    {
        path: "/:lang/leavepayrolllog",
        name: "leavepayrolllog",
        component: LeavePayrollLog,
        meta: {
            requiresAuth: true,
            funcname: "Leave Payroll Posting"
        }
    },

    
    {
        path: "/:lang/claimpayrollposting",
        name: "claimpayrollposting",
        component: ClaimPayrollPosting,
        meta: {
            requiresAuth: true,
            funcname: "Claim Payroll Posting"
        }
    },
    {
        path: "/:lang/claimpayrolllog",
        name: "claimpayrolllog",
        component: ClaimPayrollLog,
        meta: {
            requiresAuth: true,
            funcname: "Claim Payroll Posting"
        }
    },

    /* nawfal routes */

    // vendor paths
    {
        path: '/:lang/vendor/list',
        name: 'vendorlist',
        component: VendorList,
        meta: {
            requiresAuth: true,
            funcname: "Vendor Setup"
        }
    },

    {
        path: '/:lang/vendor/new',
        name: 'vendornew',
        component: VendorNew,
        meta: {
            requiresAuth: true,
            funcname: "Vendor Setup"
        }
    },

    {
        path: '/:lang/vendor/edit/:id',
        name: 'vendoredit',
        component: VendorEdit,
        meta: {
            requiresAuth: true,
            funcname: "Vendor Setup"
        }
    },

    // reference number setup paths
    {
        path: '/:lang/refnumsetup/list',
        name: 'refnumlist',
        component: RefNumList,
        meta: {
            requiresAuth: true,
            funcname: "Company Reference Number Setup"
        }
    },

    {
        path: '/:lang/refnumsetup/new',
        name: 'refnumnew',
        component: RefNumNew,
        meta: {
            requiresAuth: true,
            funcname: "Company Reference Number Setup"
        }
    },

    {
        path: '/:lang/refnumsetup/edit/:id',
        name: 'refnumedit',
        component: RefNumEdit,
        meta: {
            requiresAuth: true,
            funcname: "Company Reference Number Setup"
        }
    },

    // personnel - contact details paths

    {
        path: '/:lang/contactdetails/list',
        name: 'contactdetailslist',
        component: ContactDetailsList,
        meta: {
            requiresAuth: true,
            funcname: "Contact Details"
        }
    },

    {
        path: '/:lang/contactdetails/new',
        name: 'contactdetailsnew',
        component: ContactDetailsNew,
        meta: {
            requiresAuth: true,
            funcname: "Contact Details"
        }
    },

    {
        path: '/:lang/contactdetails/edit',
        name: 'contactdetailsedit',
        component: ContactDetailsEdit,
        meta: {
            requiresAuth: true,
            funcname: "Contact Details"
        }
    },

    //removed by SJ 2020-08-11. Replaced with new name. taxable exemption group setup paths
    // {
    //     path: '/:lang/taxexempgroupsetup/edit/:id',
    //     name: 'taxexempgroupsetupedit',
    //     component: TaxExempGroupSetupEdit,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },

    // {
    //     path: '/:lang/taxexempgroupsetup/new',
    //     name: 'taxexemgroupsetupnew',
    //     component: TaxExemGroupSetupNew,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },

    // {
    //     path: '/:lang/taxexempgroupsetup/list',
    //     name: 'taxexempgroupsetuplist',
    //     component: TaxExempGroupSetupList,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },

    // removed by SJ 2020-08-11. replaced with new name. payroll - taxable rebate setup paths
    // {
    //     path: '/:lang/taxablerebatesetup/list',
    //     name: 'taxablerebatesetuplist',
    //     component: TaxableRebateSetupList,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },

    // {
    //     path: '/:lang/taxablerebatesetup/new',
    //     name: 'taxablerebatesetupnew',
    //     component: TaxableRebateSetupNew,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },

    // {
    //     path: '/:lang/taxablerebatesetup/edit/:id',
    //     name: 'taxablerebatesetupedit',
    //     component: TaxableRebateSetupEdit,
    //     meta: {
    //         requiresAuth: true
    //     }
    // },

    //payroll - taxable rebate group setup paths
    {
        path: '/:lang/taxablerebategroup/edit/:id',
        name: 'taxablerebategroupsetupedit',
        component: TaxableRebateGroupSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Rebate Group Setup"
        }
    },

    {
        path: '/:lang/taxablerebategroup/new',
        name: 'taxablerebategroupsetupnew',
        component: TaxableRebateGroupSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Rebate Group Setup"
        }
    },

    {
        path: '/:lang/taxablerebategroup/list',
        name: 'taxablerebategroupsetuplist',
        component: TaxableRebateGroupSetupList,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Rebate Group Setup"
        }
    },

    // payroll - taxable rebate setup paths
    {
        path: '/:lang/taxablerebatesetup/list',
        name: 'taxablerebatesetuplist',
        component: TaxableRebateSetupList,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Rebate Setup"
        }
    },

    {
        path: '/:lang/taxablerebatesetup/new',
        name: 'taxablerebatesetupnew',
        component: TaxableRebateSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Rebate Setup"
        }
    },

    {
        path: '/:lang/taxablerebatesetup/edit/:id',
        name: 'taxablerebatesetupedit',
        component: TaxableRebateSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Rebate Setup"
        }
    },


    //epf setup paths setup

    {
        path: '/:lang/epfsetup/list',
        name: 'epfsetuplist',
        component: EPFSetupList,
        meta: {
            requiresAuth: true,
            funcname: "EPF Setup"
        }
    },

    {
        path: '/:lang/epfsetup/new',
        name: 'epfsetupnew',
        component: EPFSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "EPF Setup"
        }
    },

    {
        path: '/:lang/epfsetup/edit/:id',
        name: 'epfsetupedit',
        component: EPFSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "EPF Setup"
        }
    },

    //group assignment setup paths
    {
        path: '/:lang/groupassignment/list',
        name: 'groupassignmentlist',
        component: GroupAssignmentList,
        meta: {
            requiresAuth: true,
            funcname: "Group Assignment"
        }
    },

    {
        path: '/:lang/groupassignment/new',
        name: 'groupassignmentnew',
        component: GroupAssignmentNew,
        meta: {
            requiresAuth: true,
            funcname: "Group Assignment"
        }
    },

    {
        path: '/:lang/groupassignment/edit/:id',
        name: 'groupassignmentedit',
        component: GroupAssignmentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Group Assignment"
        }
    },

    // person statutory number info paths
    {
        path: '/:lang/personstatutoryinfo/list',
        name: 'personstatutoryinfolist',
        component: PersonStatutoryInfoList,
        meta: {
            requiresAuth: true,
            funcname: "Person Statutory Number Info Setup"
        }
    },

    {
        path: '/:lang/personstatutoryinfo/new',
        name: 'personstatutoryinfonew',
        component: PersonStatutoryInfoNew,
        meta: {
            requiresAuth: true,
            funcname: "Person Statutory Number Info Setup"
        }
    },

    {
        path: '/:lang/personstatutoryinfo/edit/:id',
        name: 'personstatutoryinfoedit',
        component: PersonStatutoryInfoEdit,
        meta: {
            requiresAuth: true,
            funcname: "Person Statutory Number Info Setup"
        }
    },

    //tp1 routes 
    {
        path: '/:lang/tp1/list',
        name: 'tp1list',
        component: Tp1List,
        meta: {
            requiresAuth: true,
            funcname: "TP1"
        }
    },

    {
        path: '/:lang/tp1/new',
        name: 'tp1new',
        component: Tp1New,
        meta: {
            requiresAuth: true,
            funcname: "TP1"
        }
    },

    {
        path: '/:lang/tp1/edit/:id',
        name: 'tp1edit',
        component: Tp1Edit,
        meta: {
            requiresAuth: true,
            funcname: "TP1"
        }
    },

    //headcount analysis routes
    {
        path: '/:lang/headcountanalysis/:asatdate',
        name: 'headcountanalysis',
        component: HeadcountAnalysis,
        meta: {
            requiresAuth: true,
            funcname: "Headcount Analysis"
        }
    },

    {
        path: '/:lang/parameterheadcount',
        name: 'parameterheadcount',
        component: ParameterHeadcount,
        meta: {
            requiresAuth: true,
            funcname: "Headcount Analysis"
        }
    },

    //payslip routes
    {
        path: '/:lang/parameterpayslip',
        name: 'parameterpayslip',
        component: ParameterPayslip,
        meta: {
            requiresAuth: true,
            funcname: "Payslip"
        }
    },

    {
        path: '/:lang/payrollsummarylisting',
        name: 'payrollsummarylisting',
        component: PayrollSummaryListing,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Summary Listing"
        }
    },
    {
        path: '/:lang/payrolldetaillisting',
        name: 'payrolldetaillisting',
        component: PayrollDetailListing,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Detail Listing"
        }
    },
    {
        path: '/:lang/payrollsummaryanalysis',
        name: 'payrollsummaryanalysis',
        component: PayrollSummaryAnalysis,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Summary Analysis"
        }
    },
    {
        path: '/:lang/payslip/:formData',
        name: 'payslip',
        component: Payslip,
        meta: {
            requiresAuth: true,
            funcname: "Payslip"
        }
    },

    //payroll 12monhts summary routes
    {
        path: '/:lang/staff12monthpayrollsummary',
        name: 'staff12monthpayrollsummary',
        component: Staff12MonthPayrollSummary,
        meta: {
            requiresAuth: true,
            funcname: "Staff 12 Month Payroll Summary"
        }
    },

    // {
    //     path: '/:lang/staff12monthpayrollsummary/:formData',
    //     name: 'staff12monthpayrollsummary',
    //     component: Staff12MonthPayrollSummary,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "Staff 12 Month Payroll Summary"
    //     }
    // },

    /*payroll reconciliation routes*/
    {
        path: '/:lang/parameterpayrollreconciliation',
        name: 'parameterpayrollreconciliation',
        component: ParameterPayrollReconciliation,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Reconciliation"
        }
    },

    {
        path: '/:lang/payrollreconciliation/:formData/:param',
        name: 'payrollreconciliation',
        component: PayrollReconciliation,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Reconciliation"
        }
    },

    {
        path: '/:lang/viewdetails/:payitemid/:formData/:param',
        name: 'viewdetails',
        component: ViewDetails,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Reconciliation"
        }
    },

    {
        path: '/:lang/statutoryreportsetuplist',
        name: 'statutoryreportsetuplist',
        component: StatutoryReportSetupList,
        meta: {
            requiresAuth: true,
            funcname: "Statutory Report Setup"
        }
    },

    {
        path: '/:lang/statutoryreportsetupnew',
        name: 'statutoryreportsetupnew',
        component: StatutoryReportSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Statutory Report Setup"
        }
    },

    {
        path: '/:lang/statutoryreportsetupedit',
        name: 'statutoryreportsetupedit',
        component: StatutoryReportSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Statutory Report Setup"
        }
    },

    {
        path: '/:lang/monthlyepfsubmissionrecordlist',
        name: 'monthlyepfsubmissionrecordlist',
        component: MonthlyEPFSubmissionRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EPF Submission Record"
        }
    },

    {
        path: '/:lang/monthlyepfsubmissionrecordnew',
        name: 'monthlyepfsubmissionrecordnew',
        component: MonthlyEPFSubmissionRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EPF Submission Record"
        }
    },

    {
        path: '/:lang/monthlyepfsubmissionrecordedit/:id',
        name: 'monthlyepfsubmissionrecordedit',
        component: MonthlyEPFSubmissionRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EPF Submission Record"
        }
    },

    
    {
        path: '/:lang/monthlyptptnsubmissionrecordlist',
        name: 'monthlyptptnsubmissionrecordlist',
        component: MonthlyPTPTNSubmissionRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Monthly PTPTN Submission Record"
        }
    },

    {
        path: '/:lang/monthlyptptnsubmissionrecordnew',
        name: 'monthlyptptnsubmissionrecordnew',
        component: MonthlyPTPTNSubmissionRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Monthly PTPTN Submission Record"
        }
    },

    {
        path: '/:lang/monthlyptptnsubmissionrecordedit/:id',
        name: 'monthlyptptnsubmissionrecordedit',
        component: MonthlyPTPTNSubmissionRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Monthly PTPTN Submission Record"
        }
    },

    {

        path: "/:lang/ptptnexport",
        name: "ptptnexport",
        component: PTPTNEexport,
        meta: {
            requiresAuth: true,
            funcname: "PTPTN Export"
        }
    },

    {
        path: '/:lang/monthlytaxsubmissionrecordlist',
        name: 'monthlytaxsubmissionrecordlist',
        component: MonthlyTaxSubmissionRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Tax Submission Record"
        }
    },

    {
        path: '/:lang/monthlytaxsubmissionrecordnew',
        name: 'monthlytaxsubmissionrecordnew',
        component: MonthlyTaxSubmissionRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Tax Submission Record"
        }
    },

    {
        path: '/:lang/monthlytaxsubmissionrecordedit/:id',
        name: 'monthlytaxsubmissionrecordedit',
        component: MonthlyTaxSubmissionRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Tax Submission Record"
        }
    },

    /* end of nawfal routes */

    /* faisal routes*/
    //payroll dashboard routes
    {
        path: "/:lang/payrolldashboard/filter",
        name: "payrolldashboardfilter",
        component: PayrollDashboardFilter,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Dashboard"
        }
    },

    {
        path: "/:lang/payrolldashboard/summary",
        name: "payrolldashboardsummary",
        component: PayrollDashboardSummary,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Dashboard"
        }
    },

    {
        path: "/:lang/payrolldashboard/detail",
        name: "payrolldashboarddetail",
        component: PayrollDashboardDetail,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Dashboard"
        }
    },

    {
        path: "/:lang/payrolldashboard/detail2",
        name: "payrolldashboarddetail2",
        component: PayrollDashboardDetail2,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Dashboard"
        }
    },

    //payee input routes
    {
        path: "/:lang/payeeinput/list",
        name: "payeeinputlist",
        component: PayeeInputList,
        meta: {
            requiresAuth: true,
            funcname: "Payee Input"
        }
    },

    {
        path: "/:lang/payeeinput/edit/:id",
        name: "payeeinputedit",
        component: PayeeInputEdit,
        meta: {
            requiresAuth: true,
            funcname: "Payee Input"
        }
    },

    {
        path: "/:lang/payeeinput/new",
        name: "payeeinputnew",
        component: PayeeInputNew,
        meta: {
            requiresAuth: true,
            funcname: "Payee Input"
        }
    },

    //payroll preview routes
    {
        path: "/:lang/payrollpreview/filter",
        name: "payrollpreviewfilter",
        component: PayrollPreviewFilter,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Preview"
        }
    },

    {
        path: "/:lang/payrollpreview/summary/:param/:id",
        name: "payrollpreviewsummary",
        component: PayrollPreviewSummary,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Preview"
        }
    },

    {
        path: "/:lang/payrollpreview/detail/:param/:payoutid/:staffid/:id",
        name: "payrollpreviewdetail",
        component: PayrollPreviewDetail,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Preview"
        }
    },


    //payroll exception routes
    {
        path: "/:lang/payrollexception/filter",
        name: "payrollexceptionfilter",
        component: PayrollExceptionFilter,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Exception"
        }
    },

    {
        path: "/:lang/payrollexception/summary/:id",
        name: "payrollexceptionsummary",
        component: PayrollExceptionSummary,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Exception"
        }
    },

    {
        path: "/:lang/payrollexception/detail/:staffid/:id",
        name: "payrollexceptiondetail",
        component: PayrollExceptionDetail,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Exception"
        }
    },

    //leave setup routes
    {
        path: "/:lang/leavesetup/list",
        name: "leavesetuplist",
        component: LeaveSetupList,
        meta: {
            requiresAuth: true,
            funcname: "Leave Setup"
        }
    },

    {
        path: "/:lang/leavesetup/edit/:id",
        name: "leavesetupedit",
        component: LeaveSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Leave Setup"
        }
    },

    {
        path: "/:lang/leavesetup/new",
        name: "leavesetupnew",
        component: LeaveSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Leave Setup"
        }
    },

    //leave entitlement setup routes
    {
        path: "/:lang/leaveentitlementpolicy/list",
        name: "leaveentitlementpolicylist",
        component: LeaveEntitlementList,
        meta: {
            requiresAuth: true,
            funcname: "Leave Entitlement"
        }
    },

    {
        path: "/:lang/leaveentitlementpolicy/edit/:id",
        name: "leaveentitlementpolicyedit",
        component: LeaveEntitlementEdit,
        meta: {
            requiresAuth: true,
            funcname: "Leave Entitlement"
        }
    },

    {
        path: "/:lang/leaveentitlementpolicy/new",
        name: "leaveentitlementpolicynew",
        component: LeaveEntitlementNew,
        meta: {
            requiresAuth: true,
            funcname: "Leave Entitlement"
        }
    },

    //leave to payroll posting setup routes
    {
        path: "/:lang/leavetopayrollpostingsetup/list",
        name: "leavetopayrollpostingsetuplist",
        component: LeaveToPayrollPostingSetupList,
        meta: {
            requiresAuth: true,
            funcname: "Leave To Payroll Posting"
        }
    },

    {
        path: "/:lang/leavetopayrollpostingsetup/edit/:id",
        name: "leavetopayrollpostingsetupedit",
        component: LeaveToPayrollPostingSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Leave To Payroll Posting"
        }
    },

    {
        path: "/:lang/leavetopayrollpostingsetup/new",
        name: "leavetopayrollpostingsetupnew",
        component: LeaveToPayrollPostingSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Leave To Payroll Posting"
        }
    },

    //leave C/F policy setup routes

    {
        path: "/:lang/carryforwardpolicy/",
        name: "leavecarryforwardpolicyform",
        component: LeaveCarryForwardPolicyForm,
        meta: {
            requiresAuth: true,
            funcname: "Leave Carry Forward Policy"
        }
    },

    //rest day pattern setup routes
    {
        path: "/:lang/holidayassignment/list",
        name: "holidayassignmentlist",
        component: HolidayAssignmentList,
        meta: {
            requiresAuth: true,
            funcname: "Holiday Assignment"
        }
    },

    {
        path: "/:lang/holidayassignment/edit/:id",
        name: "holidayassignmentedit",
        component: HolidayAssignmentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Holiday Assignment"
        }
    },

    {
        path: "/:lang/holidayassignment/new",
        name: "holidayassignmentnew",
        component: HolidayAssignmentNew,
        meta: {
            requiresAuth: true,
            funcname: "Holiday Assignment"
        }
    },

    //clinic routes
    {
        path: "/:lang/clinic/list",
        name: "cliniclist",
        component: ClinicList,
        meta: {
            requiresAuth: true,
            funcname: "Clinic"
        }
    },

    {
        path: "/:lang/clinic/edit/:id",
        name: "clinicedit",
        component: ClinicEdit,
        meta: {
            requiresAuth: true,
            funcname: "Clinic"
        }
    },

    {
        path: "/:lang/clinic/new",
        name: "clinicnew",
        component: ClinicNew,
        meta: {
            requiresAuth: true,
            funcname: "Clinic"
        }
    },

    //rest day pattern setup routes
    {
        path: "/:lang/restday/list",
        name: "restdaylist",
        component: RestDayList,
        meta: {
            requiresAuth: true,
            funcname: "Rest Day Pattern"
        }
    },

    {
        path: "/:lang/restday/edit/:id",
        name: "restdayedit",
        component: RestDayEdit,
        meta: {
            requiresAuth: true,
            funcname: "Rest Day Pattern"
        }
    },

    {
        path: "/:lang/restday/new",
        name: "restdaynew",
        component: RestDayNew,
        meta: {
            requiresAuth: true,
            funcname: "Rest Day Pattern"
        }
    },

    //rest day schedule instruction routes
    {
        path: "/:lang/rdayscheduleinstruction/list",
        name: "rdayscheduleinstructionlist",
        component: RDayScheduleInstructionList,
        meta: {
            requiresAuth: true,
            funcname: "Rest Day Schedule Instruction"
        }
    },

    {
        path: "/:lang/rdayscheduleinstruction/edit/:id",
        name: "rdayscheduleinstructionedit",
        component: RDayScheduleInstructionEdit,
        meta: {
            requiresAuth: true,
            funcname: "Rest Day Schedule Instruction"
        }
    },

    {
        path: "/:lang/rdayscheduleinstruction/new",
        name: "rdayscheduleinstructionnew",
        component: RDayScheduleInstructionNew,
        meta: {
            requiresAuth: true,
            funcname: "Rest Day Schedule Instruction"
        }
    },

    //leave record routes
    {
        path: "/:lang/leaverecord/list",
        name: "leaverecordlist",
        component: LeaveRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Leave Record"
        }
    },

    {
        path: "/:lang/leaverecord/edit/:id",
        name: "leaverecordedit",
        component: LeaveRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Leave Record"
        }
    },

    {
        path: "/:lang/leaverecord/new",
        name: "leaverecordnew",
        component: LeaveRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Leave Record"
        }
    },


    {
        path: "/:lang/leavebalanceadj/list",
        name: "leavebalanceadjlist",
        component: LeaveBalanceAdjList,
        meta: {
            requiresAuth: true,
            funcname: "Leave Balance Adjustment"
        }
    },

    {
        path: "/:lang/leavebalanceadj/new",
        name: "leavebalanceadjnew",
        component: LeaveBalanceAdjNew,
        meta: {
            requiresAuth: true,
            funcname: "Leave Balance Adjustment"
        }
    },

    {
        path: "/:lang/leavebalanceadj/edit/:id",
        name: "leavebalanceadjedit",
        component: LeaveBalanceAdjEdit,
        meta: {
            requiresAuth: true,
            funcname: "Leave Balance Adjustment"
        }
    },
    {
        path: "/:lang/leavebalance/staff",
        name: "leavebalancebystaff",
        component: LeaveBalanceByStaff,
        meta: {
            requiresAuth: true,
            funcname: "Leave Balance By Staff"
        }
    },

    {
        path: '/:lang/leavebalancesummary/parameter',
        name: 'leavebalancesummary',
        component: LeaveBalanceSummary,
        meta: {
            requiresAuth: true,
            funcname: "Leave Balance Summary"
        }
    },

    {
        path: '/:lang/leavebalancesummary/details/:formData',
        name: 'detailsleavebalance',
        component: DetailsLeaveBalance,
        meta: {
            requiresAuth: true,
            funcname: "Leave Balance Summary"
        }
    },

    {
        path: "/:lang/payrollfinalization/list",
        name: "payrollfinalizationlist",
        component: PayrollFinalizationList,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Finalization"
        }
    },

    {
        path: "/:lang/payrollfinalization/edit/:id",
        name: "payrollfinalizationedit",
        component: PayrollFinalizationEdit,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Finalization"
        }
    },

    {
        path: "/:lang/payrollfinalization/new",
        name: "payrollfinalizationnew",
        component: PayrollFinalizationNew,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Finalization"
        }
    },


    /* end of faisal routes*/


    /* aidil routes */

    {
        path: "/:lang/bankaccount/list",
        name: "bankaccountlist",
        component: BankAccountList,
        meta: {
            requiresAuth: true,
            funcname: "Company Bank Account"
        }
    },

    {
        path: "/:lang/bankaccount/new",
        name: "bankaccountnew",
        component: BankAccountNew,
        meta: {
            requiresAuth: true,
            funcname: "Company Bank Account"
        }

    },

    {

        path: "/:lang/bankaccount/edit/:id",
        name: "bankaccountedit",
        component: BankAccountEdit,
        meta: {
            requiresAuth: true,
            funcname: "Company Bank Account"
        }
    },

    {
        path: "/:lang/staffbankaccount/list",
        name: "staffbankaccountlist",
        component: StaffBankAccountList,
        meta: {
            requiresAuth: true,
            funcname: "Staff Bank Account"
        }
    },

    {
        path: "/:lang/staffbankaccount/new",
        name: "staffbankaccountnew",
        component: StaffBankAccountNew,
        meta: {
            requiresAuth: true,
            funcname: "Staff Bank Account"
        }
    },

    {
        path: "/:lang/staffbankaccount/edit/:id",
        name: "staffbankaccountedit",
        component: StaffBankAccountEdit,
        meta: {
            requiresAuth: true,
            funcname: "Staff Bank Account"
        }
    },

    {
        path: "/:lang/exemption/list",
        name: "exemptionlist",
        component: ExemptionList,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Exemption Setup"
        }
    },

    {
        path: "/:lang/exemption/edit/:id",
        name: "exemptionedit",
        component: ExemptionEdit,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Exemption Setup"
        }
    },

    {
        path: "/:lang/exemption/new",
        name: "exemptionnew",
        component: ExemptionNew,
        meta: {
            requiresAuth: true,
            funcname: "Taxable Exemption Setup"
        }
    },

    {
        path: "/:lang/family/list",
        name: "familylist",
        component: FamilyList,
        meta: {
            requiresAuth: true,
            funcname: "Family Details"
        }
    },

    {
        path: "/:lang/family/edit/:id",
        name: "familyedit",
        component: FamilyEdit,
        meta: {
            requiresAuth: true,
            funcname: "Family Details"
        }
    },

    {
        path: "/:lang/family/new",
        name: "familynew",
        component: FamilyNew,
        meta: {
            requiresAuth: true,
            funcname: "Family Details"
        }
    },

    {
        path: "/:lang/exchangerateset/list",
        name: "exchangeratesetlist",
        component: ExchangeRateSetList,
        meta: {
            requiresAuth: true,
            funcname: "Exchange Rate Set"
        }
    },

    {
        path: "/:lang/exchangerateset/new",
        name: "exchangeratesetnew",
        component: ExchangeRateSetNew,
        meta: {
            requiresAuth: true,
            funcname: "Exchange Rate Set"
        }
    },

    {
        path: "/:lang/exchangerateset/edit/:id",
        name: "exchangeratesetedit",
        component: ExchangeRateSetEdit,
        meta: {
            requiresAuth: true,
            funcname: "Exchange Rate Set"
        }
    },

    {
        path: "/:lang/tp3/list",
        name: "tp3list",
        component: tp3List,
        meta: {
            requiresAuth: true,
            funcname: "Person PCB B/F Information"
        }
    },

    {
        path: "/:lang/tp3/edit/:id",
        name: "tp3edit",
        component: tp3Edit,
        meta: {
            requiresAuth: true,
            funcname: "Person PCB B/F Information"
        }
    },

    {
        path: "/:lang/tp3/new",
        name: "tp3new",
        component: tp3New,
        meta: {
            requiresAuth: true,
            funcname: "Person PCB B/F Information"
        }
    },

    {
        path: "/:lang/group/list",
        name: "grouplist",
        component: GroupList,
        meta: {
            requiresAuth: true,
            funcname: "Group"
        }
    },

    {
        path: "/:lang/group/edit/:id",
        name: "groupedit",
        component: GroupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Group"
        }
    },

    {
        path: "/:lang/group/new",
        name: "groupnew",
        component: GroupNew,
        meta: {
            requiresAuth: true,
            funcname: "Group"
        }
    },

    {
        path: "/:lang/valvalue/list",
        name: "valvaluelist",
        component: ValvalueList,
        meta: {
            requiresAuth: true,
            funcname: "Group Valid Values"
        }
    },

    {
        path: "/:lang/valvalue/edit/:id",
        name: "valvalueedit",
        component: ValvalueEdit,
        meta: {
            requiresAuth: true,
            funcname: "Group Valid Values"
        }
    },

    {
        path: "/:lang/valvalue/new",
        name: "valvaluenew",
        component: ValvalueNew,
        meta: {
            requiresAuth: true,
            funcname: "Group Valid Values"
        }
    },

    {
        path: "/:lang/socso/list",
        name: "socsolist",
        component: SocsoList,
        meta: {
            requiresAuth: true,
            funcname: "SOCSO Setup"
        }
    },

    {
        path: "/:lang/socso/edit/:id",
        name: "socsoedit",
        component: SocsoEdit,
        meta: {
            requiresAuth: true,
            funcname: "SOCSO Setup"
        }
    },

    {
        path: "/:lang/socso/new",
        name: "socsonew",
        component: SocsoNew,
        meta: {
            requiresAuth: true,
            funcname: "SOCSO Setup"
        }
    },

    {
        path: "/:lang/baitulmal/list",
        name: "baitulmallist",
        component: BaitulmalList,
        meta: {
            requiresAuth: true,
            funcname: "Baitulmal Setup"
        }
    },

    {
        path: "/:lang/baitulmale/edit/:id",
        name: "baitulmaledit",
        component: BaitulmalEdit,
        meta: {
            requiresAuth: true,
            funcname: "Baitulmal Setup"
        }
    },

    {
        path: "/:lang/baitulmal/new",
        name: "baitulmalnew",
        component: BaitulmalNew,
        meta: {
            requiresAuth: true,
            funcname: "Baitulmal Setup"
        }
    },

    {
        path: "/:lang/eis/list",
        name: "eislist",
        component: eisList,
        meta: {
            requiresAuth: true,
            funcname: "EIS Setup"
        }
    },

    {
        path: "/:lang/eis/edit/:id",
        name: "eisedit",
        component: eisEdit,
        meta: {
            requiresAuth: true,
            funcname: "EIS Setup"
        }
    },

    {
        path: "/:lang/eis/new",
        name: "eisnew",
        component: eisNew,
        meta: {
            requiresAuth: true,
            funcname: "EIS Setup"
        }
    },

    {
        path: "/:lang/epfve/list",
        name: "epfvelist",
        component: EpfveList,
        meta: {
            requiresAuth: true,
            funcname: "EPF VE Setup"
        }
    },

    {
        path: "/:lang/epfve/edit/:id",
        name: "epfveedit",
        component: EpfveEdit,
        meta: {
            requiresAuth: true,
            funcname: "EPF VE Setup"
        }
    },

    {
        path: "/:lang/epfve/new",
        name: "epfvenew",
        component: EpfveNew,
        meta: {
            requiresAuth: true,
            funcname: "EPF VE Setup"
        }
    },

    {
        path: "/:lang/taxset/list",
        name: "taxsetlist",
        component: taxsetList,
        meta: {
            requiresAuth: true,
            funcname: "Tax Setup"
        }
    },

    {
        path: "/:lang/taxset/edit/:id",
        name: "taxsetedit",
        component: taxsetEdit,
        meta: {
            requiresAuth: true,
            funcname: "Tax Setup"
        }
    },

    {
        path: "/:lang/taxset/new",
        name: "taxsetnew",
        component: taxsetNew,
        meta: {
            requiresAuth: true,
            funcname: "Tax Setup"
        }
    },

    {
        path: "/:lang/psnpayroll/list",
        name: "psnpayrolllist",
        component: psnpayrollList,
        meta: {
            requiresAuth: true,
            funcname: "Person Payroll Instruction Setup"
        }
    },

    {
        path: "/:lang/psnpayroll/edit/:id",
        name: "psnpayrolledit",
        component: psnpayrollEdit,
        meta: {
            requiresAuth: true,
            funcname: "Person Payroll Instruction Setup"
        }
    },

    {
        path: "/:lang/psnpayroll/new",
        name: "psnpayrollnew",
        component: psnpayrollNew,
        meta: {
            requiresAuth: true,
            funcname: "Person Payroll Instruction Setup"
        }
    },

    {
        path: "/:lang/cp22/list",
        name: "cp22list",
        component: cp22List,
        meta: {
            requiresAuth: true,
            funcname: "CP22 Record"
        }
    },

    {
        path: "/:lang/cp22/edit/:id",
        name: "cp22edit",
        component: cp22Edit,
        meta: {
            requiresAuth: true,
            funcname: "CP22 Record"
        }
    },

    {
        path: "/:lang/cp22/new",
        name: "cp22new",
        component: cp22New,
        meta: {
            requiresAuth: true,
            funcname: "CP22 Record"
        }
    },

    {
        path: '/:lang/cp22List/parameter',
        name: 'cp22listingparameter',
        component: cp22ListingParameter,
        meta: {
            requiresAuth: true,
            funcname: "CP22 Report"
        }
    },


    {
        path: "/:lang/eadtl/list",
        name: "eadtllist",
        component: eadtlList,
        meta: {
            requiresAuth: true,
            funcname: "EA Form Add-on Details"
        }
    },

    {
        path: "/:lang/eadtl/edit/:id",
        name: "eadtledit",
        component: eadtlEdit,
        meta: {
            requiresAuth: true,
            funcname: "EA Form Add-on Details"
        }
    },

    {
        path: "/:lang/eadtl/new",
        name: "eadtlnew",
        component: eadtlNew,
        meta: {
            requiresAuth: true,
            funcname: "EA Form Add-on Details"
        }
    },
    {
        path: "/:lang/cntryss/list",
        name: "cntrysslist",
        component: cntryssList,
        meta: {
            requiresAuth: true,
            funcname: "Statutory Release Control"
        }
    },

    {
        path: "/:lang/cntryss/edit/:id",
        name: "cntryssedit",
        component: cntryssEdit,
        meta: {
            requiresAuth: true,
            funcname: "Statutory Release Control"
        }
    },

    {
        path: "/:lang/cntryss/new",
        name: "cntryssnew",
        component: cntryssNew,
        meta: {
            requiresAuth: true,
            funcname: "Statutory Release Control"
        }
    },
    {
        path: "/:lang/staffulldetails/summary",
        name: "staffulldnew",
        component: staffulldNew,
        meta: {
            requiresAuth: true,
            funcname: "Person & Staff Details Listing"
        }
    },

    {
        path: '/:lang/nonbank/parameter',
        name: 'parameternonbank',
        component: parameterNonbank,
        meta: {
            requiresAuth: true,
            funcname: ""
        }
    },

    {
        path: '/:lang/nonbank/details/:formData',
        name: 'detailsnonbank',
        component: detailsNonbank,
        meta: {
            requiresAuth: true,
            funcname: ""
        }
    },

    {
        path: '/:lang/bankpayment/parameter',
        name: 'parameterbank',
        component: parameterBank,
        meta: {
            requiresAuth: true,
            funcname: "Bank Payment Listing"
        }
    },

    {
        path: '/:lang/bankpayment/details/:formData',
        name: 'detailsbank',
        component: detailsBank,
        meta: {
            requiresAuth: true,
            funcname: "Bank Payment Listing"
        }
    },

    {
        path: '/:lang/payrollsummarybycompany/parameter',
        name: 'parametercompanysummary',
        component: parameterPayrollSummaryCompany,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Summary by Company"
        }
    },

    {
        path: '/:lang/payrollsummarybycompany/:formData',
        name: 'detailscompanysummary',
        component: detailsPayrollSummaryCompany,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Summary by Company"
        }
    },

    {
        path: "/:lang/personpayrolldirectassignment/list",
        name: "personpayrolldirectassignmentlist",
        component: PersonPayrollDirectAssignmentList,
        meta: {
            requiresAuth: true,
            funcname: "Person Payroll Direct Assignment"
        }
    },

    {
        path: "/:lang/personpayrolldirectassignment/edit/:id",
        name: "personpayrolldirectassignmentedit",
        component: PersonPayrollDirectAssignmentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Person Payroll Direct Assignment"
        }
    },

    {
        path: "/:lang/personpayrolldirectassignment/new",
        name: "personpayrolldirectassignmentnew",
        component: PersonPayrollDirectAssignmentNew,
        meta: {
            requiresAuth: true,
            funcname: "Person Payroll Direct Assignment"
        }
    },

    {
        path: "/:lang/grouppayment/list",
        name: "grouppaymentlist",
        component: GroupPaymentList,
        meta: {
            requiresAuth: true,
            funcname: "Group Payment"
        }
    },

    {
        path: "/:lang/grouppayment/edit/:id",
        name: "grouppaymentedit",
        component: GroupPaymentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Group Payment"
        }
    },

    {
        path: "/:lang/grouppayment/new",
        name: "grouppaymentnew",
        component: GroupPaymentNew,
        meta: {
            requiresAuth: true,
            funcname: "Group Payment"
        }
    },

    {
        path: '/:lang/cashdenomination/parameter',
        name: 'parametercashdenomination',
        component: parameterCashdenomination,
        meta: {
            requiresAuth: true,
            funcname: "Cash Denomination Listing"
        }
    },

    {
        path: '/:lang/cashdenomination/:formData',
        name: 'detailscashdenomination',
        component: detailsCashdenomination,
        meta: {
            requiresAuth: true,
            funcname: "Cash Denomination Listing"
        }
    },


    {
        path: "/:lang/socsosub/list",
        name: "socsosublist",
        component: socsosubList,
        meta: {
            requiresAuth: true,
            funcname: "monthly SOCSO Submission Record"
        }
    },

    {
        path: "/:lang/socsosub/edit/:id",
        name: "socsosubedit",
        component: socsosubEdit,
        meta: {
            requiresAuth: true,
            funcname: "monthly SOCSO Submission Record"
        }
    },

    {
        path: "/:lang/socsosub/new",
        name: "socsosubnew",
        component: socsosubNew,
        meta: {
            requiresAuth: true,
            funcname: "monthly SOCSO Submission Record"
        }
    },

    {
        path: "/:lang/salaryexportrecord/list",
        name: "salaryexportrecordlist",
        component: SalaryExportRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Salary Export Record"
        }
    },

    {
        path: "/:lang/salaryexportrecord/new",
        name: "salaryexportrecordnew",
        component: SalaryExportRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Salary Export Record"
        }
    },

    {
        path: "/:lang/salaryexportrecord/edit/:id",
        name: "salaryexportrecordedit",
        component: SalaryExportRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Salary Export Record"
        }
    },

    {
        path: "/:lang/salaryexport/:param",
        name: "salaryexport",
        component: SalaryExport,
        meta: {
            requiresAuth: true,
            funcname: "Salary Export"
        }
    },

    {
        path: "/:lang/salaryexport/param/:id/:item/:parameter",
        name: "salaryexportparam",
        component: SalaryExportParam,
        meta: {
            requiresAuth: true,
            funcname: "Salary Export"
        }
    },

    {
        path: "/:lang/salaryexport/log",
        name: "salaryexportlog",
        component: SalaryExportLog,
        meta: {
            requiresAuth: true,
            funcname: "Salary Export"
        }
    },

    {
        path: "/:lang/salaryexport/parameter",
        name: "salaryexportparameter",
        component: SalaryExportParameter,
        meta: {
            requiresAuth: true,
            funcname: "Salary Export"
        }
    },

    {
        path: "/:lang/eissub/list",
        name: "eissublist",
        component: eissubList,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EIS Submission Record"
        }
    },

    {
        path: "/:lang/eissub/edit/:id",
        name: "eissubedit",
        component: eissubEdit,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EIS Submission Record"
        }
    },

    {
        path: "/:lang/eissub/new",
        name: "eissubnew",
        component: eissubNew,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EIS Submission Record"
        }
    },
    {
        path: "/:lang/monthlyepfexport",
        name: "monthlyepfexport",
        component: MonthlyEPFExport,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EPF Export"
        }
    },

    {
        path: "/:lang/monthlyepfexport/param/:id/:item",
        name: "monthlyepfexportparam",
        component: MonthlyEPFExportParam,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EPF Export"
        }
    },

    {
        path: "/:lang/monthlyepfexport/log",
        name: "monthlyepfexportlog",
        component: MonthlyEPFExportLog,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EPF Export"
        }
    },

    // {
    //     path: "/:lang/monthlyptptnexport",
    //     name: "monthlyptptnexport",
    //     component: MonthlyPTPTNExport,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "Monthly PTPTN Export"
    //     }
    // },

    // {
    //     path: "/:lang/monthlyptptnexport/param/:id/:item",
    //     name: "monthlyptptnexportparam",
    //     component: MonthlyPTPTNExportParam,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "Monthly PTPTN Export"
    //     }
    // },

    // {
    //     path: "/:lang/monthlyptptnexport/log",
    //     name: "monthlyptptnexportlog",
    //     component: MonthlyPTPTNExportLog,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "Monthly PTPTN Export"
    //     }
    // },

    {
        path: "/:lang/taxexport",
        name: "monthlytaxexport",
        component: MonthlyTaxExport,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Tax Export"
        }
    },

    {
        path: "/:lang/taxexport/param/:id/:item",
        name: "monthlytaxexportparam",
        component: MonthlyTaxExportParam,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Tax Export"
        }
    },

    {
        path: "/:lang/taxexport/log",
        name: "monthlytaxexportlog",
        component: MonthlyTaxExportLog,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Tax Export"
        }
    },


    {
        path: '/:lang/parametertaxcp3839',
        name: 'parametertaxcp3839',
        component: ParameterTaxCP3839,
        meta: {
            requiresAuth: true,
            funcname: "CP38/39"
        }
    },

    {
        path: "/:lang/monthlyeisexport",
        name: "monthlyeisexport",
        component: MonthlyEISExport,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EIS Export"
        }
    },

    {
        path: "/:lang/monthlyeisexport/param/:id/:item",
        name: "monthlyeisexportparam",
        component: MonthlyEISExportParam,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EIS Export"
        }
    },

    {
        path: "/:lang/monthlyeisexport/log",
        name: "monthlyeisexportlog",
        component: MonthlyEISExportLog,
        meta: {
            requiresAuth: true,
            funcname: "Monthly EIS Export"
        }
    },

    {
        path: "/:lang/monthlysocsoexport",
        name: "monthlysocsoexport",
        component: MonthlySocsoExport,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO Export"
        }
    },

    {
        path: "/:lang/monthlysocsoexport/param/:id/:item",
        name: "monthlysocsoexportparam",
        component: MonthlySocsoExportParam,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO Export"
        }
    },

    {
        path: "/:lang/monthlysocsoexport/log",
        name: "monthlysocsoexportlog",
        component: MonthlySocsoExportLog,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO Export"
        }
    },

    {
        path: "/:lang/parametereaform",
        name: "parametereaform",
        component: ParameterEaForm,
        meta: {
            requiresAuth: true,
            funcname: "EA Form"
        }
    },

    {
        path: "/:lang/parametertaxpcbii",
        name: "parametertaxpcbii",
        component: ParameterTaxPcbII,
        meta: {
            requiresAuth: true,
            funcname: "PCB II"
        }
    },


    {
        path: "/:lang/payrollcommand/filter",
        name: "payrollcommandfilter",
        component: PayrollCommandFilter,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Command"
        }
    },

    {
        path: "/:lang/payrollcommand/process",
        name: "payrollcommandprocess",
        component: PayrollCommandProcess,
        meta: {
            requiresAuth: true,
            funcname: ""
        }
    },

    {
        path: "/:lang/documentrecord/list",
        name: "documentrecordlist",
        component: DocumentRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Document Record"
        }
    },

    {
        path: "/:lang/documentrecord/new",
        name: "documentrecordnew",
        component: DocumentRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Document Record"
        }
    },

    {
        path: "/:lang/documentrecord/edit/:id",
        name: "documentrecordedit",
        component: DocumentRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Document Record"
        }
    },

    {
        //Form A export	
        path: "/:lang/formaexport",
        name: "formaexport",
        component: FormAExport,
        meta: {
            requiresAuth: true,
            funcname: "Borang A"
        }
    },
    {
        //EIS Socso 8A export	
        path: "/:lang/eissocso8a",
        name: "eissocso8a",
        component: EISSocso8AExport,
        meta: {
            requiresAuth: true,
            funcname: "Borang 8A"
        }
    },
    {
        //Socso 8A export	
        path: "/:lang/socso8a",
        name: "socso8a",
        component: Socso8AExport,
        meta: {
            requiresAuth: true,
            funcname: "Borang 8A"
        }
    },
    {
        //Data Access Grid
        path: "/:lang/dataaccess/list",
        name: "dataaccesslist",
        component: DataAccessList,
        meta: {
            requiresAuth: true,
            funcname: "HR User Data Access"
        }
    },
    {
        path: "/:lang/dataaccess/edit/:id",
        name: "dataaccessedit",
        component: DataAccessEdit,
        meta: {
            requiresAuth: true,
            funcname: "HR User Data Access"
        }
    },
    {
        path: "/:lang/dataaccess/new",
        name: "dataaccessnew",
        component: DataAccessNew,
        meta: {
            requiresAuth: true,
            funcname: "HR User Data Access"
        }
    },
    {
        //Function Access Grid
        path: "/:lang/functionaccess/list",
        name: "functionaccesslist",
        component: FunctionAccessList,
        meta: {
            requiresAuth: true,
            funcname: "HR User Function Access"
        }
    },
    {
        path: "/:lang/functionaccess/edit/:id",
        name: "functionaccessedit",
        component: FunctionAccessEdit,
        meta: {
            requiresAuth: true,
            funcname: "HR User Function Access"
        }
    },
    {
        path: "/:lang/functionaccess/new",
        name: "functionaccessnew",
        component: FunctionAccessNew,
        meta: {
            requiresAuth: true,
            funcname: "HR User Function Access"
        }
    },

    {
        //wizardlisting export	
        path: "/:lang/wizardlisting/list",
        name: "wizardlisting",
        component: WizardListing,
        meta: {
            requiresAuth: true,
            funcname: "Wizard Listing"
        }
    },

    {
        //wizardlisting export	
        path: "/:lang/wizardlisting/step",
        name: "wizardstep",
        component: WizardStep,
        meta: {
            requiresAuth: true,
            funcname: "Wizard Step"
        }
    },

    {
        //Payroll pivot
        path: "/:lang/payrollpivot/filter",
        name: "payrollpivotfilter",
        component: PayrollPivotFilter,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Pivot"
        }
    },

    {
        //Payroll pivot
        path: "/:lang/payrollpivot/detail",
        name: "payrollpivotdetail",
        component: PayrollPivotDetail,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Pivot"
        }
    },

    {
        path: "/:lang/wizard/list",
        name: "wizardlist",
        component: WizardList,
        meta: {
            requiresAuth: true,
            funcname: "Wizard Setup"
        }
    },

    {
        path: "/:lang/wizard/new",
        name: "wizardnew",
        component: WizardNew,
        meta: {
            requiresAuth: true,
            funcname: "Wizard Setup"
        }
    },

    {
        path: "/:lang/wizard/edit/:id",
        name: "wizardedit",
        component: WizardEdit,
        meta: {
            requiresAuth: true,
            funcname: "Wizard Setup"
        }

    },

    {
        path: "/:lang/alert/edit/:id",
        name: "alertedit",
        component: AlertEdit,
        meta: {
            requiresAuth: true,
            funcname: "Alert Setup"
        }
    },

    {
        path: "/:lang/alert/list",
        name: "alertlist",
        component: AlertGrid,
        meta: {
            requiresAuth: true,
            funcname: "Alert Setup"
        }
    },

    {
        path: "/:lang/alert/new",
        name: "alertnew",
        component: AlertNew,
        meta: {
            requiresAuth: true,
            funcname: "Alert Setup"
        }
    },

    {
        //alertlisting export	
        path: "/:lang/alertlisting/list",
        name: "alertlisting",
        component: AlertListing,
        meta: {
            requiresAuth: true,
            funcname: "Alert Listing"
        }
    },

    {
        path: "/:lang/importdata",
        name: "importdata",
        component: ImportFunction,
        meta: {
            requiresAuth: true,
            funcname: "Import Data"
        }
    },
    {
        path: "/:lang/mapping/list",
        name: "mappinglist",
        component: MappingFunction,
        meta: {
            requiresAuth: true,
            funcname: "Mapping"
        }
    },
    {
        path: "/:lang/mapping/new",
        name: "mappingnew",
        component: MappingNewFunction,
        meta: {
            requiresAuth: true,
            funcname: "Mapping"
        }
    },
    {
        path: "/:lang/mapping/edit/:id",
        name: "mappingedit",
        component: MappingEditFunction,
        meta: {
            requiresAuth: true,
            funcname: "Mapping"
        }
    },
    {
        path: "/:lang/mappingprocess",
        name: "mappingprocess",
        component: MappingProcessFunction,
        meta: {
            requiresAuth: true,
            funcname: "Mapping Process"
        }
    },

    {
        path: "/:lang/parameterservicemovementlisting/parameter",
        name: "parameterservicemovementlisting",
        component: parameterServiceMovementListing,
        meta: {
            requiresAuth: true,
            funcname: "Service Movement Listing"
        }
    },
    {
        path: "/:lang/detailsservicemovementlisting",
        name: "detailsservicemovementlisting",
        component: detailsServiceMovementListing,
        meta: {
            requiresAuth: true,
            funcname: "Service Movement Listing"
        }
    },

    {
        path: "/:lang/setup/new",
        name: "holidaynew",
        component: HolidayNew,
        meta: {
            requiresAuth: true,
            funcname: "Holiday Setup"
        }
    },

    {
        path: "/:lang/setup/list",
        name: "holidaylist",
        component: HolidayGrid,
        meta: {
            requiresAuth: true,
            funcname: "Holiday Setup"
        }
    },

    {
        path: "/:lang/setup/edit/:id",
        name: "holidayedit",
        component: HolidayEdit,
        meta: {
            requiresAuth: true,
            funcname: "Holiday Setup"
        }
    },

    {
        path: "/:lang/announcement/new",
        name: "announcementnew",
        component: AnnouncementNew,
        meta: {
            requiresAuth: true,
            funcname: "Announcement Setup"
        }
    },

    {
        path: "/:lang/announcement/list",
        name: "announcementlist",
        component: AnnouncementGrid,
        meta: {
            requiresAuth: true,
            funcname: "Announcement Setup"
        }
    },

    {
        path: "/:lang/announcement/edit/:id",
        name: "announcementedit",
        component: AnnouncementEdit,
        meta: {
            requiresAuth: true,
            funcname: "Announcement Setup"
        }
    },

    {
        path: "/:lang/personandstaff/parameter",
        name: "parameterpersonandstaff",
        component: parameterPersonAndStaff,
        meta: {
            requiresAuth: true,
            funcname: "Person & Staff Details Summary"
        }
    },

    {
        path: '/:lang/personandstaff/details/:formData',
        name: 'detailspersonandstaff',
        component: detailsPersonAndStaff,
        meta: {
            requiresAuth: true,
            funcname: "Person & Staff Details Summary"
        }
    },

    {
        path: '/:lang/miscpayrollList/parameter',
        name: 'parameterMisc',
        component: parameterMisc,
        meta: {
            requiresAuth: true,
            funcname: "MISC Payroll List"
        }
    },

    {
        path: '/:lang/miscpayrollList/details/:formData',
        name: 'detailsMisc',
        component: detailsMisc,
        meta: {
            requiresAuth: true,
            funcname: "MISC Payroll List"
        }
    },

    {
        path: '/:lang/disciplinaryrecordslisting/list',
        name: 'disciplinarylist',
        component: DisciplinaryList,
        meta: {
            requiresAuth: true,
            funcname: "Disciplinary Records Listing"
        }
    },
    {
        path: '/:lang/disciplinaryrecordslisting/new',
        name: 'disciplinarynew',
        component: DisciplinaryNew,
        meta: {
            requiresAuth: true,
            funcname: "Disciplinary Records Listing"
        }
    },
    {
        path: '/:lang/disciplinaryrecordslisting/edit/:id',
        name: 'disciplinaryedit',
        component: DisciplinaryEdit,
        meta: {
            requiresAuth: true,
            funcname: "Disciplinary Records Listing"
        }
    },

    {
        path: '/:lang/workflowlisting/list',
        name: 'workflowlist',
        component: WorkflowList,
        meta: {
            requiresAuth: true,
            funcname: "Approval Workflow Listing"
        }
    },
    {
        path: '/:lang/workflowlisting/new',
        name: 'workflownew',
        component: WorkflowNew,
        meta: {
            requiresAuth: true,
            funcname: "Approval Workflow Listing"
        }
    },
    {
        path: '/:lang/workflowlisting/edit/:id',
        name: 'workflowedit',
        component: WorkflowEdit,
        meta: {
            requiresAuth: true,
            funcname: "Approval Workflow Listing"
        }
    },

    //shiftsetup routes
    {
        path: "/:lang/shiftsetup/list",
        name: "shiftsetuplist",
        component: ShiftSetupList,
        meta: {
            requiresAuth: true,
            funcname: "Shift Setup"
        }
    },

    {
        path: "/:lang/shiftsetup/edit/:id",
        name: "shiftsetupedit",
        component: ShiftSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Shift Setup"
        }
    },

    {
        path: "/:lang/shiftsetup/new",
        name: "shiftsetupnew",
        component: ShiftSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Shift Setup"
        }
    },

    {
        path: "/:lang/periodshiftinc/list",
        name: "periodshiftinclist",
        component: PeriodShiftIncList,
        meta: {
            requiresAuth: true,
            funcname: "Periodic Attendance Incentive Setup"
        }
    },

    {
        path: "/:lang/periodshiftinc/edit/:id",
        name: "periodshiftincedit",
        component: PeriodShiftIncEdit,
        meta: {
            requiresAuth: true,
            funcname: "Periodic Attendance Incentive Setup"
        }
    },

    {
        path: "/:lang/periodshiftinc/new",
        name: "periodshiftincnew",
        component: PeriodShiftIncNew,
        meta: {
            requiresAuth: true,
            funcname: "Periodic Attendance Incentive Setup"
        }
    },

    {
        path: "/:lang/workschedulegroup/list",
        name: "workschedulegrouplist",
        component: WorkScheduleGroupList,
        meta: {
            requiresAuth: true,
            funcname: "Work Schedule Group Assignment"
        }
    },

    {
        path: "/:lang/workschedulegroup/edit/:id",
        name: "workschedulegroupedit",
        component: WorkScheduleGroupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Work Schedule Group Assignment"
        }
    },

    {
        path: "/:lang/workschedulegroup/new",
        name: "workschedulegroupnew",
        component: WorkScheduleGroupNew,
        meta: {
            requiresAuth: true,
            funcname: "Work Schedule Group Assignment"
        }
    },

    {
        path: "/:lang/workschedulestaff/list",
        name: "workschedulestafflist",
        component: WorkScheduleStaffList,
        meta: {
            requiresAuth: true,
            funcname: "Work Schedule by Staff for a Period"
        }
    },

    {
        path: "/:lang/workschedulestaff/edit/:id",
        name: "workschedulestaffedit",
        component: WorkScheduleStaffEdit,
        meta: {
            requiresAuth: true,
            funcname: "Work Schedule by Staff for a Period"
        }
    },

    {
        path: "/:lang/workschedulestaff/new",
        name: "workschedulestaffnew",
        component: WorkScheduleStaffNew,
        meta: {
            requiresAuth: true,
            funcname: "Work Schedule by Staff for a Period"
        }
    },


    {
        path: "/:lang/staffdailyroster/list",
        name: "staffdailyrosterlist",
        component: StaffDailyRosterList,
        meta: {
            requiresAuth: true,
            funcname: "Staff Daily Roster"
        }
    },

    {
        path: "/:lang/workhourspayitemmapping/list",
        name: "workhourspayitemmappinglist",
        component: WorkHoursPayItemMapping,
        meta: {
            requiresAuth: true,
            funcname: "Work Hours Pay Item Mapping"
        }
    },

    {
        path: "/:lang/workhourspayitemmapping/new",
        name: "workhourspayitemmappingnew",
        component: WorkHoursPayItemMappingNew,
        meta: {
            requiresAuth: true,
            funcname: "Work Hours Pay Item Mapping"
        }
    },

    {
        path: "/:lang/workhourspayitemmapping/edit/:id",
        name: "workhourspayitemmappingedit",
        component: WorkHoursPayItemMappingEdit,
        meta: {
            requiresAuth: true,
            funcname: "Work Hours Pay Item Mapping"
        }
    },
    {
        path: "/:lang/preapprovedot/list",
        name: "preapprovedotlist",
        component: PreApprovedOT,
        meta: {
            requiresAuth: true,
            funcname: "Pre Approved OT"
        }
    },
    {
        path: "/:lang/preapprovedot/new",
        name: "preapprovedotnew",
        component: PreApprovedOTNew,
        meta: {
            requiresAuth: true,
            funcname: "Pre Approved OT"
        }
    },
    {
        path: "/:lang/preapprovedot/edit/:id",
        name: "preapprovedotedit",
        component: PreApprovedOTEdit,
        meta: {
            requiresAuth: true,
            funcname: "Pre Approved OT"
        }
    },
    {
        path: "/:lang/processclockdata",
        name: "processclockdata",
        component: ProcessClockData,
        meta: {
            requiresAuth: true,
            funcname: "Process Clock Data"
        }
    },
    {
        path: "/:lang/processedattendacedata",
        name: "processedattendancedata",
        component: ProcessedAttendanceData,
        meta: {
            requiresAuth: true,
            funcname: "Processed Attendance Data"
        }
    },
    {
        path: "/:lang/processclockdatalog",
        name: "processclockdatalog",
        component: ProcessClockDataLog,
        meta: {
            requiresAuth: true,
            funcname: "Processed Attendance Data"
        }
    },
    {
        path: "/:lang/attendancepayrollposting",
        name: "attendancepayrollposting",
        component: AttendancePayrollPosting,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Payroll Posting"
        }
    },
    {
        path: "/:lang/attendancepostinglog",
        name: "attendancepostinglog",
        component: AttendancePostingLog,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Payroll Posting"
        }
    },
    {
        path: "/:lang/generateunpaidleave",
        name: "generateunpaidleave",
        component: GenerateUnpaidLeave,
        meta: {
            requiresAuth: true,
            funcname: "Generate Unpaid Leave for Absent Days"
        }
    },
    {
        path: "/:lang/generateunpaidleavelog",
        name: "generateunpaidleavelog",
        component: GenerateUnpaidLeaveLog,
        meta: {
            requiresAuth: true,
            funcname: "Generate Unpaid Leave for Absent Days"
        }
    },
    {
        path: "/:lang/generateperiodicattnincentive",
        name: "generateperiodicattnincentive",
        component: GeneratePeriodicAttendanceIncentive,
        meta: {
            requiresAuth: true,
            funcname: "Generate Periodic Attendance Incentive"
        }
    },
    {
        path: "/:lang/generateperiodicattnincentivelog",
        name: "generateperiodicattnincentivelog",
        component: GeneratePeriodicAttendanceIncentiveLog,
        meta: {
            requiresAuth: true,
            funcname: "Generate Periodic Attendance Incentive"
        }
    },
    {
        path: "/:lang/parameterauditlisting/parameter",
        name: "parameterauditlisting",
        component: parameterAuditListing,
        meta: {
            requiresAuth: true,
            funcname: "Audit Listing"
        }
    },
    {
        path: "/:lang/detailsauditlisting",
        name: "detailsauditlisting",
        component: detailsAuditListing,
        meta: {
            requiresAuth: true,
            funcname: "Audit Listing"
        }
    },

    {
        path: "/:lang/leavedashboard/parameter",
        name: "leavedashboardfilter",
        component: LeaveDashboardFilter,
        meta: {
            requiresAuth: true,
            funcname: "Leave Dashboard"
        }
    },

    {
        path: "/:lang/leavedashboard/details/:startdate/:enddate/:parameters",
        name: "leavedashboardsummary",
        component: LeaveDashboardSummary,
        meta: {
            requiresAuth: true,
            funcname: "Leave Dashboard"
        }
    },

    // {
    //     path: "/:lang/leavedashboard/detail",
    //     name: "leavedashboarddetail",
    //     component: LeaveDashboardDetail,
    //     meta: {
    //         requiresAuth: true,
    //         funcname: "Leave Dashboard"
    //     }
    // },

    {
        path: '/:lang/asset/list',
        name: 'assetlist',
        component: AssetGrid,
        meta: {
            requiresAuth: true,
            funcname: "Asset Record Listing"
        }
    },
    {
        path: '/:lang/asset/new',
        name: 'assetnew',
        component: AssetNew,
        meta: {
            requiresAuth: true,
            funcname: "Asset Record Listing"
        }
    },   
    {
        path: '/:lang/asset/edit/:id',
        name: 'assetedit',
        component: AssetEdit,
        meta: {
            requiresAuth: true,
            funcname: "Asset Record Listing"
        }
    },   

    {
        path: '/:lang/assetassignment/list',
        name: 'assetassignmentlist',
        component: AssetAssignmentGrid,
        meta: {
            requiresAuth: true,
            funcname: "Asset Assignment Listing"
        }
    },
    {
        path: '/:lang/assetassignment/new',
        name: 'assetassignmentnew',
        component: AssetAssignmentNew,
        meta: {
            requiresAuth: true,
            funcname: "Asset Assignment Listing"
        }
    },   
    {
        path: '/:lang/assetassignment/edit/:id',
        name: 'assetassignmentedit',
        component: AssetAssignmentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Asset Assignment Listing"
        }
    },
    
    // path to position assignment analysis

    {
        path: '/:lang/positionassignmentanalysis/:asatdate',
        name: 'positionassignmentanalysis',
        component: PositionAssignmentAnalysis,
        meta: {
            requiresAuth: true,
            funcname: "Position Assignment Analysis"
        }
    },  

    {
        path: '/:lang/positionassignmentanalysisparameter',
        name: 'positionassignmentanalysisparameter',
        component: PositionAssignmentAnalysisParameter,
        meta: {
            requiresAuth: true,
            funcname: "Position Assignment Analysis"
        }
    },

    {
        path: '/:lang/attendanceperiod/parameter',
        name: 'attendanceperiod',
        component: AttendancePeriod,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Report by Period by Company"
        }
    },

    {
        path: '/:lang/attendanceperiod/details/:formData',
        name: 'detailsattendanceperiod',
        component: DetailsAttendancePeriod,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Report by Period by Company"
        }
    },

    {
        path: '/:lang/whoisonleave/dashboard',
        name: 'whoisonleavedashboard',
        component: WhoIsOnLeaveDashboard,
        meta: {
            requiresAuth: true,
            funcname: "Who Is On Leave"
        }
    },

    {
        path: '/:lang/whoisonleave/details/:item',
        name: 'whoisonleavedetail',
        component: WhoIsOnLeaveDetail,
        meta: {
            requiresAuth: true,
            funcname: "Who Is On Leave"
        }
    },
        
    {
        path: '/:lang/attendancelatecomer/parameter',
        name: 'attendancelatecomer',
        component: AttendanceLateComerListing,
        meta: {
            requiresAuth: true,
            funcname: "Late Comer Listing"
        }
    },

    {
        path: '/:lang/attendancelatecomer/details/:formData',
        name: 'detailsattendancelatecomer',
        component: DetailsAttendanceLateComer,
        meta: {
            requiresAuth: true,
            funcname: "Late Comer Listing"
        }
    },


    {
        path: '/:lang/attendanceabsent/parameter',
        name: 'attendanceabsent',
        component: AttendanceAbsentListing,
        meta: {
            requiresAuth: true,
            funcname: "Absent Listing"
        }
    },

    {
        path: '/:lang/attendanceabsent/details/:formData',
        name: 'detailsattendanceabsent',
        component: DetailsAttendanceAbsent,
        meta: {
            requiresAuth: true,
            funcname: "Absent Listing"
        }
    },

    {
        path: "/:lang/claimtypesetup/list",
        name: "claimtypesetuplist",
        component: ClaimTypeSetupGrid,
        meta: {
            requiresAuth: true,
            funcname: "Claim Type Setup"
        }
    },

    {
        path: "/:lang/claimtypesetup/new",
        name: "claimtypesetupnew",
        component: ClaimTypeSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Claim Type Setup"

        }
    },

    {
        path: "/:lang/claimtypesetup/edit/:id",
        name: "claimtypesetupedit",
        component: ClaimTypeSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Claim Type Setup"
        }
    },

    {
        path: "/:lang/claimlimitsetup/list",
        name: "claimlimitsetuplist",
        component: ClaimLimitSetupGrid,
        meta: {
            requiresAuth: true,
            funcname: "Claim Limit Setup"
        }
    },

    {
        path: "/:lang/claimlimitsetup/new",
        name: "claimlimitsetupnew",
        component: ClaimLimitSetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Claim Limit Setup"

        }
    },

    {
        path: "/:lang/claimlimitsetup/edit/:id",
        name: "claimlimitsetupedit",
        component: ClaimLimitSetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Claim Limit Setup"
        }
    },


    {
        path: "/:lang/claimrecord/list",
        name: "claimrecordlist",
        component: ClaimRecordGrid,
        meta: {
            requiresAuth: true,
            funcname: "Claim Record"
        }
    },

    {
        path: "/:lang/claimrecord/new",
        name: "claimrecordnew",
        component: ClaimRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Claim Record"

        }
    },

    {
        path: "/:lang/claimrecord/edit/:id",
        name: "claimrecordedit",
        component: ClaimRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Claim Record"
        }
    },

    {
        path: "/:lang/claimlimitadjustment/list",
        name: "claimlimitadjustmentlist",
        component: ClaimLimitAdjustmentGrid,
        meta: {
            requiresAuth: true,
            funcname: "Claim Limit Adjustment"
        }
    },

    {
        path: "/:lang/claimlimitadjustment/new",
        name: "claimlimitadjustmentnew",
        component: ClaimLimitAdjustmentNew,
        meta: {
            requiresAuth: true,
            funcname: "Claim Limit Adjustment"

        }
    },

    {
        path: "/:lang/claimlimitadjustment/edit/:id",
        name: "claimlimitadjustmentedit",
        component: ClaimLimitAdjustmentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Claim Limit Adjustment"
        }
    },

    {
        path: "/:lang/claimlimitutilization/staff",
        name: "claimlimitutilizationbystaff",
        component: ClaimLimitAndUtilizationByStaff,
        meta: {
            requiresAuth: true,
            funcname: "Claim Limit And Utilization By Staff"
        }
    },
    
    {
        path: '/:lang/claimutilizationperiod/details/:formData',
        name: 'claimutilizationperioddetails',
        component: ClaimUtilizationPeriodDetails,
        meta: {
            requiresAuth: true,
            funcname: "Claim Utilization by Period"
        }
    },  

    {
        path: '/:lang/claimutilizationperiod/parameter',
        name: 'claimutilizationperiodparameter',
        component: ClaimUtilizationPeriodParameter,
        meta: {
            requiresAuth: true,
            funcname: "Claim Utilization by Period"
        }
    },

    {
        path: "/:lang/workflowexception/list",
        name: "workflowexceptionlist",
        component: WorkflowExceptionList,
        meta: {
            requiresAuth: true,
            funcname: "Workflow Exception"
        }
    },

    {
        path: "/:lang/workflowexception/new",
        name: "workflowexceptionnew",
        component: WorkflowExceptionNew,
        meta: {
            requiresAuth: true,
            funcname: "Workflow Exception"
        }
    },

    {
        path: "/:lang/workflowexception/edit/:id",
        name: "workflowexceptionedit",
        component: WorkflowExceptionEdit,
        meta: {
            requiresAuth: true,
            funcname: "Workflow Exception"
        }
    },

    {
        path: '/:lang/claimtransaction/details/:formData',
        name: 'claimtransactiondetails',
        component: ClaimTransactionDetails,
        meta: {
            requiresAuth: true,
            funcname: "Claim Transaction Listing"
        }
    },  

    {
        path: '/:lang/claimtransaction/parameter',
        name: 'claimtransactionparameter',
        component: ClaimTransactionParameter,
        meta: {
            requiresAuth: true,
            funcname: "Claim Transaction Listing"
        }
    },
    
    {
        path: '/:lang/hrdfList/details/:formData',
        name: 'hrdflistingdetails',
        component: HRDFListingDetails,
        meta: {
            requiresAuth: true,
            funcname: "HRDF Listing"
        }
    },  

    {
        path: '/:lang/hrdfList/parameter',
        name: 'hrdflistingparameter',
        component: HRDFListingParameter,
        meta: {
            requiresAuth: true,
            funcname: "HRDF Listing"
        }
    },

    {
        //EIS SOCSO Lampiran 1 export	
        path: "/:lang/eisoclampiran1",
        name: "eisoclampiran1",
        component: EISOCLampiran1,
        meta: {
            requiresAuth: true,
            funcname: "EIS Lampiran 1"
        }
    },

    {
        //EIS Lampiran 1 export	
        path: "/:lang/eislampiran1",
        name: "eislampiran1",
        component: EISLampiran1,
        meta: {
            requiresAuth: true,
            funcname: "EIS Lampiran 1"
        }
    },

    {
        path: '/:lang/claimutilizationtrendanalysis/details/:formData',
        name: 'claimutilizationtrendanalysisdetails',
        component: ClaimUtilizationTrendAnalysisDetails,
        meta: {
            requiresAuth: true,
            funcname: "Claim Utilization Trend Analysis"
        }
    },  

    {
        path: '/:lang/claimutilizationtrendanalysis/parameter',
        name: 'claimutilizationtrendanalysisparameter',
        component: ClaimUtilizationTrendAnalysisParameter,
        meta: {
            requiresAuth: true,
            funcname: "Claim Utilization Trend Analysis"
        }
    },

     //target training hours pattern setup routes
     {
        path: "/:lang/targettraininghours/list",
        name: "targettraininghourslist",
        component: TargetTrainingHoursList,
        meta: {
            requiresAuth: true,
            funcname: "Target Training Hours"
        }
    },

    {
        path: "/:lang/targettraininghours/edit/:id",
        name: "targettraininghoursedit",
        component: TargetTrainingHoursEdit,
        meta: {
            requiresAuth: true,
            funcname: "Target Training Hours"
        }
    },

    {
        path: "/:lang/targettraininghours/new",
        name: "targettraininghoursnew",
        component: TargetTrainingHoursNew,
        meta: {
            requiresAuth: true,
            funcname: "Target Training Hours"
        }
    },

      //target training hours pattern setup routes
      {
        path: "/:lang/trainingrecord/list",
        name: "trainingrecordlist",
        component: TrainingRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Training Record"
        }
    },

    {
        path: "/:lang/trainingrecord/edit/:id",
        name: "trainingrecordedit",
        component: TrainingRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Training Record"
        }
    },

    {
        path: "/:lang/trainingrecord/new",
        name: "trainingrecordnew",
        component: TrainingRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Training Record"
        }
    },

    {
        path: '/:lang/traininghoursanalysis/details/:formData',
        name: 'traininghoursanalysisdetails',
        component: TrainingHoursAnalysisDetails,
        meta: {
            requiresAuth: true,
            funcname: "Training Hours Analysis"
        }
    },  

    {
        path: '/:lang/traininghoursanalysis/parameter',
        name: 'traininghoursanalysisparameter',
        component: TrainingHoursAnalysisParameter,
        meta: {
            requiresAuth: true,
            funcname: "Training Hours Analysis"
        }
    },

    {
        path: '/:lang/trainingexpensesanalysis/details/:formData',
        name: 'trainingexpensesanalysisdetails',
        component: TrainingExpensesAnalysisDetails,
        meta: {
            requiresAuth: true,
            funcname: "Training Expenses Analysis"
        }
    },  

    {
        path: '/:lang/trainingexpensesanalysis/parameter',
        name: 'trainingexpensesanalysisparameter',
        component: TrainingExpensesAnalysisParameter,
        meta: {
            requiresAuth: true,
            funcname: "Training Expenses Analysis"
        }
    },

     //group insurance policy setup routes
     {
        path: "/:lang/groupinsurancepolicy/list",
        name: "groupinsurancepolicylist",
        component: GroupInsurancePolicyList,
        meta: {
            requiresAuth: true,
            funcname: "Group Insurance Policy"
        }
    },

    {
        path: "/:lang/groupinsurancepolicy/edit/:id",
        name: "groupinsurancepolicyedit",
        component: GroupInsurancePolicyEdit,
        meta: {
            requiresAuth: true,
            funcname: "Group Insurance Policy"
        }
    },

    {
        path: "/:lang/groupinsurancepolicy/new",
        name: "groupinsurancepolicynew",
        component: GroupInsurancePolicyNew,
        meta: {
            requiresAuth: true,
            funcname: "Group Insurance Policy"
        }
    },

    {
        path: "/:lang/reportexportmapping/list",
        name: "reportexportmappinglist",
        component: ReportExportMappingList,
        meta: {
            requiresAuth: true,
            funcname: "Report Export Mapping"
        }
    },

    {
        path: "/:lang/reportexportmapping/edit/:id",
        name: "reportexportmappingedit",
        component: ReportExportMappingEdit,
        meta: {
            requiresAuth: true,
            funcname: "Report Export Mapping"
        }
    },

    {
        path: "/:lang/reportexportmapping/new",
        name: "reportexportmappingnew",
        component: ReportExportMappingNew,
        meta: {
            requiresAuth: true,
            funcname: "Report Export Mapping"
        }
    },


    {
        path: "/:lang/grpinsassignment/list",
        name: "grpinsassignmentlist",
        component: GrpInsAssignmentList,
        meta: {
            requiresAuth: true,
            funcname: "Group Insurance Assignment"
        }
    },

    {
        path: "/:lang/grpinsassignment/edit/:id",
        name: "grpinsassignmentedit",
        component: GrpInsAssignmentEdit,
        meta: {
            requiresAuth: true,
            funcname: "Group Insurance Assignment"
        }
    },

    {
        path: "/:lang/grpinsassignment/new",
        name: "grpinsassignmentnew",
        component: GrpInsAssignmentNew,
        meta: {
            requiresAuth: true,
            funcname: "Group Insurance Assignment"
        }
    },

    {
        path: "/:lang/exportstaffinsurance",
        name: "exportstaffinsuranceparameter",
        component: ExportStaffInsurance,
        meta: {
            requiresAuth: true,
            funcname: "Export Staff Insurance Records"
        }
    },

    {
        path: "/:lang/exportstaffinsurancedetail",
        name: "exportstaffinsurancedetail",
        component: ExportStaffInsuranceDetail,
        meta: {
            requiresAuth: true,
            funcname: "Export Staff Insurance Records"
        }
    },

    {
        path: "/:lang/exportstaffinsurancelog",
        name: "exportstaffinsurancelog",
        component: ExportStaffInsuranceLog,
        meta: {
            requiresAuth: true,
            funcname: "Export Staff Insurance Records"
        }
    },
    
    {

        path: '/:lang/batchusercreation/parameter',
        name: 'batchusercreationparameter',
        component: BatchUserCreationParameter,
        meta: {
            requiresAuth: true,
            funcname: "Batch User Creation"
        }
    },

    {
        path: '/:lang/batchusercreation/log',
        name: 'batchusercreationlog',
        component: BatchUserCreationLog,
        meta: {
            requiresAuth: true,
            funcname: "Batch User Creation"
        }
    },

    {
        path: "/:lang/ratingscaleset/list",
        name: "ratingscalesetlist",
        component: RatingScaleSetList,
        meta: {
            requiresAuth: true,
            funcname: "Rating Scale Set"
        }
    },

    {
        path: "/:lang/ratingscaleset/edit/:id",
        name: "ratingscalesetedit",
        component: RatingScaleSetEdit,
        meta: {
            requiresAuth: true,
            funcname: "Rating Scale Set"
        }
    },

    {
        path: "/:lang/ratingscaleset/new",
        name: "ratingscalesetnew",
        component: RatingScaleSetNew,
        meta: {
            requiresAuth: true,
            funcname: "Rating Scale Set"
        }
    },

    {
        path: "/:lang/rankingset/list",
        name: "rankingsetlist",
        component: RankingSetList,
        meta: {
            requiresAuth: true,
            funcname: "Ranking Set"
        }
    },

    {
        path: "/:lang/rankingset/edit/:id",
        name: "rankingsetedit",
        component: RankingSetEdit,
        meta: {
            requiresAuth: true,
            funcname: "Ranking Set"
        }
    },

    {
        path: "/:lang/rankingset/new",
        name: "rankingsetnew",
        component: RankingSetNew,
        meta: {
            requiresAuth: true,
            funcname: "Ranking Set"
        }
    },

    
    {
        path: "/:lang/appraisalexercise/list",
        name: "appraisalexerciselist",
        component: AppraisalExerciseList,
        meta: {
            requiresAuth: true,
            funcname: "Appraisal Exercise"
        }
    },

    {
        path: "/:lang/appraisalexercise/edit/:id",
        name: "appraisalexerciseedit",
        component: AppraisalExerciseEdit,
        meta: {
            requiresAuth: true,
            funcname: "Appraisal Exercise"
        }
    },

    {
        path: "/:lang/appraisalexercise/new",
        name: "appraisalexercisenew",
        component: AppraisalExerciseNew,
        meta: {
            requiresAuth: true,
            funcname: "Appraisal Exercise"
        }
    },

    {
        path: "/:lang/appraisalrecord/list",
        name: "appraisalrecordlist",
        component: AppraisalRecordList,
        meta: {
            requiresAuth: true,
            funcname: "Appraisal Record"
        }
    },

    {
        path: "/:lang/appraisalrecord/edit/:id",
        name: "appraisalrecordedit",
        component: AppraisalRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "Appraisal Record"
        }
    },

    {
        path: "/:lang/appraisalrecord/new",
        name: "appraisalrecordnew",
        component: AppraisalRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "Appraisal Record"
        }
    },

    {
        path: '/:lang/performancereview/details/:formData',
        name: 'performancereviewdetails',
        component: PerformanceReviewDetails,
        meta: {
            requiresAuth: true,
            funcname: "Performance Review Analysis"
        }
    },  

    {
        path: '/:lang/performancereview/parameter',
        name: 'performancereviewparameter',
        component: PerformanceReviewParameter,
        meta: {
            requiresAuth: true,
            funcname: "Performance Review Analysis"
        }
    },

    {
        path: '/:lang/performancereward/details/:formData',
        name: 'performancerewarddetails',
        component: PerformanceRewardDetails,
        meta: {
            requiresAuth: true,
            funcname: "Performance Reward Summary"
        }
    },  

    {
        path: '/:lang/performancereward/parameter',
        name: 'performancerewardparameter',
        component: PerformanceRewardParameter,
        meta: {
            requiresAuth: true,
            funcname: "Performance Reward Summary"
        }
    },

    {
        path: "/:lang/kpisetting/list",
        name: "kpisettinglist",
        component: KpiSettingList,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },

    {
        path: '/:lang/kpisetting/new',
        name: 'kpisettingnew',
        component: KpiSettingNew,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },

    {
        path: '/:lang/kpisetting/edit/:id',
        name: 'kpisettingedit',
        component: KpiSettingEdit,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },

    {
        path: '/:lang/kpisetting/edit/commonkpi/:id',
        name: 'kpisettingeditcommonkpi',
        component: KpiSettingEditCommonKpi,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },

    {
        path: '/:lang/kpisetting/generate',
        name: 'kpisettinggenerate',
        component: KpiSettingGenerate,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },

    {
        path: '/:lang/kpisetting/generatestaff',
        name: 'kpisettinggeneratestaff',
        component: KpiSettingGenerateStaff,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },

    {
        path: '/:lang/kpisetting/staff/:id',
        name: 'kpisettingstaff',
        component: KpiSettingStaff,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },

    {
        path: '/:lang/kpisetting/staff/exercise/:id',
        name: 'kpisettingstaffexercise',
        component: KpiSettingStaffExercise,
        meta: {
            requiresAuth: false,
            funcname: "Kpi Setting"
        }
    },


    {
        path: "/:lang/miscellaneousexport",
        name: "monthlymiscellaneousexport",
        component: MonthlyMiscellaneousExport,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Miscellaneous Export"
        }
    },

    {
        path: "/:lang/miscellaneousexport/param/:id/:item",
        name: "monthlymiscellaneousexportparam",
        component: MonthlyMiscellaneousExportParam,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Miscellaneous Export"
        }
    },

    {
        path: "/:lang/miscellaneousexport/log",
        name: "monthlymiscellaneousexportlog",
        component: MonthlyMiscellaneousExportLog,
        meta: {
            requiresAuth: true,
            funcname: "Monthly Miscellaneous Export"
        }
    },

    {
        path: "/:lang/workflowmaintenance/list",
        name: "workflowmaintenancelist",
        component: WorkflowMaintenanceList,
        meta: {
            requiresAuth: true,
            funcname: "Workflow Maintenance"
        }
    },

    {
        path: "/:lang/workflowmaintenance/edit/:id",
        name: "workflowmaintenanceedit",
        component: WorkflowMaintenanceEdit,
        meta: {
            requiresAuth: true,
            funcname: "Workflow Maintenance"
        }
    },

    {
        path: "/:lang/workflowmaintenance/new",
        name: "workflowmaintenancenew",
        component: WorkflowMaintenanceNew,
        meta: {
            requiresAuth: true,
            funcname: "Workflow Maintenance"
        }
    },

    {
        path: "/:lang/workflowmaintenancebatch/list",
        name: "workflowmaintenancebatch",
        component: WorkflowMaintenanceBatch,
        meta: {
            requiresAuth: true,
            funcname: "Workflow Maintenance Batch"
        }
    },

    {
        path: "/:lang/ircasemaintenance/list",
        name: "ircasemaintenancelist",
        component: IRCaseMaintenanceList,
        meta: {
            requiresAuth: true,
            funcname: "IR Case Maintenance"
        }
    },

    {
        path: "/:lang/ircasemaintenance/edit/:id",
        name: "ircasemaintenanceedit",
        component: IRCaseMaintenanceEdit,
        meta: {
            requiresAuth: true,
            funcname: "IR Case Maintenance"
        }
    },

    {
        path: "/:lang/ircasemaintenance/new",
        name: "ircasemaintenancenew",
        component: IRCaseMaintenanceNew,
        meta: {
            requiresAuth: true,
            funcname: "IR Case Maintenance"
        }
    },


    {
        path: "/:lang/casesubmaintenance/list",
        name: "casesubmaintenancelist",
        component: CaseSubjectMaintenanceList,
        meta: {
            requiresAuth: true,
            funcname: "Case Subject Maintenance"
        }
    },

    {
        path: "/:lang/casesubmaintenance/edit/:id",
        name: "casesubmaintenanceedit",
        component: CaseSubjectMaintenanceEdit,
        meta: {
            requiresAuth: true,
            funcname: "Case Subject Maintenance"
        }
    },

    {
        path: "/:lang/casesubmaintenance/new",
        name: "casesubmaintenancenew",
        component: CaseSubjectMaintenanceNew,
        meta: {
            requiresAuth: true,
            funcname: "Case Subject Maintenance"
        }
    },

    // Staff Enquiry

    {
        path: "/:lang/staffenquiryparameter",
        name: "staffenquiryparameter",
        component: StaffEnquiryParameter,
        meta: {
            requiresAuth: true,
            funcname: "Staff Enquiry"
        }
    },

    {
        path: "/:lang/staffenquiry/:asatdate",
        name: "staffenquirydetails",
        component: StaffEnquiryDetails,
        meta: {
            requiresAuth: true,
            funcname: "Staff Enquiry"
        }
    },

    // Claim Enquiry

    {
        path: "/:lang/claimenquiryparameter",
        name: "claimenquiryparameter",
        component: ClaimEnquiryParameter,
        meta: {
            requiresAuth: true,
            funcname: "Claim Enquiry"
        }
    },

    {
        path: "/:lang/claimenquiry/:asatdate",
        name: "claimenquirydetails",
        component: ClaimEnquiryDetails,
        meta: {
            requiresAuth: true,
            funcname: "Claim Enquiry"
        }
    },

    // Leave Enquiry

    {
        path: "/:lang/leaveenquiryparameter",
        name: "leaveenquiryparameter",
        component: LeaveEnquiryParameter,
        meta: {
            requiresAuth: true,
            funcname: "Leave Enquiry"
        }
    },

    {
        path: "/:lang/leaveenquiry/:asatdate",
        name: "leaveenquirydetails",
        component: LeaveEnquiryDetails,
        meta: {
            requiresAuth: true,
            funcname: "Leave Enquiry"
        }
    },


    {
        path: "/:lang/payrollenquiry/filter",
        name: "payrollenquiryfilter",
        component: PayrollEnquiryFilter,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Enquiry"
        }
    },

    {
        path: "/:lang/payrollenquiry/detail",
        name: "payrollenquirydetail",
        component: PayrollEnquiryDetail,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Enquiry"
        }
    },

    {
        path: "/:lang/payrollenquirybysm/filter",
        name: "payrollenquirybysmfilter",
        component: PayrollEnquiryBySmFilter,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Enquiry by Service Movement"
        }
    },

    {
        path: "/:lang/payrollenquirybysm/detail",
        name: "payrollenquirybysmdetail",
        component: PayrollEnquiryBySmDetail,
        meta: {
            requiresAuth: true,
            funcname: "Payroll Enquiry by Service Movement"
        }
    },

    {
        path: "/:lang/attendanceenquiry/filter",
        name: "attendanceenquiryfilter",
        component: AttendanceEnquiryFilter,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Enquiry"
        }
    },

    {
        path: "/:lang/attendanceenquiry/detail",
        name: "attendanceenquirydetail",
        component: AttendanceEnquiryDetail,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Enquiry"
        }
    },

    {
        path: "/:lang/attendancesummarybystaff/filter",
        name: "attendancesummarybystafffilter",
        component: AttendanceSummaryByStaffFilter,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Summary by Staff"
        }
    },

    {
        path: "/:lang/attendancesummarybystaff/detail",
        name: "attendancesummarybystaffdetail",
        component: AttendanceSummaryByStaffDetail,
        meta: {
            requiresAuth: true,
            funcname: "Attendance Summary by Staff"
        }
    },

    {
        path: "/:lang/processloglisting/all",
        name: "processloglisting",
        component: ProcessLogListing,
        meta: {
            requiresAuth: true,
            funcname: "Process Log Listing"
        }
    },

    {
        path: "/:lang/eissocsubmissionrecord/list",
        name: "eissocsublist",
        component: eissocsubList,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO & EIS Submission Record"
        }
    },

    {
        path: "/:lang/processloglisting/detail/:id",
        name: "processloglistingdetail",
        component: ProcessLogListingDetail,
        meta: {
            requiresAuth: true,
            funcname: "Process Log Listing"
        }
    },

    {
        path: "/:lang/eissocsubmissionrecord/edit/:id",
        name: "eissocsubedit",
        component: eissocsubEdit,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO & EIS Submission Record"
        }
    },

    {
        path: "/:lang/eissocsubmissionrecord/new",
        name: "eissocsubnew",
        component: eissocsubNew,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO & EIS Submission Record"
        }
    },

    {
        path: "/:lang/monthlyeissocsoexport",
        name: "monthlyeissocsoexport",
        component: MonthlyEISSocsoExport,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO & EIS Export"
        }
    },

    {
        path: "/:lang/monthlyeissocsoexport/param/:id/:item",
        name: "monthlyeissocsoexportparam",
        component: MonthlyEISSocsoExportParam,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO & EIS Export"
        }
    },

    {
        path: "/:lang/monthlyeissocsoexport/log",
        name: "monthlyeissocsoexportlog",
        component: MonthlyEISSocsoExportLog,
        meta: {
            requiresAuth: true,
            funcname: "Monthly SOCSO & EIS Export"
        }
    },

    {
        path: "/:lang/staffpayrolldirectassignmentenquiry",
        name: "staffpayrolldirectassignmentenquiry",
        component: StaffPayrollDirectAssignmentEnquiryparameter,
        meta: {
            requiresAuth: true,
            funcname: "Staff Payroll Direct Assignment Enquiry"
        }
    },

    {
        path: "/:lang/staffpayrolldirectassignment/:asatdate",
        name: "staffpayrolldirectassignmentenquirydetail",
        component: StaffPayrollDirectAssignmentEnquirydetail,
        meta: {
            requiresAuth: true,
            funcname: "Staff Payroll Direct Assignment Enquiry"
        }
    },

    {
        path: "/:lang/organizationchartfilter",
        name: "orgchartfilter",
        component: OrgChartFilter,
        meta: {
            requiresAuth: true,
            funcname: "Organization Chart"
        }
    },

    {
        path: "/:lang/organizationchartdetails/:positionid/:asatdate",
        name: "orgchartdetails",
        component: OrgChartDetails,
        meta: {
            requiresAuth: true,
            funcname: "Organization Chart"
        }
    },

    {
        path: "/:lang/systemlogo",
        name: "systemlogo",
        component: SystemLogo
        // meta: {
        //     requiresAuth: false,
        //     funcname: "System Logo"
        // }
    },

    {
        path: "/:lang/qualification/list",
        name: "qualificationlist",
        component: QualificationList,
        meta: {
            requiresAuth: true,
            funcname: "Qualification Record"
        }
    },

    {
        path: "/:lang/qualification/edit/:id",
        name: "qualificationedit",
        component: QualificationEdit,
        meta: {
            requiresAuth: true,
            funcname: "Qualification Record"
        }
    },

    {
        path: "/:lang/qualification/new",
        name: "qualificationnew",
        component: QualificationNew,
        meta: {
            requiresAuth: true,
            funcname: "Qualification Record"
        }
    },

    {
        path: "/:lang/selfservice/home",
        name: "selfservicehome",
        component: SelfServiceHome,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: "/:lang/selfservice/mytask",
        name: "selfservicemytask",
        component: SelfServiceMyTask,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: "/:lang/selfservice/leaveapproval",
        name: "selfserviceleaveapproval",
        component: SelfServiceLeaveApproval,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: "/:lang/selfservice/claimapproval",
        name: "selfserviceclaimapproval",
        component: SelfServiceClaimApproval,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: "/:lang/selfservice/claimapprovaldetails",
        name: "selfserviceclaimapprovaldetails",
        component: SelfServiceClaimApprovalDetails,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: "/:lang/edoc/list",
        name: "edoclist",
        component: EdocList,
        meta: {
            requiresAuth: true,
            funcname: "Edocument Info"
        }
    },

    {
        path: "/:lang/edoc/new",
        name: "edocnew",
        component: EdocNew,
        meta: {
            requiresAuth: true,
            funcname: "Edocument Info"
        }
    },

    {
        path: "/:lang/edoc/edit/:id",
        name: "edocedit",
        component: EdocEdit,
        meta: {
            requiresAuth: true,
            funcname: "Edocument Info"
        }
    },

    {
        path: "/:lang/intgsetup/list",
        name: "intgsetuplist",
        component: intgsetupList,
        meta: {
            requiresAuth: true,
            funcname: "Integration Setup"
        }
    },

    {
        path: "/:lang/intgsetup/new",
        name: "intgsetupnew",
        component: intgsetupNew,
        meta: {
            requiresAuth: true,
            funcname: "Integration Setup"
        }
    },

    {
        path: "/:lang/intgsetup/edit/:id",
        name: "intgsetupedit",
        component: intgsetupEdit,
        meta: {
            requiresAuth: true,
            funcname: "Integration Setup"
        }
    },


//     path: "/:lang/domesticinquiry/edit/:id",
//     name: "domesticinquiryedit",
//     component: IRDomesticInquiryEdit,
//     meta: {
//         requiresAuth: true,
//         funcname: "Domestic Inquiry (DI) Maintenance"
//     }
// },

// {
//     path: "/:lang/domesticinquiry/new",
//     name: "domesticinquirynew",
//     component: IRDomesticInquiryNew,
//     meta: {
//         requiresAuth: true,
//         funcname: "Domestic Inquiry (DI) Maintenance"
//     }
// }

    {
        path: "/:lang/mmssbsetup/list",
        name: "mmssbsetuplist",
        component: mmssbsetupList,
        meta: {
            requiresAuth: true,
            funcname: "SSB Setup"
        }
    },

{
    path: "/:lang/mmssbsetup/edit/:id",
    name: "mmssbsetupedit",
    component: mmssbsetupEdit,
    meta: {
        requiresAuth: true,
        funcname: "SSB Setup"
    }
},

{
    path: "/:lang/mmssbsetup/new",
    name: "mmssbsetupnew",
    component: mmssbsetupNew,
    meta: {
        requiresAuth: true,
        funcname: "SSB Setup"
    }
},
{
    path: "/:lang/mmtaxratesetup/list",
    name: "mmtaxratesetuplist",
    component: mmtaxratesetupList,
    meta: {
        requiresAuth: true,
        funcname: "Tax Rate Setup"
    }
},

{
    path: "/:lang/mmtaxratesetup/edit/:id",
    name: "mmtaxratesetupedit",
    component: mmtaxratesetupEdit,
    meta: {
        requiresAuth: true,
        funcname: "Tax Rate Setup"
    }
},

{
    path: "/:lang/mmtaxratesetup/new",
    name: "mmtaxratesetupnew",
    component: mmtaxratesetupNew,
    meta: {
        requiresAuth: true,
        funcname: "Tax Rate Setup"
    }
},
{
    path: "/:lang/payrollauditexport",
    name: "payrollauditexport",
    component: PayrollAuditExport,
    meta: {
        requiresAuth: true,
        funcname: "Payroll Audit Export"
    }
},

{
    path: "/:lang/mmtaxperiodsetup/list",
    name: "mmtaxperiodsetuplist",
    component: MMTaxPeriodSetupList,
    meta: {
        requiresAuth: true,
        funcname: "Tax Period Setup"
    }
},

{
    path: "/:lang/mmtaxperiodsetup/edit/:id",
    name: "mmtaxperiodsetupedit",
    component: MMTaxPeriodSetupEdit,
    meta: {
        requiresAuth: true,
        funcname: "Tax Period Setup"
    }
},

{
    path: "/:lang/mmtaxperiodsetup/new",
    name: "mmtaxperiodsetupnew",
    component: MMTaxPeriodSetupNew,
    meta: {
        requiresAuth: true,
        funcname: "Tax Period Setup"
    }
},

{
    path: "/:lang/mmtaxablerebatelimit/list",
    name: "mmtaxablerebatelimitlist",
    component: MMTaxableRebateLimitList,
    meta: {
        requiresAuth: true,
        funcname: "Taxable Rebate Limit"
    }
},

{
    path: "/:lang/mmtaxablerebatelimit/edit/:id",
    name: "mmtaxablerebatelimitedit",
    component: MMTaxableRebateLimitEdit,
    meta: {
        requiresAuth: true,
        funcname: "Taxable Rebate Limit"
    }
},

{
    path: "/:lang/mmtaxablerebatelimit/new",
    name: "mmtaxablerebatelimitnew",
    component: MMTaxableRebateLimitNew,
    meta: {
        requiresAuth: true,
        funcname: "Taxable Rebate Limit"
    }
},

{
    path: "/:lang/mmpersontaxablerebatereg/list",
    name: "mmpersontaxablerebatereglist",
    component: MMPersonTaxableRebateRegistrationList,
    meta: {
        requiresAuth: true,
        funcname: "Person Taxable Rebate Registration"
    }
},

{
    path: "/:lang/mmpersontaxablerebatereg/edit/:id",
    name: "mmpersontaxablerebateregedit",
    component: MMPersonTaxableRebateRegistrationEdit,
    meta: {
        requiresAuth: true,
        funcname: "Person Taxable Rebate Registration"
    }
},

{
    path: "/:lang/mmpersontaxablerebatereg/new",
    name: "mmpersontaxablerebateregnew",
    component: MMPersonTaxableRebateRegistrationNew,
    meta: {
        requiresAuth: true,
        funcname: "Person Taxable Rebate Registration"
    }
},

{
    path: "/:lang/mmpsnstatutory/list",
    name: "mmpsnstatutorylist",
    component: mmpsnstatutoryList,
    meta: {
        requiresAuth: true,
        funcname: "MM Person Statutory Instruction"
    }
},

{
    path: "/:lang/mmpsnstatutory/edit/:id",
    name: "mmpsnstatutoryedit",
    component: mmpsnstatutoryEdit,
    meta: {
        requiresAuth: true,
        funcname: "MM Person Statutory Instruction"
    }
},

{
    path: "/:lang/mmpsnstatutory/new",
    name: "mmpsnstatutorynew",
    component: mmpsnstatutoryNew,
    meta: {
        requiresAuth: true,
        funcname: "MM Person Statutory Instruction"
    }
},
{
    path: "/:lang/mmpsntaxbf/list",
    name: "mmpsntaxbflist",
    component: mmpsntaxbfList,
    meta: {
        requiresAuth: true,
        funcname: "MM Person B/F Tax Info"
    }
},

{
    path: "/:lang/mmpsntaxbf/edit/:id",
    name: "mmpsntaxbfedit",
    component: mmpsntaxbfEdit,
    meta: {
        requiresAuth: true,
        funcname: "MM Person B/F Tax Info"
    }
},

{
    path: "/:lang/mmpsntaxbf/new",
    name: "mmpsntaxbfnew",
    component: mmpsntaxbfNew,
    meta: {
        requiresAuth: true,
        funcname: "MM Person B/F Tax Info"
    }
},

{
        path: "/:lang/domesticinquiry/list",
        name: "domesticinquirylist",
        component: IRDomesticInquiryList,
        meta: {
            requiresAuth: true,
            funcname: "Domestic Inquiry (DI) Maintenance"
        }
    },
    {
        path: '/:lang/mmmonthlytaxsubmissionrecordlist',
        name: 'mmmonthlytaxsubmissionrecordlist',
        component: MMMonthlyTaxSubmissionRecordList,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly Tax Submission Record"
        }
    },
   
    {

    path: "/:lang/domesticinquiry/edit/:id",
    name: "domesticinquiryedit",
    component: IRDomesticInquiryEdit,
    meta: {
        requiresAuth: true,
        funcname: "Domestic Inquiry (DI) Maintenance"
    }
},

{
    path: "/:lang/domesticinquiry/new",
    name: "domesticinquirynew",
    component: IRDomesticInquiryNew,
    meta: {
        requiresAuth: true,
        funcname: "Domestic Inquiry (DI) Maintenance"
    }
},


    {
        path: '/:lang/mmmonthlytaxsubmissionrecordnew',
        name: 'mmmonthlytaxsubmissionrecordnew',
        component: MMMonthlyTaxSubmissionRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly Tax Submission Record"
        }
    },

    {
        path: '/:lang/mmmonthlytaxsubmissionrecordedit/:id',
        name: 'mmmonthlytaxsubmissionrecordedit',
        component: MMMonthlyTaxSubmissionRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly Tax Submission Record"
        }
    },

    {
        path: '/:lang/mmmonthlytaxexport',
        name: 'mmmonthlytaxexport',
        component: MMMonthlyTaxExport,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly Tax Export"
        }
    },

    {
        path: '/:lang/mmmonthlytaxexport/param/:id/:item',
        name: 'mmmonthlytaxexportparam',
        component: MMMonthlyTaxExportParam,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly Tax Export"
        }
    },

    {
        path: '/:lang/mmmonthlytaxexport/log',
        name: 'mmmonthlytaxexportlog',
        component: MMMonthlyTaxExportLog,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly Tax Export"
        }
    },

    {
        path: '/:lang/mmmonthlyssbsubmissionrecordlist',
        name: 'mmmonthlyssbsubmissionrecordlist',
        component: MMMonthlySSBSubmissionRecordList,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly SSB Submission Record"
        }
    },


    {
        path: '/:lang/mmmonthlyssbsubmissionrecordnew',
        name: 'mmmonthlyssbsubmissionrecordnew',
        component: MMMonthlySSBSubmissionRecordNew,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly SSB Submission Record"
        }
    },

    {
        path: '/:lang/mmmonthlyssbsubmissionrecordedit/:id',
        name: 'mmmonthlyssbsubmissionrecordedit',
        component: MMMonthlySSBSubmissionRecordEdit,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly SSB Submission Record"
        }
    },

    {
        path: '/:lang/mmmonthlyssbexport',
        name: 'mmmonthlyssbexport',
        component: MMMonthlySSBExport,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly SSB Export"
        }
    },

    {
        path: '/:lang/mmmonthlyssbexport/param/:id/:item',
        name: 'mmmonthlyssbexportparam',
        component: MMMonthlySSBExportParam,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly SSB Export"
        }
    },

    {
        path: '/:lang/mmmonthlyssbexport/log',
        name: 'mmmonthlyssbexportlog',
        component: MMMonthlySSBExportLog,
        meta: {
            requiresAuth: true,
            funcname: "MM Monthly SSB Export"
        }
    },
    
    
    //mm payslip routes
    {
        path: '/:lang/mmparameterpayslip',
        name: 'mmparameterpayslip',
        component: MMParameterPayslip,
        meta: {
            requiresAuth: true,
            funcname: "Myanmar Payslip"
        }
    },

    //mm payslip routes
    {
        path: '/:lang/mmpayslip/:formData',
        name: 'mmpayslip',
        component: MMPayslip,
        meta: {
            requiresAuth: true,
            funcname: "Myanmar Payslip"
        }
    },
    {
        path: "/:lang/latenessdeductionpolicy/list",
        name: "latenessdeductionpolicylist",
        component: LatenessDeductionPolicyGrid,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Policy"
        }
    },

    {
        path: "/:lang/latenessdeductionpolicy/new",
        name: "latenessdeductionpolicynew",
        component: LatenessDeductionPolicyNew,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Policy"

        }
    },

    {
        path: "/:lang/latenessdeductionpolicy/edit/:id",
        name: "latenessdeductionpolicyedit",
        component: LatenessDeductionPolicyEdit,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Policy"
        }
    },

    {
        path: "/:lang/latenessdeductiongenerate",
        name: "latenessdeductiongenerate",
        component: LatenessDeductionGenerate,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Generate"
        }
    },

    {
        path: "/:lang/latenessdeductiongeneratelog",
        name: "latenessdeductiongeneratelog",
        component: LatenessDeductionGenerateLog,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Generate"
        }
    },

    {
        path: "/:lang/latenessdeductionlisting",
        name: "latenessdeductionlisting",
        component: LatenessDeductionListing,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Listing"
        }
    },

    {
        path: "/:lang/latenessdeductiondetailslisting",
        name: "latenessdeductiondetailslisting",
        component: LatenessDeductionDetailsListing,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Listing"
        }
    },

    {
        path: "/:lang/latenessdeductiondetailsedit",
        name: "latenessdeductiondetailsedit",
        component: LatenessDeductionDetailsEdit,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Listing"
        }
    },
    
    {
        path: "/:lang/latenessdeductionposting",
        name: "latenessdeductionposting",
        component: LatenessDeductionPosting,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Posting"
        }
    },

    {
        path: "/:lang/latenessdeductionpostinglog",
        name: "latenessdeductionpostinglog",
        component: LatenessDeductionPostingLog,
        meta: {
            requiresAuth: true,
            funcname: "Lateness Deduction Posting"
        }
    },

    {
        path: "/:lang/glcodesetup",
        name: "glcodesetup",
        component: GLCodeSetup,
        meta: {
            requiresAuth: true,
            funcname: "GL Code Setup"
        }
    },

    {
        path: "/:lang/glcodemappingpolicy/list",
        name: "glcodemappingpolicylist",
        component: GLCodeMappingPolicyList,
        meta: {
            requiresAuth: true,
            funcname: "GL Code Mapping Policy"
        }
    },

    {
        path: "/:lang/glcodemappingpolicy/new",
        name: "glcodemappingpolicynew",
        component: GLCodeMappingPolicyNew,
        meta: {
            requiresAuth: true,
            funcname: "GL Code Mapping Policy"
        }
    },

    {
        path: "/:lang/glcodemappingpolicy/edit/:id",
        name: "glcodemappingpolicyedit",
        component: GLCodeMappingPolicyEdit,
        meta: {
            requiresAuth: true,
            funcname: "GL Code Mapping Policy"
        }
    },

    {
        path: "/:lang/glcodemappinglisting/list",
        name: "glcodemappinglistinglist",
        component: GLCodeMappingListingList,
        meta: {
            requiresAuth: true,
            funcname: "GL Code Mapping Listing"
        }
    },

    {
        path: "/:lang/glcodemappinglisting/new",
        name: "glcodemappinglistingnew",
        component: GLCodeMappingListingNew,
        meta: {
            requiresAuth: true,
            funcname: "GL Code Mapping Listing"
        }
    },

    {
        path: "/:lang/glcodemappinglisting/edit/:id",
        name: "glcodemappinglistingedit",
        component: GLCodeMappingListingEdit,
        meta: {
            requiresAuth: true,
            funcname: "GL Code Mapping Listing"
        }
    },

    {
        path: "/:lang/glexport/:parameter",
        name: "glexport",
        component: GLExport,
        meta: {
            requiresAuth: true,
            funcname: "GL Export"
        }
    },

    {
        path: "/:lang/glexport/param/:id/:item/:parameter",
        name: "glexportparam",
        component: GLExportParam,
        meta: {
            requiresAuth: true,
            funcname: "GL Export"
        }
    },

    {
        path: "/:lang/glexport/log",
        name: "glexportlog",
        component: GLExportLog,
        meta: {
            requiresAuth: true,
            funcname: "GL Export"
        }
    },

    {
        path: "/:lang/glexport/parameter",
        name: "glexportparameter",
        component: GLExportParameter,
        meta: {
            requiresAuth: true,
            funcname: "GL Export"
        }
    },

];
