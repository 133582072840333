<template>
  <div>
    <v-container>
      <v-form>
        <v-row>
          <v-col>
            <h2 class="pb-5">
              {{ this.parentObject.pageTitle }}
              <span class="page_title_value">{{
                this.parentObject.pageTitleValue
              }}</span>
            </h2>
          </v-col>
        </v-row>

        <v-container
          id="scroll-target"
          class="custom-form overflow-y-auto px-5 pt-6"
        >
          <v-row v-if="!this.formData.lockstaff && this.formData.posted == false">
            <v-col cols="4" sm="10" class="pb-n5 mt-n6">
              <v-autocomplete
                outlined
                :label="this.$t('columns.name')"
                type="text"
                ref="psnid"
                :rules="[rules.required]"
                v-model="formData.staffid"
                dense
                :items="staffDetailsList"
                @input="updateValue"
                @change="changeStaffIDandCompanyHandler(formData.staffid)"
              ></v-autocomplete>
            </v-col>
          </v-row>



           <v-row v-else>
            <v-col cols="4" sm="10" class="pb-n5 mt-n6">
              <v-autocomplete
                outlined
                :label="this.$t('columns.name')"
                type="text"
                ref="psnid"
                v-model="formData.staffid"
                dense
                :items="staffDetailsList"
                readonly
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.staffcode')"
                type="text"
                :rules="[rules.required]"
                v-model="formData.staffcode"
                dense
                readonly
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.company')"
                type="text"
                ref="compnyid"
                v-model="formData.compnyname"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('generateunpaidleaverecord.leavetype')"
                type="text"
                ref="leavecode"
                v-model="formData.leavetypeid"
                dense
                readonly
                :items="leavetypelist"
                append-icon=""
                background-color="#ffeab2"
              ></v-autocomplete>
            </v-col>

            <v-col v-else cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('generateunpaidleaverecord.leavetype')"
                type="text"
                ref="leavecode"
                :rules="[rules.required]"
                v-model="formData.leavetypeid"
                dense
                :items="leavetypelist"
                @input="updateValue"
                @change="changeLeavetype(formData.leavetypeid)"
              ></v-autocomplete>
            </v-col>
            

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.leavecode')"
                type="text"
                ref="leavecode"
                v-model="formData.leavecode"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.startdate')"
                type="date"
                ref="startdate"
                v-model="formData.startdate"
                readonly
                append-icon=""
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>

            <v-col v-else cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.startdate')"
                type="date"
                ref="startdate"
                :rules="[rules.required]"
                v-model="formData.startdate"
                @input="updateValue"
                @change="updateValue"
                dense
              ></v-text-field>
            </v-col>

            <v-col v-if="this.formData.posted == true" cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.enddate')"
                type="date"
                ref="enddate"
                v-model="formData.enddate"
                readonly
                append-icon=""
                background-color="#ffeab2"
                dense
              ></v-text-field>
            </v-col>

            <v-col v-else cols="4" sm="4" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('columns.enddate')"
                type="date"
                ref="enddate"
                :rules="[rules.required]"
                v-model="formData.enddate"
                @input="updateValue"
                @change="updateValue"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-btn
                color="primary"
                style="width: auto; float: right"
                @click="CheckBalance"
              >
              {{$t('button.checkbalance')}}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="10" style="padding-top: 0px">
              <app-table
                v-bind:parentObject="{
                  tabHeader: this.tabHeader,
                  detailItem: this.formData.partialday,
                  startdate: this.formData.startdate,
                  enddate: this.formData.enddate,
                  posted: this.formData.posted
                }"
                v-on:input="addItemHandler"
                v-on:delete="delItemHandler"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" sm="1" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('recleaverec.hours')"
                type="text"
                ref="leavehours"
                v-model="formData.leavehours"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="1" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('recleaverec.days')"
                type="text"
                ref="leavedays"
                v-model="formData.leavedays"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" class="pb-n5 mt-n8">
              <v-btn
                color="primary"
                style="width: auto"
                @click="CheckLeaveDays"
              >
              {{$t('button.checkleavedays')}}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="10" class="pb-n5 mt-n8">
              <div v-if="this.formData.posted == true" class="text-center">
                <v-text-field
                  ref="attach"
                  :label="this.$t('claimrecordlisting.browseforattachment')"
                  outlined
                  v-model="fileHolder"
                  dense
                  readonly
                  append-icon=""
                  background-color="#ffeab2"
                ></v-text-field>
              </div>
              <div v-else class="text-center">
                <v-file-input
                  ref="attach"
                  :label="this.$t('claimrecordlisting.browseforattachment')"
                  outlined
                  v-model="fileHolder"
                  @change="fileToBase64"
                  dense
                ></v-file-input>
              </div>
            </v-col>
          </v-row>

          <div v-if="this.formData.attachment">
            <v-row>
              <v-col cols="4" sm="6" class="pb-8 mt-n5">
                <p>
                  <a
                    v-bind:href="this.formData.attachment"
                    v-bind:download="this.formData.attachment"
                    >{{$t('recleaverec.downloadattach')}}</a
                  >
                  <v-icon
                    small
                    class="ml-3 mr-2"
                    @click="deleteAttachmentHandler"
                  >
                    mdi-delete
                  </v-icon>
                </p>
              </v-col>
            </v-row>
          </div>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="4" sm="10" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('recleaverec.reliefstaff')"
                type="text"
                ref="psnid"
                v-model="formData.reliefstaffid"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
                :items="staffDetailsList"
              ></v-autocomplete>
            </v-col>

            <v-col v-else cols="4" sm="10" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('recleaverec.reliefstaff')"
                type="text"
                ref="psnid"
                :rules="[rules.required]"
                v-model="formData.reliefstaffid"
                dense
                :items="staffDetailsList"
                @input="updateValue"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.reason')"
                type="text"
                ref="leavereasonid"
                v-model="formData.reasonid"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
                :items="leavereasonlist"
              ></v-autocomplete>
            </v-col>

            <v-col v-else cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.reason')"
                type="text"
                ref="leavereasonid"
                :rules="[rules.required]"
                v-model="formData.reasonid"
                dense
                hide-selected
                :items="leavereasonlist"
                @input="updateValue"
                @change="changeleavereason(formData.reasonid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('generateunpaidleaverecord.reasoncode')"
                type="text"
                ref="reasoncode"
                v-model="formData.reasoncode"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>

            <v-col cols="2" sm="2" class="pb-n4 mt-n8">
                            <v-dialog v-model="leavereasonDialog" max-width="500px" persistent>
                                <template v-slot:activator="{ on }">
                                    <v-btn dense v-on="on">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <app-genericform 
                                        v-bind:parentData = "{
                                            tabItems: leavereasonItems,
                                            formTitle: `${this.$t('recleaverec.leavereason')}`,
                                            formId: 'leavereason'
                                        }"

                                        v-on:input="itemData = $event"
                                        v-on:saveLeaveReason="saveLeaveReasonHandler"
                                        v-on:deleteLeaveReason="deleteLeaveReasonHandler"
                                    />

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="warning"
                                            text
                                            @click="leavereasonDialog = false"
                                        >
                                        {{$t('button.close')}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="5" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.status')"
                type="text"
                ref="leavestatus"
                v-model="formData.lvstatusid"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
                :items="leavestatuslist"
              ></v-autocomplete>
            </v-col>

            <v-col v-else cols="5" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('columns.status')"
                type="text"
                ref="leavestatus"
                :rules="[rules.required]"
                v-model="formData.lvstatusid"
                dense
                :items="leavestatuslist"
                @change="changeLeaveStatus(formData.lvstatusid)"
              ></v-autocomplete>
            </v-col>
            <!-- @input="updateValue" -->

            <v-col cols="2" sm="2" class="pb-n5 mt-n8"
              ><v-checkbox
                :label="this.$t('recleaverec.postedtopayroll')"
                v-model="formData.posted"
                @change="changePosted(formData.posted)"
                input-value="true"
                disabled
              >
              </v-checkbox
            ></v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8"
              ><v-checkbox
                :label="this.$t('recleaverec.pastrec')"
                v-model="formData.ishistory"
                @change="updateValue"
                input-value="true"
                :disabled="formData.posted"
              >
              </v-checkbox
            ></v-col>

            <v-col cols="2" sm="2" class="pb-n5 mt-n8"
              ><v-checkbox
                :label="this.$t('recleaverec.emergency')"
                v-model="formData.isemergency"
                @change="updateValue"
                input-value="true"
              >
              </v-checkbox
            ></v-col>
          </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="4" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('recleaverec.apprvlremark')"
                type="text"
                ref="aprvremark"
                v-model="formData.aprvremark"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
                @input="updateValue"
              ></v-text-field>
              <!-- :rules="[rules.required]" -->
            </v-col>

            <v-col v-else cols="4" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('recleaverec.apprvlremark')"
                type="text"
                ref="aprvremark"
                v-model="formData.aprvremark"
                dense
                @input="updateValue"
              ></v-text-field>
              <!-- :rules="[rules.required]" -->
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('panelclinic')"
                type="text"
                ref="cliniccode"
                v-model="formData.clinicid"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
                :items="panelcliniclist"
              ></v-autocomplete>
            </v-col>

            <v-col v-else cols="8" sm="5" class="pb-n5 mt-n8">
              <v-autocomplete
                outlined
                :label="this.$t('panelclinic')"
                type="text"
                ref="cliniccode"
                :rules="[rules.required]"
                v-model="formData.clinicid"
                dense
                :items="panelcliniclist"
                @input="updateValue"
                @change="changePanelClinic(formData.clinicid)"
              ></v-autocomplete>
            </v-col>

            <v-col cols="8" sm="5" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppanelclinic.cliniccode')"
                type="text"
                ref="cliniccode"
                v-model="formData.cliniccode"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="4" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('setuppanelclinic.cliniccode')"
                type="text"
                ref="nonpanelclinic"
                v-model="formData.nonpanelclinic"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
              <!-- :rules="[rules.required]" -->
            </v-col>

            <v-col v-else cols="4" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('recleaverec.non_panelclinic')"
                type="text"
                ref="nonpanelclinic"
                v-model="formData.nonpanelclinic"
                dense
                @input="updateValue"
              ></v-text-field>
              <!-- :rules="[rules.required]" -->
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="this.formData.posted == true" cols="4" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('recleaverec.leaverecremark')"
                type="text"
                ref="lvremark"
                v-model="formData.lvremark"
                dense
                readonly
                append-icon=""
                background-color="#ffeab2"
              ></v-text-field>
              <!-- :rules="[rules.required]" -->
            </v-col>

            <v-col v-else cols="4" sm="10" class="pb-n5 mt-n8">
              <v-text-field
                outlined
                :label="this.$t('recleaverec.leaverecremark')"
                type="text"
                ref="lvremark"
                v-model="formData.lvremark"
                dense
                @input="updateValue"
              ></v-text-field>
              <!-- :rules="[rules.required]" -->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" sm="10" class="pb-n4 mt-n8">
              <div>
                <v-form>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-subheader class="blue--text mt-n3 mb-n3 ml-n2"
                          >{{$t('preapprovedotrecord.entryandapprovalinfo')}}</v-subheader
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          <v-row>
                            <v-col cols="4" sm="10" class="blue--text"
                              >{{$t('recleaverec.leaveentryinfo')}}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('recleaverec.submittedby')}}</v-col
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ formData.entrybyusername }}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('preapprovedotrecord.submissiontime')}}</v-col
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ formData.entrytime }}</v-col
                            >
                          </v-row>
                          <v-row>
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{$t('setuptp1.source')}}</v-col
                            >
                            <v-col
                              cols="4"
                              sm="4"
                              class="mt-n3"
                              style="padding: 8px 12px"
                              >{{ formData.src }}</v-col
                            >
                          </v-row>
                        </div>

                        <div>
                          <v-row>
                            <v-col cols="4" sm="10" class="blue--text"
                              >{{$t('recleaverec.leaveapprvlinfo')}}</v-col
                            >
                          </v-row>
                          <div
                            v-for="(item, index) in leaveApprInfo"
                            :key="index"
                            class="pb-5"
                          >
                            <!-- <v-row>
                              <v-col cols="4" sm="4" class="mt-n3"
                                >Source</v-col
                              >
                              <v-col cols="4" sm="4" class="mt-n3">{{
                                item.
                              }}</v-col>
                            </v-row> -->
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('recleaverec.actiontime')}}</v-col
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.aprdate }}</v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('recleaverec.actby')}}</v-col
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.staffcode }} -
                                {{ item.psnname }}</v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('columns.status')}}</v-col
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.aprstatus }}</v-col
                              >
                            </v-row>
                            <v-row>
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{$t('recleaverec.apprvlremark')}}</v-col
                              >
                              <v-col
                                cols="4"
                                sm="4"
                                class="mt-n3"
                                style="padding: 8px 12px"
                                >{{ item.remark }}</v-col
                              >
                            </v-row>
                          </div>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-container>

        <app-formbuttons
          v-bind:EditBool="this.parentObject.isEdit"
          v-on:NewPressed="newHandler"
          v-on:SavePressed="saveHandler"
          v-on:DeletePressed="deleteHandler"
          v-on:CancelPressed="cancelHandler"
        />
      </v-form>
    </v-container>
    <div v-if="isErrTrigger">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErrTrigger,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>
import ErrorDialog from "../../../components/common/ErrorDialog";
import PartialDayTable from "./PartialDayTable";
import moment from "moment";
import GenericForm from '../../generic/GridForm';

export default {
  props: {
    parentObject: Object,
  },

  components: {
    "app-error": ErrorDialog,
    "app-table": PartialDayTable,
    'app-genericform': GenericForm,
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('reportsparam.required'),
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t('reportsparam.invalidemail');
        },
      },

      tabHeader: [
        { text: this.$t('columns.actions'), value: "action", sortable: false, width: "auto" },
        { text: this.$t('columns.date'), value: "date", width: "auto", sortable: false },
        {
          text: this.$t('reportprocessloglisting.starttime'),
          value: "starttime",
          width: "auto",
          sortable: false,
        },
        { text: this.$t('reportprocessloglisting.endtime'), value: "endtime", width: "auto", sortable: false },
      ],

      formData: {
        id: this.parentObject.data.id,
        staffid: this.parentObject.data.staffid,
        staffcode: this.parentObject.data.staffcode,
        compnyid: this.parentObject.data.compnyid,
        compnyname: this.parentObject.data.compnyname,
        leavetypeid: this.parentObject.data.leavetypeid,
        leavecode: this.parentObject.data.leavecode,
        startdate: this.parentObject.data.startdate,
        enddate: this.parentObject.data.enddate,
        partialday: this.parentObject.data.partialday,
        reasonid: this.parentObject.data.reasonid,
        reasoncode: this.parentObject.data.reasoncode,
        clinicid: this.parentObject.data.clinicid,
        cliniccode: this.parentObject.data.cliniccode,
        reliefstaffid: this.parentObject.data.reliefstaffid,
        lvstatusid: this.parentObject.data.lvstatusid,
        lvstatuscode: this.parentObject.data.lvstatuscode,
        posted: this.parentObject.data.posted,
        attachment: this.parentObject.data.attachment,
        attachname: this.parentObject.data.attachname,
        aprvremark: this.parentObject.data.aprvremark,
        lvremark: this.parentObject.data.lvremark,
        entrytime: this.parentObject.data.entrytime,
        entrybid: this.parentObject.data.entrybid,
        entrybyusername: this.parentObject.data.entrybyusername,
        lockstaff: this.parentObject.data.lockstaff,
        appsrcid: this.parentObject.data.appsrcid,
        src: this.parentObject.data.src,
        leavehours: this.parentObject.data.leavehours,
        leavedays: this.parentObject.data.leavedays,
        nonpanelclinic: this.parentObject.data.nonpanelclinic,
       // leavedate: this.parentObject.data.leavedate,
       // daytypeid: this.parentObject.data.daytypeid,
        leaverecorddetail: this.parentObject.data.leaverecorddetail,
        search: this.parentObject.data.search,
        stamp: this.parentObject.data.stamp,
        userid: this.parentObject.data.userid,
        ishistory: this.parentObject.data.ishistory,
        isemergency: this.parentObject.data.isemergency
      },

      itemData: {
        itemcode: null,
        itemdesc: null,
        itemstamp: 0
      },

      //arrays to store data objects from load list handlers
      leaveApprInfo: [],
      staffdetails: [],
      staffDetailsList: [],
      companies: [],
      companiesitemsList: [],
      leavetype: [],
      leavetypelist: [],
      leavereason: [],
      leavereasonlist: [],
      leavereasonItems: [],
      leavestatus: [],
      leavestatuslist: [],
      panelclinic: [],
      panelcliniclist: [],
    //  normallist: [],
      ndaytype: [],
      subDialog: false,
      leavereasonDialog: false,
      fileHolder: null,
      errMsg: "",
      isErr: false,
      isErrTrigger: false,
      isEdit: this.parentObject.isEdit,
      search: this.parentObject.searchnew
    };
  },

  methods: {
    updateValue() {
      this.$emit("input", {
        id: this.formData.id,
        staffid: this.formData.staffid,
        staffcode: this.formData.staffcode,
        compnyid: this.formData.compnyid,
        compnyname: this.formData.compnyname,
        leavetypeid: this.formData.leavetypeid,
        leavecode: this.formData.leavecode,
        startdate: this.formData.startdate,
        enddate: this.formData.enddate,
        partialday: this.formData.partialday,
        reasonid: this.formData.reasonid,
        reasoncode: this.formData.reasoncode,
        clinicid: this.formData.clinicid,
        cliniccode: this.formData.cliniccode,
        reliefstaffid: this.formData.reliefstaffid,
        lvstatusid: this.formData.lvstatusid,
        lvstatuscode: this.formData.lvstatuscode,
        posted: this.formData.posted,
        attachment: this.formData.attachment,
        attachname: this.formData.attachname,
        aprvremark: this.formData.aprvremark,
        lvremark: this.formData.lvremark,
        entrytime: this.formData.entrytime,
        entrybid: this.formData.entrybid,
        entrybyusername: this.formData.entrybyusername,
        appsrcid: this.formData.appsrcid,
        src: this.formData.src,
        lockstaff: this.formData.lockstaff,
        leavehours: this.formData.leavehours,
        leavedays: this.formData.leavedays,
        nonpanelclinic: this.formData.nonpanelclinic,
        // leavedate: this.formData.leavedate,
        // daytypeid: this.formData.daytypeid,
        leaverecorddetail: this.formData.leaverecorddetail,
        search: this.formData.search,
        stamp: this.formData.stamp,
        userid: this.formData.userid,
        ishistory: this.formData.ishistory,
        isemergency: this.formData.isemergency
      });
      //console.log('leaverecorddetail check', this.formData.leaverecorddetail)
    },
    fileToBase64() {
      if (this.fileHolder) {
        var file = this.$refs.attach.internalValue;
        this.formData.attachname = this.fileHolder.name;

        var reader = new FileReader();

        reader.onloadend = () => {
          //var b64 = reader.result.replace(/^data:.+base64,/, '');
          var b64 = reader.result;
          this.formData.attachment = b64;
          this.updateValue();
        };
        

        reader.readAsDataURL(file);
      }
    },
    //load staffdetails list view
    loadStaffDetailsListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/staff/all/${this.$store.getters.userId}`
      })
        .then((res) => {
          //req success --> push res into array
          vm.staffdetails = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.staffDetailsList.push({
              text: res.data.rec[i].psndetails,
              value: res.data.rec[i].id,
            });

            if (vm.formData.staffid) {
              this.changeStaffIDandCompanyHandler(vm.formData.staffid);
            }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load leavetype list view
    loadLeavetypeListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/leavesetup/all`})
        .then((res) => {
          //req success --> push res into array
          vm.leavetype = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.leavetypelist.push({
              text: res.data.rec[i].leavedesc,
              value: res.data.rec[i].id,
            });

            if (vm.formData.leavetypeid) {
              this.changeLeavetype(vm.formData.leavetypeid);
            }
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    //load leavereason list view
    loadLeaveReasonListHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/leave/reason`})
        .then((res) => {
          //req success --> push res into array
          console.log('res', res.data.rec)
          vm.leavereason = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.leavereasonlist.push({
              text: res.data.rec[i].reasondesc,
              value: res.data.rec[i].id,
            });

            vm.leavereasonItems.push(
              {
                id: res.data.rec[i].id,
                code: res.data.rec[i].reasoncode,
                desc: res.data.rec[i].reasondesc,
                stamp: res.data.rec[i].stamp
              }
              )

          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

      saveLeaveReasonHandler() {
          const vm = this;

            if (this.itemData.itemid) {
                this.$store.dispatch('put', {
                  url: '/leavereason/' + this.itemData.itemid, 
                  payload: {
                    reasoncode: this.itemData.itemcode,
                    reasondesc: this.itemData.itemdesc,
                    stamp: this.itemData.itemstamp,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.leavereason = [];
                        vm.leavereasonItems = [];
                        vm.leavereasonlist = [];

                        this.loadLeaveReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            } else {
                this.$store.dispatch('post', {
                  url: '/leavereason/create', 
                  payload: {
                    reasoncode: this.itemData.itemcode,
                    reasondesc: this.itemData.itemdesc,
                    userid: this.formData.userid,
                }})
                .then(res => {
                    if (res.data.status == 201) {
                        // resets all arrays
                        vm.leavereason = [];
                        vm.leavereasonItems = [];
                        vm.leavereasonlist = [];

                        this.loadLeaveReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });
            }
        },

         deleteLeaveReasonHandler($event) {
            const vm = this;

            if (confirm('Remove ' + $event.desc + '?')) {
                this.$store.dispatch('delete', {
                  url: '/leavereason/' + $event.id + '/' + this.formData.userid})
                .then(res => {
                    if (res.data.status == 200) {
                        // resets all arrays
                        vm.leavereason = [];
                        vm.leavereasonItems = [];
                        vm.leavereasonlist = [];

                        this.loadLeaveReasonListHandler();
                    } else {
                        vm.isErr = true;
                        vm.errMsg = res.data.error;
                        vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                    }
                })
                .catch(err => {
                    vm.isErr = true;
                    vm.errMsg = err;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                });                
            }

        },

         genLeaveReasonTabItems() {
            for (let i = 0; i < this.leavereason.length; i++) {
                this.leavereasonItems.push(
                    {
                        id: this.leavereason[i].id,
                        code: this.leavereason[i].reasoncode,
                        desc: this.leavereason[i].reasondesc,
                        stamp: this.leavereason[i].stamp
                    }
                )
            }
        },

    loadLeaveStatusListHandler() {
      const vm = this;
      //console.log(vm.formData.lvstatusid);
      this.$store.dispatch('get', {
        url: `/leave/status`})
        .then((res) => {
          //req success --> push res into array
          vm.leavestatus = res.data.rec;
          console.log(res);

          for (let i = 0; i < res.data.rec.length; i++) {
            if (
              res.data.rec[i].transtatus === "APPROVED" &&
              !vm.formData.lvstatusid
            ) {
              this.formData.lvstatusid = res.data.rec[i].id;
            }

            vm.leavestatuslist.push({
              text: res.data.rec[i].transtatus,
              value: res.data.rec[i].id,
            });
          }
          if (vm.formData.lvstatusid) {
            this.changeLeaveStatus(vm.formData.lvstatusid);
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    loadPanelClinicHandler() {
      const vm = this;
      this.$store.dispatch('get', {
        url: `/clinic/all`})
        .then((res) => {
          //req success --> push res into array
          vm.panelclinic = res.data.rec;

          for (let i = 0; i < res.data.rec.length; i++) {
            vm.panelcliniclist.push({
              text: res.data.rec[i].clinicdesc,
              value: res.data.rec[i].id,
            });
          }
        })
        .catch((err) => {
          //req failure --> emit error
          vm.isErr = true;
          vm.errMsg = err;
          vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
        });
    },

    CheckLeaveDays() {
      const vm = this;
      //console.log('leavetype', this.leavetype);

        // console.log('1233')
        // console.log('this.formData.staffid', this.formData.staffid)
        // console.log('this.formData.startdate', this.formData.startdate)
        // console.log('this.formData.leavetypeid', this.formData.leavetypeid)
      if (
        this.formData.staffid &&
        this.formData.startdate &&
        this.formData.enddate &&
        this.formData.leavetypeid
      ) {
        var data = {
          startdate: this.formData.startdate,
          enddate: this.formData.enddate,
          leavetypeid: this.formData.leavetypeid,
          staffid: this.formData.staffid,
          userid: this.$store.getters.userId,
        };
        this.$store.dispatch('post', {
          url: `/leave/leaveday`, 
          payload: data})
          .then((res) => {
            //req success --> push res into array
            console.log(res.data.rec);
            if (res.data.rec.leaveday) {
              let leaveitem = this.leavetype.find(
                (item) => item.id === res.data.rec.leavetypeid
              );

            //   let item2 = res.data;
            //   // let leaverange = [];
            //   // let leavedaytype = [];

            //  // console.log('item2', item2)


            //    item2.rec.normalday= item2.rec.normalday.filter( val => {
            //              return data.startdate <= moment(val).format('YYYY-MM-DD') 
            //              && data.enddate >= moment(val).format('YYYY-MM-DD')
            //          })

            //       //  console.log('item2.rec.holiday', item2.rec.holiday.length)
                
            //     item2.rec.restday= item2.rec.restday.filter( val => {
            //              return data.startdate <= moment(val).format('YYYY-MM-DD') 
            //              && data.enddate >= moment(val).format('YYYY-MM-DD')
            //          })

            //      item2.rec.offday= item2.rec.offday.filter( val => {
            //              return data.startdate <= moment(val).format('YYYY-MM-DD') 
            //              && data.enddate >= moment(val).format('YYYY-MM-DD')
            //          })

            //      for (let j = 0; j < item2.rec.holiday.length; j++){
            //       let daytypeid = '3';
            //       this.formData.leaverecorddetail.push({
            //             range: item2.rec.holiday[j].holdate,
            //             daytype: daytypeid
            //             })
            //       // range.push(item2.rec.holiday[j].holdate) 
            //       // daytype.push(daytypeid[j]);
            //       }
                  
                     
            //          if (item2.rec.normalday.length > 0){
            //           let daytypeid = '4';

            //           for(let i = 0; i < item2.rec.normalday.length; i++){
            //            this.formData.leaverecorddetail.push({
            //             range: item2.rec.normalday[i],
            //             daytype: daytypeid
            //             })
            //           }   
            //          }

            //         if (item2.rec.restday.length > 0){
            //         let daytypeid = '2';
            //          for(let i = 0; i < item2.rec.restday.length; i++){

            //           this.formData.leaverecorddetail.push({
            //             range: item2.rec.restday[i],
            //             daytype: daytypeid
            //             })
            //           }
                        
            //          }

            //         if (item2.rec.offday.length > 0){

            //         let daytypeid = '1';
            //          for(let i = 0; i < item2.rec.offday.length; i++){
            //             this.formData.leaverecorddetail.push({
            //             range: item2.rec.offday[i],
            //             daytype: daytypeid
            //             })
            //           }
                        
            //          }
            //       //  for(let i = 0; i < this.normallist.length; i++){
            //       //     leaverange.push(this.normallist[i].range),
            //       //     leavedaytype.push(this.normallist[i].daytype)
                  
            //       //     }

            //     //    this.formData.leavedate = leaverange;
            //     //    this.formData.daytypeid = leavedaytype;


            //     // console.log('range', leaverange)
            //     // console.log('daytype', leavedaytype)

              let remaining_hours = 0;
              let hours = 0;
              if (leaveitem) {
                for (let i = 0; i < this.formData.partialday.length; i++) {
                  remaining_hours +=
                    parseInt(leaveitem.hrperday) -
                    parseInt(this.formData.partialday[i].partialqty);

                  hours += parseInt(this.formData.partialday[i].partialqty);
                }
                console.log(hours);
                let leavehour = remaining_hours / leaveitem.hrperday;
                this.formData.leavehours =
                  leaveitem.hrperday * (res.data.rec.leaveday - leavehour);
                this.formData.leavedays = res.data.rec.leaveday - leavehour;
              } else {
                for (let i = 0; i < this.formData.partialday.length; i++) {
                  remaining_hours +=
                    8 - parseInt(this.formData.partialday[i].partialqty);

                  hours += parseInt(this.formData.partialday[i].partialqty);
                }
                console.log('hours', hours);
                let leavehour = remaining_hours / leaveitem.hrperday;
                this.formData.leavehours =
                  8 * (res.data.rec.leaveday - leavehour);
                this.formData.leavedays = res.data.rec.leaveday;
              }
              this.updateValue();
            //  this.$emit("save");
            } else {
              this.$dialog.alert(this.$t('vdatatable.norecordsfound')).then((dialog) => {
                console.log(dialog);
              });
            }

          })
          .catch((err) => {
            //req failure --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

     CheckLeaveDaysNew() {
      const vm = this;
      //console.log('leavetype', this.leavetype);

        // console.log('1233')
        // console.log('this.formData.staffid', this.formData.staffid)
        // console.log('this.formData.startdate', this.formData.startdate)
        // console.log('this.formData.leavetypeid', this.formData.leavetypeid)
      if (
        this.formData.staffid &&
        this.formData.startdate &&
        this.formData.enddate &&
        this.formData.leavetypeid
      ) {
        var data = {
          startdate: this.formData.startdate,
          enddate: this.formData.enddate,
          leavetypeid: this.formData.leavetypeid,
          staffid: this.formData.staffid,
          userid: this.$store.getters.userId,
        };
        this.$store.dispatch('post', {
          url: `/leave/leaveday`, 
          payload: data})
          .then((res) => {
            //req success --> push res into array
            console.log(res.data.rec);
            if (res.data.rec.leaveday) {
              let leaveitem = this.leavetype.find(
                (item) => item.id === res.data.rec.leavetypeid
              );

              let item2 = res.data;
              // let leaverange = [];
              // let leavedaytype = [];

              console.log('item2', item2)


               item2.rec.normalday= item2.rec.normalday.filter( val => {
                         return data.startdate <= moment(val).format('YYYY-MM-DD') 
                         && data.enddate >= moment(val).format('YYYY-MM-DD')
                     })

                  //  console.log('item2.rec.holiday', item2.rec.holiday.length)
                
                item2.rec.restday= item2.rec.restday.filter( val => {
                         return data.startdate <= moment(val).format('YYYY-MM-DD') 
                         && data.enddate >= moment(val).format('YYYY-MM-DD')
                     })

                 item2.rec.offday= item2.rec.offday.filter( val => {
                         return data.startdate <= moment(val).format('YYYY-MM-DD') 
                         && data.enddate >= moment(val).format('YYYY-MM-DD')
                     })

                 for (let j = 0; j < item2.rec.holiday.length; j++){
                  let daytypeid = '3';
                  this.formData.leaverecorddetail.push({
                        range: item2.rec.holiday[j].holdate,
                        daytype: daytypeid
                        })
                  // range.push(item2.rec.holiday[j].holdate) 
                  // daytype.push(daytypeid[j]);
                  }
                  
                     
                     if (item2.rec.normalday.length > 0){
                      let daytypeid = '4';

                      for(let i = 0; i < item2.rec.normalday.length; i++){
                       this.formData.leaverecorddetail.push({
                        range: item2.rec.normalday[i],
                        daytype: daytypeid
                        })
                      }   
                     }

                    if (item2.rec.restday.length > 0){
                    let daytypeid = '2';
                     for(let i = 0; i < item2.rec.restday.length; i++){

                      this.formData.leaverecorddetail.push({
                        range: item2.rec.restday[i],
                        daytype: daytypeid
                        })
                      }
                        
                     }

                    if (item2.rec.offday.length > 0){

                    let daytypeid = '1';
                     for(let i = 0; i < item2.rec.offday.length; i++){
                        this.formData.leaverecorddetail.push({
                        range: item2.rec.offday[i],
                        daytype: daytypeid
                        })
                      }
                        
                     }
                  //  for(let i = 0; i < this.normallist.length; i++){
                  //     leaverange.push(this.normallist[i].range),
                  //     leavedaytype.push(this.normallist[i].daytype)
                  
                  //     }

                //    this.formData.leavedate = leaverange;
                //    this.formData.daytypeid = leavedaytype;


                // console.log('range', leaverange)
                // console.log('daytype', leavedaytype)

              let remaining_hours = 0;
              let hours = 0;
              if (leaveitem) {
                for (let i = 0; i < this.formData.partialday.length; i++) {
                  remaining_hours +=
                    parseInt(leaveitem.hrperday) -
                    parseInt(this.formData.partialday[i].partialqty);

                  hours += parseInt(this.formData.partialday[i].partialqty);
                }
                console.log(hours);
                let leavehour = remaining_hours / leaveitem.hrperday;
                this.formData.leavehours =
                  leaveitem.hrperday * (res.data.rec.leaveday - leavehour);
                this.formData.leavedays = res.data.rec.leaveday - leavehour;
              } else {
                for (let i = 0; i < this.formData.partialday.length; i++) {
                  remaining_hours +=
                    8 - parseInt(this.formData.partialday[i].partialqty);

                  hours += parseInt(this.formData.partialday[i].partialqty);
                }
                console.log('hours', hours);
                let leavehour = remaining_hours / leaveitem.hrperday;
                this.formData.leavehours =
                  8 * (res.data.rec.leaveday - leavehour);
                this.formData.leavedays = res.data.rec.leaveday;
              }
              this.updateValue();
              this.$emit("save");
            } else {
              this.$dialog.alert(this.$t('vdatatable.norecordsfound')).then((dialog) => {
                console.log(dialog);
              });
            }

          })
          .catch((err) => {
            //req failure --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },


    CheckBalance() {
      const vm = this;
  
      if (
        this.formData.staffid &&
        this.formData.startdate &&
        this.formData.leavetypeid
      ) {
        let item = this.leavetype.find(
          (item) => item.id === this.formData.leavetypeid
        );

        var data = {
          asofdate: this.formData.startdate,
          leavetypeid: this.formData.leavetypeid,
          staffid: this.formData.staffid,
          userid: this.$store.getters.userId,
        };
        this.$store.dispatch('post', {
          url: `/leave/balance`, 
          payload: data})
          .then((res) => {
            //req success --> push res into array
            console.log(res.data.rec);
            // if (res.data.rec.includes('do not have group assignment')) {
            //    this.$dialog
            //     .alert(
            //       `Staff ${res.data.rec[0].staffcode} do not have group assignment.`
            //     )
            //     .then((dialog) => {
            //       console.log(dialog);
            //     });
            // } 
            console.log('res.data.rec', res.data.rec)
            if (res.data.rec.length > 0 && res.data.rec.includes('do not have group assignment')) {
              this.$dialog
                .alert(
                  `${this.$t('columns.staff')} ${this.formData.staffcode} ${this.$t('donothavegrpassign')}`
                )
                .then((dialog) => {
                  console.log(dialog);
                });  

            } else if (res.data.rec.length > 0 ) {
              this.$dialog
                .alert(
                  `${this.$t('allowedtotake')} ${res.data.rec[0].allowed_take} ${item.unittype}.`
                )
                .then((dialog) => {
                  console.log(dialog);
                });

          
            }else {
              this.$dialog.alert(this.$t('vdatatable.norecordsfound')).then((dialog) => {
                console.log(dialog);
              });
            }
          })
          .catch((err) => {
            //req failure --> emit error
            vm.isErr = true;
            vm.errMsg = err;
            vm.$emit("error", { isErr: vm.isErr, errMsg: vm.errMsg });
          });
      }
    },

    changeLeavetype(id) {
      //const vm = this;
      let item = this.leavetype.find((item) => item.id === id);
      this.formData.leavetypeid = item.id;
      this.formData.leavecode = item.leavecode;

      if (this.formData.leavedays) {
        if (item.unittype == "Hour") {
          this.formData.leavehours = this.formData.leavedays;
          this.formData.leavedays = 0;

          //vm.CheckLeaveDays();
        }
      }
    },

    changeleavereason(id) {
      let item = this.leavereason.find((item) => item.id === id);
      this.formData.reasonid = item.id;
      this.formData.reasoncode = item.reasoncode;
    },

    changePanelClinic(id) {
      let item = this.panelclinic.find((item) => item.id === id);
      this.formData.clinicid = item.id;
      this.formData.cliniccode = item.cliniccode;
    },

    changeLeaveStatus(id) {
      let item = this.leavestatus.find((item) => item.id === id);
      this.formData.lvstatusid = item.id;
      this.formData.lvstatuscode = item.transtatus;
      this.updateValue();
    },

    //change staff id and company after selecting psnnid
    changeStaffIDandCompanyHandler(id) {
      let item = this.staffdetails.find((item) => item.id === id);

      //set psnid to psnid and staffid to staffid
      this.formData.psnid = item.psnid;
      this.formData.staffid = item.id;
      this.formData.staffcode = item.staffcode;
      this.formData.compnyid = item.compnyid;
      this.formData.compnyname = item.compnyname;

      this.updateValue();
    },

    addItemHandler($event) {
      // console.log($event);
      this.formData.partialday = $event;
      // let hours = 0;
      // for (var i = 0; i < this.formData.partialday.length; i++) {
      //   hours += parseInt(this.formData.partialday[i].partialqty);
      // }
      // this.formData.leavehours = hours;
      // this.formData.leavedays = parseInt(hours / 8);
    },

    delItemHandler($event) {
      this.formData.partialday = $event;
      // let hours = 0;
      // for (var i = 0; i < this.formData.partialday.length; i++) {
      //   hours += parseInt(this.formData.partialday[i].partialqty);
      // }
      // this.formData.leavehours = hours;
      // this.formData.leavedays = parseInt(hours / 8);
    },

    deleteAttachmentHandler() {
      this.formData.attachment = "";
      this.formData.attachname = "";
      this.updateValue();
    },

    newHandler() {
      this.$emit("new");
    },

    saveHandler() {
      //this.$emit("save");
      this.CheckLeaveDaysNew();
    },

    deleteHandler() {
      this.$emit("delete");
    },

    cancelHandler() {
      this.$router.push({ name: "leaverecordlist", params: { search: this.search} });
    },

    loadLeaveAprInfo() {
      if (this.formData.id) {
        this.$store.dispatch('get', {
          url: "/leaverecord/wftran/" + this.formData.id})
          .then((res) => {
            this.leaveApprInfo = res.data.rec;
            console.log('leaveApprInfo', this.leaveApprInfo)
            
            if (this.leaveApprInfo.length > 0) {
              for (var i = 0; i < this.leaveApprInfo.length; i++) {
                if (this.leaveApprInfo[i].aprdate) {
                  this.leaveApprInfo[i].aprdate = moment(
                    this.leaveApprInfo[i].aprdate
                  ).format("YYYY-MM-DD HH:mm:ss");
                }
              }
            }
          });
      }
    },
  },

  mounted() {
    this.loadStaffDetailsListHandler();
    this.loadLeavetypeListHandler();
    this.loadLeaveReasonListHandler();
    this.loadLeaveStatusListHandler();
    this.loadPanelClinicHandler();
    this.loadLeaveAprInfo();

      if (this.parentObject.isEdit){
        this.duplicateStaffData = this.formData;
        
        if (this.parentObject.data.lockstaff == "N") {
            this.formData.lockstaff = false;
        } else {
            this.formData.lockstaff = true;
        }
      }
      
    //  console.log('leaverecorddetail check', this.formData.leaverecorddetail)
  },
};
</script>

<!-- numberfield style class to remove buttons in number type field  -->
<style>
.numberfield input[type="number"] {
  -moz-appearance: textfield;
}
.numberfield input::-webkit-outer-spin-button,
.numberfield input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>