<template>
    <div>
        <v-container>
            <v-form ref="form">
                <v-row>
                    <h2 class="pb-5">{{ this.parentObject.pageTitle }}<span class="page_title_value">{{ this.parentObject.pageTitleValue }}</span></h2>
                </v-row>
                
                <v-container
                    id="scroll-target"
                    class="custom-form overflow-y-auto px-5"
                >

                <v-card>
                    <div class="ma-4">
                        <v-subheader class="blue--text">{{$t('reportsparam.parameters')}}</v-subheader>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n5">
                            <v-autocomplete
                                outlined
                                :label="this.$t('columns.type')"
                                type="text"
                                :items="typeList"
                                v-model="formData.type"
                                @input="updateValue"
                                dense
                            ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                <v-text-field
                                outlined
                                :label="this.$t('columns.fromdate')"
                                type="date"
                                ref="fromdate"
                                :rules="[rules.required]"
                                v-model="formData.fromdate"
                                dense
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" class="pb-n4 mt-n8">
                                <v-text-field
                                outlined
                                :label="this.$t('columns.untildate')"
                                type="date"
                                ref="untildate"
                                :rules="[rules.required]"
                                v-model="formData.untildate"
                                dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>

                </v-container>

                <v-row>
                    <div class="pt-5">
                        <v-row>
                        <v-col>
                        <v-btn 
                            color="warning" 
                            style="width: 100px"
                            @click="goHandler" 
                            > 
                            {{$t('button.go')}}
                        </v-btn>
                        </v-col>

                        </v-row>

                    </div>
                </v-row>

            </v-form>
        </v-container>
    </div>
</template>

<script>

// import moment from 'moment'


export default {
    props: {
        parentObject: Object
    },

    components: {
      
    },


    data() {
        return {
            rules: {
                required: value => !!value || this.$t('reportsparam.required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Invalid e-mail.'
                },
            },
            formData: {
                type: null,
                fromdate: null,
                untildate: null
            },
            typeList: [{
                text: 'CP22',
                value: 'cp22'
            },
            {
                text: 'CP22A',
                value: 'cp22a'
            },
            {
                text: 'CP22B',
                value: 'cp22b'
            }],
            errMsg: "",
            isErr: false,
        }
    },

    methods: {
        goHandler(){
            const vm = this;
            
            this.$store.dispatch('post', {url: "/cp22/report", payload: vm.formData})
            .then((res) => {
                if (res.data.error) {
                    vm.isErr = true;
                    vm.errMsg = res.data.error;
                    vm.$emit('error', {isErr: vm.isErr, errMsg: vm.errMsg});
                } else {
                    let item = res.data.rec;
                    let data = [];
                    if (vm.formData.type == 'cp22' ){
                        data.push(['Nama Pekerja', 'No Cukai Pendapatan (TIN)', 'Jenis Pengenalan1', 'No. KP', 'No. Pasport Semasa'
                        , 'Tarikh Tamat Pasport', 'No. Pasport Didaftarkan Dengan LHDNM', 'Negara Pengeluar Pasport2', 'Warganegara2', 'Jantina3'
                        , 'Tarikh Lahir', 'Status Pekerja4', 'Kod Negara Tel. Bimbit5', 'No Telefon Bimbit', 'Alamat Emel'
                        , 'Alamat Kediaman Terkini6', 'CRA Baris 1', 'CRA Baris 2', 'CRA Baris 3', 'CRA Poskod'
                        , 'CRA Bandar', 'CRA Negeri', 'CRA Negara2', 'Indikator Alamat Surat Menyurat7', 'Alamat Surat Menyurat Terkini8'
                        , 'AD Baris 1', 'AD Baris 2', 'AD Baris 3', 'AD Poskod', 'AD Bandar'
                        , 'AD Negeri', 'AD Negara2', 'Tarikh Permulaan Pekerjaan', 'Jawatan9', 'Tempoh Tahun'
                        , 'Tempoh Bulan', 'Tempoh Hari', 'Jenis Pekerjaan10', 'Lain-lain Jenis Pekerjaan10', 'Nama Pasangan'
                        , 'Jenis Pengenalan Pasangan1', 'No KP Pasangan11', 'No Pasport Pasangan11', 'No Cukai Pendapatan Pasangan', 'No Tel Bimbit Pasangan'
                        , 'Amaun Gaji, Bayaran, Upah dan Kerja Lebih Masa', 'Amaun Gaji Cuti', 'Amaun Komisen Bonus', 'Elaun Tunai12', 'Lain-Lain Elaun Tunai'
                        , 'Amaun Elaun Tunai', 'Amaun Manfaat Barangan', 'Amaun Nilai Kediaman', 'Amaun Elaun Lain', 'Bayaran Lain'
                        , 'Amaun Bayaran Lain', 'Jumlah Saraan Bulanan', 'Nama Majikan Terdahulu', 'Alamat Majikan Terdahulu13', 'EMP Baris 1'
                        , 'EMP Baris 2', 'EMP Baris 3', 'EMP Poskod', 'EMP Bandar', 'EMP Negeri'
                        , 'EMP Negara2']);
                    }

                    for (var i = 0; i < item.length; i++) {
                        if (vm.formData.type == 'cp22a' ){
                            data.push([
                            item[i].COL01,item[i].COL02,item[i].COL03,item[i].COL04,item[i].COL05,
                            item[i].COL06,item[i].COL07,item[i].COL08,item[i].COL09,item[i].COL10,
                            item[i].COL11,item[i].COL12,item[i].COL13,item[i].COL14,item[i].COL15,
                            item[i].COL16,item[i].COL17,item[i].COL18,item[i].COL19,item[i].COL20,
                            item[i].COL21,item[i].COL22,item[i].COL23,item[i].COL24,item[i].COL25,
                            item[i].COL26,item[i].COL27,item[i].COL28,item[i].COL29,item[i].COL30,
                            item[i].COL31,item[i].COL32,item[i].COL33,item[i].COL34,item[i].COL35,
                            item[i].COL36,item[i].COL37,item[i].COL38,item[i].COL39,item[i].COL40,
                            item[i].COL41,item[i].COL42,item[i].COL43,item[i].COL44,item[i].COL45,
                            item[i].COL46,item[i].COL47,item[i].COL48,item[i].COL49,item[i].COL50,
                            item[i].COL51,item[i].COL52,item[i].COL53,item[i].COL54,item[i].COL55,
                            item[i].COL56,item[i].COL57,item[i].COL58,item[i].COL59,item[i].COL60,
                            item[i].COL61,item[i].COL62,item[i].COL63,item[i].COL64,item[i].COL65,
                            item[i].COL66,item[i].COL67,item[i].COL68,item[i].COL69,item[i].COL70,
                            item[i].COL71,item[i].COL72,item[i].COL73,item[i].COL74,item[i].COL75,
                            item[i].COL73,item[i].COL74,item[i].COL78,item[i].COL73,item[i].COL74,
                            item[i].COL81,item[i].COL82,item[i].COL83,item[i].COL73,item[i].COL74,
                            item[i].COL86,item[i].COL82,item[i].COL83,item[i].COL73,item[i].COL74,
                            item[i].COL91,item[i].COL92,item[i].COL73,item[i].COL74,item[i].COL95,
                            item[i].COL73,item[i].COL74,item[i].COL98,item[i].COL73,item[i].COL74,
                            item[i].COL101,item[i].COL102,item[i].COL103,item[i].COL104,item[i].COL105,
                            item[i].COL106,item[i].COL107,item[i].COL108,item[i].COL109,item[i].COL110,
                            item[i].COL111,item[i].COL112,item[i].COL113,item[i].COL114,item[i].COL115,
                            item[i].COL116,item[i].COL117,item[i].COL118,item[i].COL119,item[i].COL120,
                            item[i].COL121,item[i].COL122,item[i].COL123,item[i].COL124,item[i].COL125,
                            item[i].COL126,item[i].COL127,item[i].COL128,item[i].COL129,item[i].COL130,
                            item[i].COL131,item[i].COL132,item[i].COL133,item[i].COL134,item[i].COL135,
                            item[i].COL136,item[i].COL137]);
                        } else if (vm.formData.type == 'cp22b' ){
                            data.push([
                            item[i].COL01,item[i].COL02,item[i].COL03,item[i].COL04,item[i].COL05,
                            item[i].COL06,item[i].COL07,item[i].COL08,item[i].COL09,item[i].COL10,
                            item[i].COL11,item[i].COL12,item[i].COL13,item[i].COL14,item[i].COL15,
                            item[i].COL16,item[i].COL17,item[i].COL18,item[i].COL19,item[i].COL20,
                            item[i].COL21,item[i].COL22,item[i].COL23,item[i].COL24,item[i].COL25,
                            item[i].COL26,item[i].COL27,item[i].COL28,item[i].COL29,item[i].COL30,
                            item[i].COL31,item[i].COL32,item[i].COL33,item[i].COL34,item[i].COL35,
                            item[i].COL36,item[i].COL37,item[i].COL38,item[i].COL39,item[i].COL40,
                            item[i].COL41,item[i].COL42,item[i].COL43,item[i].COL44,item[i].COL45,
                            item[i].COL46,item[i].COL47,item[i].COL48,item[i].COL49,item[i].COL50,
                            item[i].COL51,item[i].COL52,item[i].COL53,item[i].COL54,item[i].COL55,
                            item[i].COL56,item[i].COL57,item[i].COL58,item[i].COL59,item[i].COL60,
                            item[i].COL61,item[i].COL62,item[i].COL63,item[i].COL64,item[i].COL65,
                            item[i].COL66,item[i].COL67,item[i].COL68,item[i].COL69,item[i].COL70,
                            item[i].COL71,item[i].COL72,item[i].COL73,item[i].COL74,item[i].COL75,
                            item[i].COL73,item[i].COL74,item[i].COL78,item[i].COL73,item[i].COL74,
                            item[i].COL81,item[i].COL82,item[i].COL83,item[i].COL73,item[i].COL74,
                            item[i].COL86,item[i].COL82,item[i].COL83,item[i].COL73,item[i].COL74,
                            item[i].COL91,item[i].COL92,item[i].COL73,item[i].COL74,item[i].COL95,
                            item[i].COL73,item[i].COL74,item[i].COL98,item[i].COL73,item[i].COL74,
                            item[i].COL101,item[i].COL102,item[i].COL103,item[i].COL104,item[i].COL105,
                            item[i].COL106,item[i].COL107,item[i].COL108,item[i].COL109,item[i].COL110,
                            item[i].COL111,item[i].COL112,item[i].COL113,item[i].COL114,item[i].COL115,
                            item[i].COL116,item[i].COL117,item[i].COL118,item[i].COL119,item[i].COL120,
                            item[i].COL121,item[i].COL122,item[i].COL123,item[i].COL124,item[i].COL125,
                            item[i].COL126,item[i].COL127,item[i].COL128]);
                        }else if (vm.formData.type == 'cp22' ){
                            data.push([
                            item[i].COL01,item[i].COL02,item[i].COL03,item[i].COL04,item[i].COL05,
                            item[i].COL06,item[i].COL07,item[i].COL08,item[i].COL09,item[i].COL10,
                            item[i].COL11,item[i].COL12,item[i].COL13,item[i].COL14,item[i].COL15,
                            item[i].COL16,item[i].COL17,item[i].COL18,item[i].COL19,item[i].COL20,
                            item[i].COL21,item[i].COL22,item[i].COL23,item[i].COL24,item[i].COL25,
                            item[i].COL26,item[i].COL27,item[i].COL28,item[i].COL29,item[i].COL30,
                            item[i].COL31,item[i].COL32,item[i].COL33,item[i].COL34,item[i].COL35,
                            item[i].COL36,item[i].COL37,item[i].COL38,item[i].COL39,item[i].COL40,
                            item[i].COL41,item[i].COL42,item[i].COL43,item[i].COL44,item[i].COL45,
                            item[i].COL46,item[i].COL47,item[i].COL48,item[i].COL49,item[i].COL50,
                            item[i].COL51,item[i].COL52,item[i].COL53,item[i].COL54,item[i].COL55,
                            item[i].COL56,item[i].COL57,item[i].COL58,item[i].COL59,item[i].COL60,
                            item[i].COL61,item[i].COL62,item[i].COL63,item[i].COL64,item[i].COL65,
                            item[i].COL66]);
                        }
                    }
                    if (vm.formData.type == 'cp22' ) {
                        const csvData = this.objectToCSV(data, true);
                        this.download(csvData, item[0].compcode + '_' + vm.formData.type.toUpperCase() + '.csv');
                    } else{
                        const csvData = this.objectToCSV(data);
                        this.download(csvData, item[0].compcode + '_' + vm.formData.type.toUpperCase() + '.txt');
                    }
                }
                })
            .catch((err) => {
                vm.isErr = true;
                vm.errMsg = err;
            });

            
        },

        updateValue() {
            this.$emit("input", {
                type: this.formData.type,
                fromdate: this.formData.fromdate,
                untildate: this.formData.untildate
            });
        },

        objectToCSV(data, csv = false) {
            const csvRows = [];

            // get header
            const headers = Object.keys(data[0]);
            // csvRows.push(headers.join(","));

            // loop over the rows
            for (const row of data) {
                const values = headers.map((header) => {
                const escaped = ("" + row[header]).replace(/"/g, '\\"').replace('null','').replace('undefined','');
                return escaped;
                });
                if (csv){
                    csvRows.push(values.join("|"));
                } else {
                    csvRows.push(values.join(","));
                }
            }
            return csvRows.join("\n");
        },

        download(data, name) {
            const blob = new Blob([decodeURIComponent('%ef%bb%bf'), data], { type: "text/csv;charset=utf-8;" });
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement("a");

            a.setAttribute("hidden", "");
            a.setAttribute("href", url);
            a.setAttribute(
                "download",
                name
            );

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
    }, 

    mounted() { 
    },
    
}
</script>
