<template>
  <div>
    <app-form
      v-bind:parentObject="{
        data: this.formData,
        pageTitle: this.pageTitle,
        pageTitleValue: this.pageTitleValue,
        errMsg: this.errMsg,
        isEdit: this.editMode,
      }"
      v-on:input="formData = $event"
      v-on:save="saveHandler"
    />
    <div v-if="isErr">
      <app-error
        v-bind:parentError="{
          data: this.errMsg,
          isErr: this.isErr,
        }"
        v-on:closeError="isErr = $event"
      />
    </div>
  </div>
</template>

<script>

import CP22Form from "../../../components/payroll/cp22/Form";
import ErrorDialog from "../../../components/common/ErrorDialog";

export default {
  components: {
    "app-form": CP22Form,
    "app-error": ErrorDialog,
  },

  data() {
    return {
      pageTitle: this.$t('cp22.createcp22'),
      pageTitleValue: "",
      formData: {
        staffid: null,
        staffcode: null,
        compnyid: null,
        compnycode: null,
        compnyname: null,
        cesstypeid: null,
        cesstype: null,
        cesstypedesc: null,
        deathnoticedate: null,
        retiretypeid: null,
        retiretype: null,
        retiretypedesc: null,
        vssmss: false,
        repinctry: false,
        repname: null,
        repaddr1: null,
        repaddr2: null,
        repaddr3: null,
        repposcode: null,
        repcity: null,
        repctryid: null,
        repctrycode: null,
        repctrydesc: null,
        idtypeid: null,
        idtypecode: null,
        idno: null,
        phoneno: null, 
        relationshipfordeath: null,
        stamp: 0,
        userid: this.$store.getters.userId,
      },

      currencyCode: "",
      paymodecode: "",
      paystatcode: "",

      pcbcats: [],
      pcbcatList: [],

      taxes: [],
      taxList: [],

      epfs: [],
      epfList: [],

      epfves: [],
      epfveList: [],

      socsos: [],
      socsoList: [],

      baitulmals: [],
      baitulmalList: [],

      payitems: [],
      payitemList: [],

      paymodes: [],
      paymodeList: [],

      paystats: [],
      paystatList: [],

      psns: [],
      psn0List: [],
      psn1List: [],
      psn2List: [],

      errMsg: "",
      isErr: false,
      editMode: false,
    };
  },

  methods: {
    saveHandler() {
      const vm = this;
      if (!this.CheckPermission(this.$route.meta.funcname, "canadd")) {
        return false;
      }
      if (this.formData.repinctry == false) {
        this.formData.repinctry = 0;
      } else {
        this.formData.repinctry = 1;
      }

      if (this.formData.vssmss == false) {
        this.formData.vssmss = 0;
      } else {
        this.formData.vssmss = 1;
      }

      this.$store.dispatch('post', {url: "/cp22/create", payload: vm.formData})
        .then((res) => {
          if (res.data.error) {
            vm.isErr = true;
            vm.errMsg = res.data.error;
          } else {
            this.$router.push({ name: "cp22list" });
          }
        })
        .catch((err) => {
          vm.isErr = true;
          vm.errMsg = err;
        });
    },

    cancelHandler() {
      this.$router.push({ name: "cp22list" });
    },
  },
};
</script>